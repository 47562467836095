import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@obrador/core';
import { finalize, first } from 'rxjs';
import { LoadingController, polluteForm, SnackBarService } from '@obrador/shared';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    form: UntypedFormGroup;
    loading = false;
    submitted = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        protected snack: SnackBarService,
        protected loadingController: LoadingController
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            username: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]]
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            polluteForm(this.form);
            return;
        }
        const values = this.form.value;
        this.loading = true;
        this.loadingController.start();
        this.authService.login(values.username, values.password)
            .pipe(finalize(() => {
                this.loadingController.stop();
            }))
            .subscribe({
                next: async () => {
                    await this.router.navigateByUrl('home')
                },
                error: (err) => this.snack.error(err)
            });
    }

}
