import { BaseDTO } from './api-interfaces';
import { CuentaDTO } from "./cuenta";

export interface AdminDTO extends BaseDTO{
    nombre: string;
    apellido: string;
    ci: string;
    email: string;
    perfiles?: PerfilDTO[]
}

export interface PerfilDTO extends BaseDTO {
    nombre?: string;
    imagenURL?: string;
    cuentas: CuentaDTO[];
}
