import { Injectable, Injector } from '@angular/core';
import { EventoApp } from '@obrador/api-interfaces';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class EventoaAppService extends RestService<EventoApp>{

    constructor(protected injector: Injector) {
        super(injector, Resource.EVENTO_APP)
    }


}
