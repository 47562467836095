import { Pipe, PipeTransform } from '@angular/core';
import { Enumerable, EnumTranslateService } from '../services';

@Pipe({
    name: 'enum'
})
export class EnumPipe implements PipeTransform {

    constructor(protected enumService: EnumTranslateService) {
    }

    /**
     * Translates enumerate values to strings defined in enun.json whilst using
     * EnumService.
     * @param key currentPair value of the enumerate
     * @param enumerate - Type of enumerate (it maps to enum.json)
     * @param defaultValue - Default value to fall back in case of null/undefined
     */
    transform(key: string | number, enumerate: Enumerable, defaultValue?: string | number): string {
        if ((key === undefined || key === null || key === '') && defaultValue) {
            return this.enumService.getValue(enumerate, defaultValue);
        }
        return this.enumService.getValue(enumerate, key);
    }

}
