import { QueryParams } from '@obrador/core';

export const simpleQueryFunction = (paramName: string) => {
    return (params: QueryParams, txt: string): QueryParams => {
        params.filters = [];
        if (txt) {
            params.filters.push({
                value: txt,
                field: paramName
            });
        }
        return params;
    };
};

export const buildQueryEmpresas = simpleQueryFunction('nombre');
