import { BaseDTO, Empresa, Motorizado, Remolque } from './api-interfaces';
import { EstadoConvocatoria } from './enums';
import { Reserva } from './reserva';
import { FamiliaServicio } from './servicio';
import { SubConvocatoria } from './sub-convocatoria';

export interface GroupedConvocatoriaData {
    familia: FamiliaServicio;
    reservas: Array<Reserva>
    convocatorias: Array<Convocatoria>;
    lugares: Array<string>;
}

export interface Convocatoria extends BaseDTO {
    fecha_inicio?: Date;
    fecha_fin?: Date;
    estado?: EstadoConvocatoria;
    admins?: Admin[];
    sub_convocatorias?: Array<SubConvocatoria>;
    familia_servicio?: FamiliaServicio;
    motorizado?: Motorizado;
    remolque?: Remolque;
    empresa: Empresa;
    reservas?: Array<Reserva>;
    comentario?: string;
    comentario_cancelacion?: string;
    requiereRemolque?: boolean;
}

export interface Admin {
    nombre: string;
    apellido: string;
    ci: string;
    email: string;
}

export interface ConvocatoriaSave {
    id?: number;
    fecha_inicio?: Date;
    fecha_fin?: Date;
    estado?: EstadoConvocatoria;
    id_empresa?: number;
    id_familia_servicio: number;
    ids_reservas: Array<number>;
    id_remolque?: number;
    id_motorizado?: number;
    comentario?: string;
    id_cuenta: number;
    comentario_cancelacion?: string;
}

export type ConvocatoriaUpdate = Omit<ConvocatoriaSave, 'id_cuenta'>;

export interface ViajeBulkData {
    reservas: number[];
    cantidad_viajes: number;
    ids_sub_convocatorias: number[];
    fecha_manual?: Date;
}
