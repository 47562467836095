import { BaseDTO } from './api-interfaces';
import { ViajeApi } from './viaje';

export interface Pesada extends BaseDTO {
    numero: string;
    viaje_api: ViajeApi;
}
export interface UpdatePesadaItem {
    numero: string;
    fecha: Date | string;
    motorizado: string;
    remolque?: string;
}

export interface UpdatePesadaItemResult extends UpdatePesadaItem {
    pesada?: Pesada
}
