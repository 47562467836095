import { Pipe, PipeTransform, TrackByFunction } from '@angular/core';

@Pipe({
    name: 'trackByFn'
})
export class TrackByFnPipe implements PipeTransform {

    protected cache: Map<string, TrackByFunction<any>>;

    constructor() {
        this.cache = new Map<string, TrackByFunction<any>>();
        this.cache.set('index', this.trackByIndex);
        this.cache.set('$value', this.trackByValue);
        this.cache.set('id', this.trackById);
    }

    public transform<T>(propertyName: string): (index: number, item: any) => any {
        const fn = this.cache.get(propertyName);
        if (!fn) {
            this.cache.set(propertyName, this.constructFunction(propertyName));
        }
        return this.cache.get(propertyName);
    }

    trackByIndex = (index: number, item: any): any => {
        return index;
    }

    trackByValue = (index: number, item: any): any => {
        return item;
    }

    trackById = (index: number, item: any): any => {
        return item.id;
    }

    constructFunction<T>(propertyName: string): TrackByFunction<T> {
        return (index: number, item: any): any => {
            const result = item[propertyName];
            if (!result) {
                return item;
            }
            return item[propertyName];
        };
    }

}
