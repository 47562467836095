<mat-form-field appearance='outline'>
    <mat-label> {{ label }}</mat-label>
    <mtx-select (change)='handleChange($event)' appearance='' [disabled]="isDisabled" [multiple]='true'>
        <ng-container *ngFor="let groupOption of groupedOptions; trackByProperty: 'group'">
            <mtx-option disabled>{{ groupOption.groupLabel }}</mtx-option>
            <mtx-option
                *ngFor="let option of groupOption.elements; trackByProperty: 'value'"
                [value]="option"
                >
                {{ option.value }}
            </mtx-option>
        </ng-container>
    </mtx-select>
</mat-form-field>
