import {
    EventoReserva,
    EventoValidacion,
    Problema, RemitoDigital,
    Reserva,
    ValidacionReglaResult,
    Viaje, ViajeRegistro
} from '@obrador/api-interfaces';

export interface ResumenReservaTableRow {
    data: Viaje;
    id: number;
    motorizado: string;
    remolque: string;
    chofer: string;
    inicio: Date;
    fin: Date;
    toneladasOrigen: number;
    toneladasDestino: number;
    remitoOrigen: string[];
    remitoDestino: string[];
    fotos: string[];
    contenedores: string[];
    estados: string[];
    reserva?: Partial<Reserva>;
    canSelect?: boolean;
    puedeCambiarReserva?: boolean;
    valido?: boolean;
    problema?: Problema;
    validaciones?: ValidacionReglaResult;
    eventos_validacion?: EventoValidacion[];
    tieneValidaciones?: boolean;
    toolTipValidaciones?: string;
    eventos?: EventoReserva[];
    comentario?: string | null;
    remitos_digitales?: RemitoDigital[];
    tieneRemitosManuales?: boolean;
    viaje_registro?: ViajeRegistro;
}

export interface HistoricoValidacionTableRow {
    id: number;
    fecha: Date;
    regla: string;
    admin: string;
    resultado: ValidacionReglaResult
}
