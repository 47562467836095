export enum TipoFiltroSeguimiento {
    CON_ALARMAS = 1,
    SIN_NOVEDAD = 2,
    POSPUESTAS = 3
}

export enum TipoAccionNovedad {
    POSPONER = 'POSPONER',
    APAGAR = 'APAGAR'
}

export enum MotivoProblema {
    CHOFER = 'PROBLEMA_CHOFER',
    DISPOSITIVO = 'PROBLEMA_DISPOSITIVO',
    APLICACION = 'PROBLEMA_APLICACION',
    COORDINACION = 'PROBLEMA_COORDINACION',
    OTRO = "OTRO"
}
