import { Injectable } from '@angular/core';
import { EstadoConvocatoria, TipoMotivoNovedad } from '@obrador/api-interfaces';
import _ from 'lodash';
import { EstadoTableFilter } from '../components';
import { MotivoProblema, TipoAccionNovedad, TipoFiltroSeguimiento } from '../models';
import { Enumerable, EnumTranslateService } from './enum-translate.service';

export interface SelectModel<E> {
    value: E;
    label: string;
}

@Injectable()
export class EnumSelectModel {

    constructor(protected enumTranslate: EnumTranslateService) {
    }

    /**
     * Obtiene los labels/values del enumTranslateService si existen.
     * @param enumName - nombre del enumerado ie: "EstadoConvocatoria"
     * @param enumType - clase del enumerado ie: EstadoConvocatoria
     * @protected
     */
    protected getByEnum<T>(enumName: Enumerable, enumType: any): Array<SelectModel<T>> {
        const result = [];
        for (const [key, value] of _.entries(enumType)) {
            const label = this.enumTranslate.getValue(enumName, key);
            if (label) {
                result.push({
                    value: value,
                    label
                });
            }
        }
        return result.sort((a, b) => {
            return a.label.localeCompare(b.label);
        }) as Array<SelectModel<T>>;
    }

    getEstadosConovocatorias(): Array<SelectModel<EstadoConvocatoria>> {
        return this.getByEnum<EstadoConvocatoria>('EstadoConvocatoria', EstadoConvocatoria);
    }

    getEstadosFiltroFamilia(): Array<SelectModel<EstadoTableFilter>> {
        return this.getByEnum<EstadoTableFilter>('EstadoTableFilter', EstadoTableFilter);
    }

    getTipoAccionesNovedad(): Array<SelectModel<TipoAccionNovedad>> {
        return this.getByEnum<TipoAccionNovedad>('TipoAccionNovedad', TipoAccionNovedad);
    }

    getTipoMotivoNovedad(): Array<SelectModel<TipoMotivoNovedad>> {
        return this.getByEnum<TipoMotivoNovedad>('TipoMotivoNovedad', TipoMotivoNovedad);
    }

    getTipoFiltroSeguimiento(): Array<SelectModel<TipoFiltroSeguimiento>> {
        return this.getByEnum<TipoFiltroSeguimiento>('TipoFiltroSeguimiento', TipoFiltroSeguimiento);
    }

    getMotivoProblema(): Array<SelectModel<MotivoProblema>> {
        return this.getByEnum<MotivoProblema>('MotivoProblema', MotivoProblema);
    }

}
