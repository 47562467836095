import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogComentarioGenericoOptions {
    title: string;
    message?: {
        text: string;
        type: 'warn' | 'info' | 'error';
    };
    placeholder?: string;
}

@Component({
    selector: 'app-dialog-comentario-generico',
    templateUrl: './dialog-comentario-generico.component.html',
    styleUrls: ['./dialog-comentario-generico.component.scss'],
})
export class DialogComentarioGenericoComponent implements OnInit {

    form: FormGroup;

    constructor(
        protected dialogRef: MatDialogRef<DialogComentarioGenericoComponent>,
        @Inject(MAT_DIALOG_DATA) public options: DialogComentarioGenericoOptions,
        protected fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            comentario: ['', [Validators.required, Validators.maxLength(1000)]]
        });
    }

    close(comentario?: boolean): void {
        if (comentario) {
            this.dialogRef.close({
                comentario: this.form.get('comentario').value
            })
        } else {
            this.dialogRef.close();
        }
    }

}
