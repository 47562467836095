import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-progress-container',
  templateUrl: './progress-container.component.html',
  styleUrls: ['./progress-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressContainerComponent {

}
