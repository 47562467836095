import { Observable } from 'rxjs';
import { ApiError, PagedResult, QueryParams } from './rest.model';

// tslint:disable:interface-name
export interface IRestService<T> {
  all(): Observable<Array<T>>;

  allWith(queryParams: any): Observable<Array<T>>;

  paginated(queryParams: any): Observable<PagedResult<T>>;

  findOne(id: string): Observable<T>;

  create(entity: T): Observable<T>;

  update(entity: T): Observable<T>;

  delete(id: string): Observable<boolean>;

  mapPaginated(result: any, queryParams: QueryParams): PagedResult<T>;

  mapEntity(value: any): T;

  mapArray(value: any): Array<T>;

}
