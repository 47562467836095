import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent, DialogConfirmConfig } from '../components/dialog-confirm/dialog-confirm.component';

/**
 * When decorating a component method, this will pop the DialogConfirmComponent
 * modal/confirmation. If the user selects Ok then it will execute whatever
 * the function does. If not, won't do anything.
 * @param matDialogKey - key to the dialog controller (matDialog object key in the component level)
 * @param config DialogConfirmConfig interface
 */
export function ConfirmDialog(
  matDialogKey: string,
  config: DialogConfirmConfig
) {
  return (target: any, name: string, descriptor: PropertyDescriptor) => {
    const method = descriptor.value; // references the method being decorated
    descriptor.value = async function (...args: any[]) {
      // @ts-expect-error: No error...
      const dialog = this[matDialogKey] as MatDialog;
      if (dialog) {
        return dialog
          .open(DialogConfirmComponent, {
            data: config,
          })
          .afterClosed()
          .subscribe((ok) => {
            if (ok) {
              method.apply(this, args);
            }
          });
      } else {
        return method.apply(this, args);
      }
    };
  };
}
