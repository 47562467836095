<mat-toolbar color='primary'>
    <mat-toolbar-row class='menu-header-img'>
        <div class='menu-header-container'>
            <img src='/assets/images/user.png' alt='Profile'>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row>
        <h2>{{ user.nombre }} {{ user.apellido }}</h2>
    </mat-toolbar-row>
</mat-toolbar>
