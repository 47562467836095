import { Component, Inject, OnInit } from '@angular/core';
import {AlarmaNovedad, EstadoEventoViajeCancerbero, Novedad, TipoMotivoNovedad} from '@obrador/api-interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    NgxTimelineEvent,
    NgxTimelineEventChangeSideInGroup,
    NgxTimelineEventGroup,
} from '@frxjs/ngx-timeline';
import { NovedadService } from '@obrador/core';
import {EnumPipe, EnumSelectModel, SelectModel} from '@obrador/shared';

export interface DialogHistoricoNovedadesConfig {
    viajeId: number;
    tiempos: string;
}

@Component({
    selector: 'app-historico-novedades',
    templateUrl: './historico-novedades.component.html',
    styleUrls: ['./historico-novedades.component.scss'],
})
export class HistoricoNovedadesComponent implements OnInit {

    novedades: Novedad[] = [];
    isLoading = false;
    borraNovedad = false;
    alarmaNovedad: AlarmaNovedad = null;
    timelineNovedades: NgxTimelineEvent[] = [];
    changeSideEnum = NgxTimelineEventChangeSideInGroup;
    eventGroupEnum = NgxTimelineEventGroup;
    primerId = 0;
    motivos: Array<SelectModel<TipoMotivoNovedad>>;
    tiempoMsg: string;

    constructor(
        protected dialogRef: MatDialogRef<HistoricoNovedadesComponent>,
        @Inject(MAT_DIALOG_DATA) public config: DialogHistoricoNovedadesConfig,
        protected novedadService: NovedadService,
        protected selectService: EnumSelectModel,
        protected enumPipe: EnumPipe
    ) {}

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.motivos = this.selectService.getTipoMotivoNovedad();
        this.tiempoMsg = this.config.tiempos;
        await this.getNovedades();
    }

    async getNovedades(): Promise<void> {
        this.novedadService.getNovedadesByViajeId(this.config.viajeId)
            .subscribe({
                next: (novedades) => {
                    this.novedades = novedades;
                    this.timelineNovedades = novedades.map((n, i) => {
                        if (i === 0) {
                            this.primerId = n.id;
                        }
                        if (n.motivo) {
                            n.motivo_select = this.motivos.find(motivo => motivo.value === n.motivo)?.label;
                        }
                        const alarma = n.alarmas[0];
                        return {
                            id: n.id,
                            timestamp: new Date(n.fecha),
                            title: n.admin?.nombre ? n.admin.nombre + ' ' + n.admin.apellido : n.admin?.email || 'S/N',
                            motivo:n.motivo_select,
                            description: n.comentario,
                            alarma: alarma ? alarma.alarma : null,
                            instancia: alarma ? this.enumPipe
                                .transform(alarma.alarma.tipo_instancia, 'EstadoEventoViajeCancerbero', EstadoEventoViajeCancerbero.RUMBO_ORIGEN) : null
                        }
                    })
                    this.isLoading = false;
                    if (novedades.length === 0) {
                        this.closeDialog();
                    } else {
                        if (novedades[0].alarmas && novedades[0].alarmas.length > 0) {
                            this.alarmaNovedad = novedades[0].alarmas[0];
                        }
                    }
                },
                error: () => {
                    this.isLoading = false;
                }
            })
    }

    closeDialog(): void {
        this.dialogRef.close({
            refresh: this.borraNovedad
        });
    }

    async borrarNovedad(id: number): Promise<void> {
        this.isLoading = true;
        this.borraNovedad = true;
        this.novedadService.delete(id)
            .subscribe({
                next: async () => {
                    this.isLoading = false;
                    await this.getNovedades();
                },
                error: () => {
                    this.isLoading = false;
                }
            })
    }
}
