import { ArrayUtils } from '@obrador/common';

export interface DataTableFilters {
    text: string;
    filters?: Array<DataTablePropertyFilter>;
}
export interface DataTablePropertyFilter {
    columnName: string;
    value: any;
}

export interface DataTableRow {
    id: any;
    rowClass?: string;
}
export const defaultPredicate: ((data: any, filter: string) => boolean) = (data: any, filter: string): boolean => {
    // Transform the data into a lowercase string of all property values.
    const accumulator = (currentTerm: any, key: string) => currentTerm + data[key];
    const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();

    // Transform the filter by converting it to lowercase and removing whitespace.
    const transformedFilter = filter.trim().toLowerCase();

    return dataStr.indexOf(transformedFilter) != -1;
}

export const dataTablePredicate = (data: any, filter: string): boolean => {
    const filters = JSON.parse(filter) as DataTableFilters;
    const textPredicate = defaultPredicate(data, filters.text);
    const conditions = [textPredicate];
    if (filters.filters && filters.filters.length > 0) {
        for (const fil of filters.filters) {
            const value = data[fil.columnName];
            const filterValue = fil.value;
            let evaluation = false;
            // Arrays se hace diferente
            if (ArrayUtils.isArray(filterValue)) {
                evaluation = filterValue.includes(value);
            } else {
                evaluation = value === filterValue;
            }

            conditions.push(evaluation);
        }
    }
    return conditions.every(c => c);
}
