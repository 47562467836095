import { BaseDTO } from './api-interfaces';
import { TipoEventoApp, ViajeApi } from './viaje';

export interface TipoEstadoEventoApp extends BaseDTO {
    nombre: string;
}

export interface EventoAppEstado extends BaseDTO {
    tipo: TipoEstadoEventoApp;
}

export interface EventoAppRemito extends BaseDTO {
    url?: string;
    vision_info?: any;
    numero?: string;
    lugar: string;
    kilos_netos?: string;
    correcto?: boolean;
}

export interface EventoAppFoto extends BaseDTO {
    url?: string;
    vision_info?: any;
}

export interface EventoAppContenedor extends BaseDTO {
    numero_contenedor: string;
    url?: string;
    correcto: boolean;
    vision_info?: any;
}

export interface EventoAppProblema {
    comentario?: string;
}

export interface EventoApp extends BaseDTO {
    borrado?: boolean;
    latitude?: string;
    longitude?: string;
    en_destino?: string;
    en_origen?: string;
    deleted_at?: Date;
    viaje_api: ViajeApi;
    id_migrador?: number;
    fecha: Date;
    uid?: string;
    tipo?: TipoEventoApp;
    estado?: EventoAppEstado;
    remito?: EventoAppRemito;
    foto?: EventoAppFoto;
    contenedor?: EventoAppContenedor;
    problema?: EventoAppProblema;
}
