<div mat-dialog-title class="dialog-title-close">
    <span>Detalle de viaje Rechazado</span>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <ng-container *ngIf='createdBy?.admin'>
        <div class='dialog-viaje-subtitle'>
            Identificadores
        </div>
        <mat-divider></mat-divider>
        <div class='dialog-viaje-info-row'>
            <mat-icon matTooltip='ID'>tag</mat-icon>
            <span class='dialog-viaje-id-info'>
                {{ data.id }}
            </span>
        </div>
        <div class='dialog-viaje-subtitle'>
            Creado
        </div>
        <mat-divider></mat-divider>
        <div class='dialog-viaje-info-row-multi'>
            <div class='dialog-viaje-info-row-multi-row'>
                <mat-icon>account_circle</mat-icon>
                <span>{{ createdBy.admin.nombre }} {{ createdBy.admin.apellido }} </span>
            </div>
            <div class='dialog-viaje-info-row-multi-row'>
                <mat-icon>email</mat-icon>
                <span> {{ createdBy.admin.email }} </span>
            </div>
        </div>
        <div class='dialog-viaje-info-row'>
            <mat-icon>event</mat-icon>
            <span> {{ createdBy.fecha | date: 'dd/MM/yyyy HH:mm'}}</span>
        </div>
        <div class='dialog-viaje-subtitle'>
            Rechazado
        </div>
        <mat-divider></mat-divider>
        <div class='dialog-viaje-info-row'>
            <mat-icon>comment</mat-icon>
            <span>{{rejectedBy.comentario}}</span>
        </div>
    </ng-container>
</div>

<div mat-dialog-actions class='justify-content-end'>
    <button color='primary' mat-button (click)="close()" cdkFocusInitial>Cerrar</button>
</div>
