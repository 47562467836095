<h2 mat-dialog-title>Cambiar horario convocatorias</h2>
<div mat-dialog-content [formGroup]='form'>
    <div class='row'>
        <div class='col-md-6'>
            <mat-form-field>
                <mat-label>Fecha Inicio</mat-label>
                <input formControlName='fecha_inicio' [matDatepicker]='pickerDesde' matInput>
                <mat-datepicker-toggle matSuffix [for]='pickerDesde'></mat-datepicker-toggle>
                <mat-datepicker #pickerDesde></mat-datepicker>
                <mat-error appFormError [control]='form.get("fecha_inicio")' fieldName='Fecha inicio'></mat-error>
            </mat-form-field>
        </div>
        <div class='col-md-6'>
            <mat-form-field>
                <mat-label>Hora Inicio</mat-label>
                <input formControlName='hora_inicio' matInput type='time'>
                <mat-icon matSuffix>av_timer</mat-icon>
                <mat-error appFormError [control]='form.get("hora_inicio")' fieldName='Hora inicio'></mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class='row'>
        <div class='col-md-6'>
            <mat-form-field>
                <mat-label>Fecha Fin</mat-label>
                <input formControlName='fecha_fin' [matDatepicker]="picker" matInput>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error appFormError [control]='form.get("fecha_fin")' fieldName='Fecha fin'></mat-error>
            </mat-form-field>
        </div>
        <div class='col-md-6'>
            <mat-form-field>
                <mat-label>Hora Fin</mat-label>
                <input formControlName='hora_fin' matInput type='time'>
                <mat-icon matSuffix>av_timer</mat-icon>
                <mat-error appFormError [control]='form.get("hora_fin")' fieldName='Hora fin'></mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class='row' *ngIf='shouldShowFechaError()'>
        <div class='col-md-12'>
            <mat-error>
                Fecha inicio tiene que ser menor que Fecha fin
            </mat-error>
        </div>
    </div>
</div>
<div mat-dialog-actions align='end'>
    <button mat-raised-button color="primary" (click)='submit()'>Guardar</button>
    <button mat-raised-button color="secondary" (click)='closeDialog()'>Cancelar</button>
</div>
