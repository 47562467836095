import { Component, Input } from '@angular/core';
import { PlanViaje, Reserva } from '@obrador/api-interfaces';

@Component({
    selector: 'app-plan-diario-card',
    templateUrl: './plan-diario-card.component.html',
    styleUrls: ['./plan-diario-card.component.scss']
})
export class PlanDiarioCardComponent {

    readonly default_viaje: Partial<PlanViaje> = Object.seal({
        reserva: null,
        fecha: new Date(),
        cantidad: 0,
        total_viajes: 0,
        cantidad_camiones: 0
    });

    planViaje: Partial<PlanViaje>;

    protected _reserva: Reserva;

    @Input() set reserva(r: Reserva) {
        this._reserva = r;
        this.planViaje = r.planDiario || this.default_viaje;
    }

    get reserva() {
        return this._reserva;
    }

}
