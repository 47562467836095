import { Injectable, Injector } from '@angular/core';
import {Empresa, EstadoEmpresa} from '@obrador/api-interfaces';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';
import {catchError, Observable} from "rxjs";
import {PagedResult, QueryParams} from "../rest.model";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class EmpresaService  extends RestService<Empresa>{

    constructor(protected injector: Injector) {
        super(injector, Resource.EMPRESAS)
    }

    bulkSave(ids: number[], prioridad?:number, estado?: EstadoEmpresa, requiereremolque?: boolean, requerimiento?: boolean): Observable<void> {
        return this.http.put<void>(`${this.buildURL()}/bulk-save`, {
            ids,
            prioridad,
            estado,
            requiereremolque,
            requerimiento
        })
    }

    paginatedAll(queryParams: QueryParams): Observable<PagedResult<Empresa>> {
        return this.http.get<PagedResult<Empresa>>(`${this.buildURL()}/recursos`, { params: this.buildParams(queryParams) })
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapPaginated(data, queryParams))
            );
    }

    validarEmpresa(empresaId: number, reservasIds: number[]): Observable<boolean> {
        return this.http.post<boolean>(`${this.buildURL()}/validar-reqs`, {
            empresaId,
            reservasIds
        })
    }

}
