import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface DialogConfirmConfig {
    message: string;
    isHTML?: boolean;
    title: string;
    okText?: string;
    cancelText?: string;
}

@Component({
    selector: 'app-dialog-confirm',
    templateUrl: './dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogConfirmComponent implements OnInit {

    safeHTML: SafeHtml;

    constructor(
        protected dialog: MatDialogRef<DialogConfirmComponent>,
        protected sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public config: DialogConfirmConfig
    ) {
    }

    ngOnInit(): void {
        if (this.config.isHTML) {
            this.safeHTML = this.sanitizer.bypassSecurityTrustHtml(this.config.message);
        }
    }

    cancel(): void {
        this.dialog.close(false);
    }

    confirm(): void {
        this.dialog.close(true);
    }
}
