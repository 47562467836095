import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AppEvents, Events } from '@obrador/core';
import { MenuItems } from '@obrador/shared';


/** @title Responsive sidenav */
@Component({
    selector: 'app-full-layout',
    templateUrl: 'full.component.html',
    styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnDestroy, OnInit {

    @ViewChild('recursosSidebar', { static: false }) recursosSidebar: MatSidenav;

    mobileQuery: MediaQueryList;

    protected _mobileQueryListener: () => void;

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public menuItems: MenuItems,
        protected events: Events
    ) {
        this.mobileQuery = media.matchMedia('(min-width: 1024px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnInit(): void {
        this.events.subscribe(AppEvents.SIDENAV_RECURSOS_TOGGLE, async () => {
            if (this.recursosSidebar) {
                await this.recursosSidebar.toggle();
            }
        });
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

}
