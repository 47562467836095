import { Pipe, PipeTransform } from '@angular/core';
import { ApiErrorMessageService } from '../services';

declare type IEnumerateName = 'ControlDesvioError';
@Pipe({
    name: 'apiError',
})
export class ApiErrorPipe implements PipeTransform {

    constructor(protected service: ApiErrorMessageService) {
    }
    transform(enumValue: any, enumerateName?: IEnumerateName): string {
        return this.service.getErrorMessage(enumValue, enumerateName);
    }
}
