<ng-container *ngIf='reserva'>
    <mat-card appearance="outlined">
        <div class='reserva-small-title'>
            <mat-card-title>
                R{{ reserva.id_migrador }}
            </mat-card-title>
            <mat-divider></mat-divider>
        </div>
        <mat-card-content>
            <div class='reserva-small-card-row ellipsis text-center'>
                <span class='w-100'> {{ reserva.empresa || reserva.booking }}</span>
            </div>
            <div class='reserva-small-card-row ellipsis'>
                <mat-icon>place</mat-icon>
                <small> {{ reserva.origen || 'Sin origen' }} - {{ reserva.destino || 'Sin destino' }} </small>
            </div>
            <div class='reserva-small-card-row'>
                <mat-icon>schedule</mat-icon>
                <small> {{ reserva.tiempo_total }} horas</small>
            </div>
        </mat-card-content>
        <mat-icon class='reserva-small-card-icon-close' matTooltip='Quitar' (click)='onRemove()'>close</mat-icon>
    </mat-card>
</ng-container>
