import { Component, OnInit } from '@angular/core';
import { Admin } from '@obrador/api-interfaces';

@Component({
    selector: 'app-menu-header',
    templateUrl: './menu-header.component.html',
    styleUrls: ['./menu-header.component.scss']
})
export class MenuHeaderComponent implements OnInit {

    user: Admin

    ngOnInit(): void {
        this.user = {
            nombre: 'Usuario',
            apellido: 'anónimo',
            ci: '485505155',
            email: 'ohernandez@juango.com.uy '
        };
    }

}
