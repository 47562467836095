import { BaseDTO } from './api-interfaces';

export interface FamiliaServicio extends BaseDTO {
    nombre: string;
    id_gaudi2: number;
    servicios?: Array<Servicio>;
    resumen?: ResumenConvocatoria
}

export interface ResumenConvocatoria {
    reservas: number;
    convocatorias: number;
    convocatorias_juango: number;
    rechazadas: number;
    aceptadas: number;
    canceladas: number;
    preparadas: number;
    publicadas: number;
}

export interface Servicio extends BaseDTO {
    id_gaudi2: number;
    name: string;
    complete_name: string;
    unit: string;
    mode: string;
    pack: string;
    rythm: string;
    comments: string;
    iva: number;
    currency: string;
    cost: number;
    impoexpo: string;
}
