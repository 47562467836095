import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { FormGroupSelectOption } from '../components/form-group-select/form-group-select.component';

export const polluteForm = (group: UntypedFormGroup | UntypedFormArray) => {
    for (const control in group.controls) {
        const formControl = group.get(control);
        if (formControl) {
            if (formControl instanceof UntypedFormGroup || formControl instanceof UntypedFormArray) {
                // If this is another form group inside, then recursively pollute form
                polluteForm(formControl);
            } else {
                formControl.markAsDirty();
                formControl.markAsTouched();
                formControl.updateValueAndValidity();
            }
        }
    }
};

export const triggerFormValidations = (form: UntypedFormGroup) => {
    Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        control.updateValueAndValidity();
    });
}

export const generateUUID = (): string => {
    return uuid()
}

interface OptionDescriptor<T> {
    key: keyof T;
    keyLabel: string;
}
export function convertDataToOptions<T>(data: T[], optionDescriptors: OptionDescriptor<T>[]): FormGroupSelectOption[] {
    const options: FormGroupSelectOption[] = [];

    data.forEach(item => {
        for (const descriptor of optionDescriptors) {
            // eslint-disable-next-line no-prototype-builtins
            if (item.hasOwnProperty(descriptor.key)) {
                const option: FormGroupSelectOption = {
                    key: descriptor.key as string,
                    keyLabel: descriptor.keyLabel,
                    value: item[descriptor.key]
                };
                if (!options.find(o => o.value === option.value)) {
                    options.push(option);
                }
            }
        }
    });
    return options;
}
