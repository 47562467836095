<div class="login-main">
    <mat-card appearance="outlined">
        <br>
        <img src='assets/images/logo-juango-logistica.png'>
        <mat-card-header>
            <mat-card-title>Ingresar a Obrador</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]='form'>
                <mat-form-field>
                    <mat-label>Correo</mat-label>
                    <input formControlName='username' matInput placeholder="sistema@juango.com.uy" autocomplete='false'>
                    <mat-error *ngIf="(form.get('username').touched || form.get('username').dirty) && form.get('username').errors?.email">
                        Debe ingresar un correo valido
                    </mat-error>
                    <mat-error *ngIf="(form.get('username').touched || form.get('username').dirty) && form.get('username').errors?.required">
                        El campo correo es requerido
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Contraseña</mat-label>
                    <input formControlName='password' type="password" matInput placeholder="Contraseña" autocomplete='false'>
                    <mat-error *ngIf="(form.get('password').touched || form.get('password').dirty) && form.get('password').errors?.required">
                        El campo Contraseña es requerido
                    </mat-error>
                </mat-form-field>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button
                (click)='onSubmit()'
                mat-raised-button
                color="primary">Ingresar</button>
            <br><br><br>
        </mat-card-actions>
    </mat-card>
</div>
