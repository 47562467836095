<div class='row'>
    <div class='col-md-12'>
        <div class='flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12'>
            <h2 class='title'>404 Página no encontrada</h2>
            <small class='sub-title'>La ṕagina que intenta acceder no existe</small>
            <button nbButton fullWidth (click)='goToHome()' type='button' class='home-button'>
                Volver Atras
            </button>
        </div>
    </div>
</div>
