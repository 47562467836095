import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-alert-message',
    templateUrl: './alert-message.component.html',
    styleUrls: ['./alert-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertMessageComponent {

    @Input() message: string;

    @Input() set type (t: 'info' | 'warn' | 'error') {
        switch (t) {
            case 'info':
                this.color = 'info';
                this.icon = 'info'
                break;
            case 'warn':
                this.color = 'warning';
                this.icon = 'warning';
                break;
            case 'error':
                this.color = 'danger';
                this.icon = 'error'
                break;
        }
    }

    color: string;
    icon: string;

}
