import { AbstractControl, ValidationErrors } from '@angular/forms';

export const greaterThanZero = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (isNaN(value)) {
        return null;
    }
    if (Number(value) <= 0) {
        return { 'notGreaterThanZero': { value: control.value } };
    }
    return null;
}
