/**
 * Server params:
 * offset: Number(req.query['offset']) || 0,
 * limit: Number(req.query['limit']) || API_PAGINATION_LIMIT,
 * orderType: req.query['order_type'] || 'ASC',
 * orderBy: req.query['order_by'] || null,
 * except: Number(req.query['except']) || null,
 * searchBy: req.query['search_by'] || null,
 * search: req.query['search'] || null
 */
export enum APIParams {
    LIMIT = 'limit',
    OFFSET = 'offset',
    ORDER_TYPE = 'order_type',
    ORDER_BY = 'order_by',
    EXCEPT = 'except',
    SEARCH_BY = 'search_by',
    SEARCH = 'search',
    PAGE = 'page'
}

export interface IPaginationMeta {
    current_page: number;
    itemCount: number;
    per_page: number;
    total: number;
    total_pages: number;
}

export interface IPaginationLinks {
    first: string;
    last: string;
    prev: string;
    next: string;
}

export interface PagedResult<T> {
    meta: IPaginationMeta;
    links: IPaginationLinks;
    data: Array<T>;
    params?: QueryParams;
}

export enum QueryType {
    PAGINATION = 1,
    SEARCH = 2
}

export interface QueryParams {
    type?: QueryType;
    orderBy?: OrderBy;
    limit?: number;
    offset?: number;
    filters?: Array<Filter>;
    except?: number | string;
    searchBy?: any;
    search?: any;
    page?: number;
    concatData?: boolean;
    relations?: Array<string>;
}

/**
 * A field filter,
 */
export interface Filter {
    /**
     * The actual name of the filter.
     */
    field: string;
    /**
     * The actual value.
     */
    value: any;
}

export interface FilterData {
    filters?: Array<Filter>;
    word?: string;
    search?: string;
    orderBy?: OrderBy;
}

export interface OrderBy {
    field: string;
    order: 'asc' | 'desc' | 'ASC' | 'DESC';
}

export class ApiError extends Error {
    error: any;
    // Custom Client side message.
    message: string;

    constructor(error: any, customMessage: string) {
        super();
        this.error = error;
        this.message = customMessage;
    }

}
