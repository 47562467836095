import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(
        protected registry: MatIconRegistry,
        protected sanitizer: DomSanitizer
    ) {
        registry.addSvgIcon(
            "juango",
            this.sanitizer.bypassSecurityTrustResourceUrl("./assets/images/juango.svg")
        );
        registry.addSvgIcon(
            'container',
            this.sanitizer.bypassSecurityTrustResourceUrl('./assets/mat-icons/container.svg')
        )
    }
}
