<h1 mat-dialog-title>Historial de Validaciones</h1>
<div  mat-dialog-content>
    <table matSort mat-table multiTemplateDataRows [dataSource]="dataSource">

        <tr class='mat-row' *matNoDataRow>
            <ng-container *ngIf='isLoading;'>
                <app-loading>Cargando</app-loading>
            </ng-container>
            <ng-container *ngIf='!isLoading && isEmpty'>
                <td class='mat-cell' colspan='9999'>
                    <app-alert-message type='info'>
                        No hay datos para visualizar.
                    </app-alert-message>
                </td>
            </ng-container>
        </tr>

        <ng-container matColumnDef="id">
            <th mat-sort-header='id' mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell [matTooltip]='row.id' *matCellDef="let row" matTooltipShowDelay='1000'>{{row.id}}</td>
        </ng-container>
        <ng-container matColumnDef="fecha">
            <th mat-sort-header='fecha' mat-header-cell *matHeaderCellDef>Fecha</th>
            <td mat-cell [matTooltip]='row.fecha' *matCellDef="let row" matTooltipShowDelay='1000'>{{row.fecha | date: 'dd/MM HH:mm'}}</td>
        </ng-container>
        <ng-container matColumnDef="regla">
            <th mat-sort-header='regla' mat-header-cell *matHeaderCellDef>Regla</th>
            <td mat-cell [matTooltip]='row.regla' *matCellDef="let row" matTooltipShowDelay='1000'>{{row.regla}}</td>
        </ng-container>
        <ng-container matColumnDef="admin">
            <th mat-sort-header='admin' mat-header-cell *matHeaderCellDef>Admin</th>
            <td mat-cell [matTooltip]='row.admin' *matCellDef="let row" matTooltipShowDelay='1000'>{{row.admin}}</td>
        </ng-container>
        <ng-container matColumnDef="resultado">
            <th mat-sort-header='resultado' mat-header-cell *matHeaderCellDef>Resultado</th>
            <td mat-cell [matTooltip]='row.resultadoTooltip' *matCellDef="let row" matTooltipShowDelay='1000'>
                <button mat-icon-button class="validacion-icon" [attr.resultado]='row.resultado'>
                    <mat-icon>{{row.resultado | reglaResultIcon}}</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<div mat-dialog-actions class='justify-content-end'>
    <button mat-button (click)='closeDialog()'>Cerrar</button>
</div>
