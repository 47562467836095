import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '../environment';

const noop = (): undefined => undefined;

/**
 * A common logger that is used by the app.
 */
@Injectable()
export class Logger {

    constructor(@Inject(ENVIRONMENT) protected env: Environment) {}

    private invokeConsoleMethod(type: keyof Console, ...args: any[]): void {
        // Don't log in production
        if (!this.env.production) {

            const logFn: any = console[type] || console.log || noop;
            logFn.apply(console, args);
        }
    }

    info(...args: Array<any>): void {
        this.invokeConsoleMethod('info', args);
    }

    warn(...args: Array<any>): void {
        this.invokeConsoleMethod('warn', args);
    }

    error(...args: Array<any>): void {
        this.invokeConsoleMethod('error', args);
    }

    debug(...args: Array<any>): void {
        this.invokeConsoleMethod('debug', args);
    }
}
