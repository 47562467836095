import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'mat-select-all-option',
    templateUrl: './mat-select-all.component.html',
    styleUrls: ['./mat-select-all.component.scss']
})
export class MatSelectAllComponent {
    @Input() control: AbstractControl;
    @Input() values: Array<any> = [];

    isChecked(): boolean {
        return this.control.value && this.values.length
            && this.control.value.length === this.values.length;
    }

    isIndeterminate(): boolean {
        return this.control.value && this.values.length && this.control.value.length
            && this.control.value.length < this.values.length;
    }

    toggleSelection(change: MatCheckboxChange): void {
        if (change.checked) {
            this.control.setValue(this.values);
        } else {
            this.control.setValue([]);
        }
    }

}
