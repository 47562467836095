import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {AfterViewInit, Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DefaultValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl} from '@angular/forms';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {BaseDTO} from '@obrador/api-interfaces';
import {Logger, PagedResult, QueryParams, QueryType, RestService} from '@obrador/core';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, mergeMap, scan, skip} from 'rxjs/operators';
import {FormAutocompleteComponent, InputTextEvent} from '../form-autocomplete/form-autocomplete.component';

@Component({
    selector: 'app-form-infinite-autocomplete',
    templateUrl: './form-infinite-autocomplete.component.html',
    styleUrls: ['./form-infinite-autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FormInfiniteAutocompleteComponent,
            multi: true
        }
    ]
})
export class FormInfiniteAutocompleteComponent extends FormAutocompleteComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild(DefaultValueAccessor) accessor: DefaultValueAccessor;
    @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
    @ViewChild(MatAutocomplete) autoComplete: MatAutocomplete;

    /**
     * Function that builds the parameters. This is needed to make the api calls.
     */
    @Input() buildParamsFn: (...params: any[]) => QueryParams;
    /**
     * RestService to bind. Uses the pagination() method.
     */
    @Input() restService: RestService<BaseDTO>;

    /**
     * Initial query. When pagination resets (input changes), it uses this property.
     * to start querying again.
     */
    @Input() initialQuery: QueryParams;

    /**
     * Emit value when the input is empty.
     */
    @Input() emitWhenEmpty = true;
    /**
     * Icon position (magnifying glass)
     */
    @Input() iconPosition: 'left' | 'right' = 'left';
    /**
     * Clears the input text whenever the user selects an option.
     */
    @Input() clearOnSelect: boolean;
    /**
     * If the component is currently querying for info.
     */
    isQuerying: boolean;

    /**
     * Workaround to the emitter of autocomplete is currently not firing on the first time.
     * This is tied to the autocomplete directive which subscribes to the scroll event.
     * This needs to be fired one time.
     * See: https://github.com/angular/components/issues/13650
     */
    emitWorkAround: boolean;

    @ContentChild('optionTemplate') optionTemplate: TemplateRef<any>;

    paginationDisabled: boolean;
    protected currentParams: QueryParams;
    protected pagination$: BehaviorSubject<QueryParams>;
    protected destroy$: Subject<void> = new Subject<void>();

    constructor(logger: Logger) {
        super(logger);
    }

    ngOnInit(): void {
        if (!this.initialQuery) {
            this.initialQuery = {
                page: 1,
                limit: 100,
                orderBy: { field: 'id', order: 'desc' },
                concatData: true
            }
        }
        if (!this.formControl) {
            // If there is not aa form control create an internal one.
            this.formControl = new UntypedFormControl('');
        }
        if (!this.keyLabel) {
            this.logger.warn('FormInfiniteAutocompleteComponent: KeyLabel must be defined');
        }
        this.subscribeKeyUp();
    }

    subscribeKeyUp(): void {
        this.keyUp$ = new BehaviorSubject('');
        this.keyUp$.pipe(
            takeUntil(this.destroy$),
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(val => {
            this.inputChangedEvent(val);
        });
        this.inputChanged$ = new BehaviorSubject<InputTextEvent>({ value: '', emitLookup: false });
        this.inputChanged$
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(event => {
                if (event.emitLookup) {
                    this.onSearch(event.value);
                }
            });
    }

    ngAfterViewInit(): void {
        this.subscribePagination();
    }

    subscribePagination(): void {
        this.pagination$ = new BehaviorSubject(this.initialQuery);
        this.pagination$
            .pipe(
                takeUntil(this.destroy$),
                skip(1),
                mergeMap((query: QueryParams) => {
                    return this.restService.paginated(query);
                }),
                catchError(error => this.handleError(error)),
                scan((a: PagedResult<any>,b: PagedResult<any>)=> {
                    // Disable pagination when result length is less than limit.
                    if (!b.links.next || b.data?.length === 0) {
                        this.paginationDisabled = true;
                    }
                    return {
                        params: b.params,
                        data: b.params.concatData && b.params.type !== QueryType.SEARCH ? a.data.concat(b.data) : b.data,
                        meta: b.meta,
                        links: b.links
                    };
                })
            )
            .subscribe(result => {
                if (!this.emitWorkAround) {
                    this.autoComplete.opened.emit();
                    this.emitWorkAround = true;
                }
                this.elements = result.data || [];
                this.currentParams = result.params;
                this.dataFetched.emit(result.data);
                this.isQuerying = false;
            });
    }

    advancePagination(currentQueryParams: QueryParams): QueryParams {
        return {
            ...currentQueryParams,
            page: currentQueryParams.page + 1
        };
    }

    getNextBatch(): void {
        if (!this.paginationDisabled) {
            this.onPaginate();
        }
    }

    onSearch(text: string): void {
        if (!this.emitWhenEmpty && !text) {
            return;
        }
        const queryParams = this.initialQuery;
        const params = this.buildQueryParams(queryParams, text);
        this.paginationDisabled = false;
        this.isQuerying = true;
        this.pagination$.next({
            ...params,
            type: QueryType.SEARCH
        });
    }

    refresh(): void {
        this.pagination$.next({
            ...this.initialQuery,
            type: QueryType.SEARCH
        });
    }

    /**
     * OnPaginate emits a pagination advance on pageState, triggering the REST API.
     */
    onPaginate(): void {
        if (!this.paginationDisabled) {
            const queryParams = this.pagination$.getValue() || this.initialQuery;
            // concatenate data only from onPaginate Event.
            this.isQuerying = true;
            this.pagination$.next(this.advancePagination({
                ...queryParams,
                type: QueryType.PAGINATION
            }));
        }
    }

    buildQueryParams(params: any, text: string): QueryParams {
        if (this.buildParamsFn) {
            return this.buildParamsFn(params, text);
        }
        return {
            ...params
        };
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent): void {
        const value = event.option.value;
        const emitValue = !this.bindObject && value[this.keyLabel] ? value[this.keyLabel] : value;
        this.optionSelected.emit(emitValue);
        if (this.clearOnSelect && this.formControl) {
            this.formControl.setValue('', { emitEvent: false });
        }
    }
}
