import { Injectable, Injector } from '@angular/core';
import { CuentaDTO } from "@obrador/api-interfaces";
import { RestService } from "../rest.service";
import { Resource } from "../rest.resources";

@Injectable({
  providedIn: 'root'
})
export class CuentaService extends RestService<CuentaDTO>{

    constructor(
      protected injector: Injector
    ) {
      super(injector, Resource.CUENTAS)
    }

}
