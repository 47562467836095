<h2 mat-dialog-title>Historial Novedades</h2>
<div mat-dialog-content>
    <app-alert-message *ngIf='tiempoMsg' [message]="tiempoMsg" type='info'></app-alert-message>
    <ngx-timeline
        [events]="timelineNovedades"
        [reverseOrder]="true"
        [langCode]="'es'"
        [changeSideInGroup]="changeSideEnum.ALL"
        [groupEvent]="eventGroupEnum.MONTH_YEAR"
        [innerEventCustomTemplate]="innerEventCustomTemplate"
        [enableAnimation]="false"
    >
        <ng-template #innerEventCustomTemplate let-event=event let-index=index>
            <div class="custom-event">
                <div class="timeline-item-date">
                    <div class="timeline-date">
                        <mat-icon>event</mat-icon>
                        <span>{{ event.eventInfo.timestamp | date: 'dd/MM/YYYY HH:mm' }}</span>
                    </div>
                    <mat-icon class="trash-icon" *ngIf="primerId===event.eventInfo.id" (click)="borrarNovedad(event.eventInfo.id)">delete</mat-icon>
                </div>
                <div class="timeline-item">
                    <mat-icon>account_circle</mat-icon>
                    <span>{{ event.eventInfo.title }}:</span>
                </div>
                <div class="timeline-item" *ngIf="event.eventInfo.alarma">
                    <mat-icon>notifications_active</mat-icon>
                    <span>{{ event.eventInfo.alarma.fecha | date: 'dd/MM/YYYY HH:mm' }}</span>
                </div>
                <div class="timeline-item" *ngIf="event.eventInfo.alarma">
                    <mat-icon>event</mat-icon>
                    <span>{{ event.eventInfo.instancia }}</span>
                </div>
                <div class="timeline-item" *ngIf="event.eventInfo.motivo">
                    <mat-icon>psychology_alt</mat-icon>
                    <span>{{ event.eventInfo.motivo }}</span>
                </div>
                <div class="timeline-item">
                    <mat-icon>chat</mat-icon>
                    <span class="timeline-comment">{{ event.eventInfo.description }}</span>
                </div>
                <div class="timeline-item alarma-novedad" *ngIf="primerId===event.eventInfo.id && alarmaNovedad">
                    <mat-icon>alarm</mat-icon>
                    <span>Pospone alarma/s {{ alarmaNovedad.tiempo_postpuesta }} min. hasta {{ alarmaNovedad.fecha_fin | date: 'HH:mm'}}</span>
                </div>
            </div>
        </ng-template>
    </ngx-timeline>
<!--    <mzd-timeline [alternateSide]="false" firstContentSide='right' *ngFor='let novedad of novedades; let i = index; trackById'>-->
<!--        &lt;!&ndash;    <mzd-icon><strong>1</strong></mzd-icon>&ndash;&gt;-->
<!--        <mzd-timeline-content>-->
<!--            <ng-container *ngIf='isLoading;'>-->
<!--                <app-loading>Cargando</app-loading>-->
<!--            </ng-container>-->
<!--            <div>-->
<!--                <div class="timeline-item-date">-->
<!--                    <div class="timeline-date">-->
<!--                        <mat-icon>event</mat-icon>-->
<!--                        <span>{{ novedad.fecha | date: 'dd/MM/YYYY HH:mm:ss' }}</span>-->
<!--                    </div>-->
<!--                    <mat-icon class="trash-icon" *ngIf="i===0" (click)="borrarNovedad(novedad.id)">delete</mat-icon>-->
<!--                </div>-->
<!--                <div class="timeline-item">-->
<!--                    <mat-icon>account_circle</mat-icon>-->
<!--                    <span>{{ novedad.admin.nombre }} {{novedad.admin.apellido }}:</span>-->
<!--                </div>-->
<!--                <div class="timeline-item">-->
<!--                    <mat-icon>chat</mat-icon>-->
<!--                    <span class="timeline-comment">{{ novedad.comentario }}</span>-->
<!--                </div>-->
<!--                <div class="timeline-item alarma-novedad" *ngIf="i === 0 && alarmaNovedad">-->
<!--                    <mat-icon>alarm</mat-icon>-->
<!--                    <span>Pospone alarma/s {{ alarmaNovedad.tiempo_postpuesta }} min. hasta {{ alarmaNovedad.fecha_fin | date: 'HH:mm'}}</span>-->
<!--                </div>-->
<!--            </div>-->
<!--        </mzd-timeline-content>-->
<!--    </mzd-timeline>-->
</div>
<div mat-dialog-actions class='justify-content-end'>
    <button mat-button (click)='closeDialog()'>Cerrar</button>
</div>
