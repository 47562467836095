import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'percentage'
})
export class PercentagePipe implements PipeTransform {

    transform(value: any): any {
        if (value === null || value === undefined || isNaN(value)) {
            return '0%';
        }
        const numberValue = Number(value).toFixed(0);
        return `${numberValue}%`
    }
}
