import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FamiliaServicio } from '@obrador/api-interfaces';
import { combineLatest, debounceTime, distinctUntilChanged, map, startWith, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { EnumSelectModel, SelectModel } from '../../services';

export interface FilterFamiliaEvent {
    familia: FamiliaServicio;
    text: string;
    estado: EstadoTableFilter;
}

export enum EstadoTableFilter {
    ABIERTA = 1,
    CERRADA = 2,
    CON_PLANES = 3,
    SIN_PLANES = 4,
    CIERRE_CANTIDADES = 5
}

@Component({
    selector: 'app-table-filter-familia',
    templateUrl: './table-filter-familia.component.html',
    styleUrls: ['./table-filter-familia.component.scss']
})
export class TableFilterFamiliaComponent implements OnInit, OnDestroy {

    @Input() familias: Array<FamiliaServicio>;

    @Output() readonly changed: EventEmitter<FilterFamiliaEvent> = new EventEmitter();

    form: UntypedFormGroup;
    estados: Array<SelectModel<EstadoTableFilter>>;

    protected sub: Subscription;

    constructor(protected fb: UntypedFormBuilder, protected enumModels: EnumSelectModel) {
    }

    ngOnInit(): void {
        this.estados = this.enumModels.getEstadosFiltroFamilia();
        this.form = this.fb.group({
            familia: [null, []],
            text: [null, []],
            estado: [null, []]
        });
        this.sub = combineLatest([
            this.form.get('text').valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged(),
                startWith(null)
            ),
            this.form.get('familia').valueChanges.pipe(
                startWith(null)
            ),
            this.form.get('estado').valueChanges.pipe(
                startWith(null)
            )
        ])
            .pipe(
                // Evitar emitir el primero donde los 3 empiezan en null.
                skip(1),
                map(results => {
                    return {
                        familia: results[1],
                        text: results[0],
                        estado: results[2]
                    };
                })
            )
            .subscribe(value => {
                this.changed.emit(value);
            });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

}
