import { BaseDTO } from './api-interfaces';
import { ControlDesvioError, FuenteDatos, OPERADOR } from './enums';
import { Reserva } from './reserva';

export interface ControlDesvioBase extends BaseDTO {
    nombre_campo?: string;
    campo_interno?: string;
    campo_externo?: string;
    operador?: OPERADOR;
    tolerancia?: number;
    hash?: string;
}

export interface ControlDesvio extends ControlDesvioBase {
    control_reserva?: ControlReserva;
    tipo?: string;
}

export interface ControlReserva extends BaseDTO {
    fuente_datos: FuenteDatos;
    endpoint: string;
    control_desvios: ControlDesvio[]; // Asegúrate de tener definida la interfaz ControlDesvio
    reserva: Reserva; // Define la interfaz o clase Reserva si aún no está definida
    reservaId: number;
}

export interface TipoControlDesvio extends BaseDTO {
    nombre: string;
}

export interface IControlDesvioResult extends BaseDTO {
    control: ControlDesvioBase;
    rows: IControlDesvioRow[];
    error?: ControlDesvioError;
}

export interface IControlDesvioRow {
    dato: string;
    fuente_interna: number;
    fuente_externa: number;
    resultado: boolean;
}
