<h1 mat-dialog-title>{{ config.title }}</h1>
<div mat-dialog-content>
    <div [attr.type]='config.type' class='dialog-alert-content'>
        <mat-icon>
            {{ icon }}
        </mat-icon>
        <p>{{ config.message }}</p>
    </div>
</div>
<div mat-dialog-actions class='justify-content-end'>
    <button color='primary' (click)='close()' mat-button cdkFocusInitial> {{ config.okText || 'Ok'}}</button>
</div>
