<mat-card>
    <mat-card-content>
        <label class='numeric-card-title'>
            {{ title }}
        </label>
        <label class='numeric-card-subtitle'>
            {{ number }}
        </label>
    </mat-card-content>
</mat-card>
