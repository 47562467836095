import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogAlertConfig {
    message: string;
    title: string;
    type: 'info' | 'warn' | 'error';
    okText: string;
}

@Component({
    selector: 'app-dialog-alert',
    templateUrl: './dialog-alert.component.html',
    styleUrls: ['./dialog-alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogAlertComponent implements OnInit {

    icon: string;

    constructor(
        protected dialog: MatDialogRef<DialogAlertComponent>,
        @Inject(MAT_DIALOG_DATA) public config: DialogAlertConfig
    ) {
    }

    ngOnInit(): void {
        switch(this.config.type) {
            case 'info':
                this.icon = 'info';
                break;
            case 'warn':
                this.icon = 'info';
                break;
            case 'error':
                this.icon = 'error';
                break;
            default:
                break;
        }
    }

    close(): void {
        this.dialog.close();
    }

}
