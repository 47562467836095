import { Injectable } from '@angular/core';
import { AppEvents, Events } from '@obrador/core';

@Injectable()
export class SideBarService {

    constructor(protected events: Events) {
    }

    toggleSideNavRecursos(): void {
        this.events.publish(AppEvents.SIDENAV_RECURSOS_TOGGLE);
    }

}
