<section class='recursos-convocatoria-header'>
    <h3>Recursos</h3>
    <button (click)='close()' mat-icon-button>
        <mat-icon>close</mat-icon>
    </button>
</section>
<section class='recursos-convocatoria-search'>
    <div class='row'>
        <div class='col-md-7 col-sm-12'>
            <app-form-infinite-autocomplete
                [restService]='empresaService'
                [bindObject]='true'
                [initialQuery]='initialQueryEmpresas'
                [buildParamsFn]='buildQueryEmpresaCuenta'
                (optionSelected)='onEmpresaChange($event)'
                keyLabel='nombre'
                [placeholder]='"Empresa"'
            >
                <ng-template #optionTemplate let-element>
                    <span>{{element.nombre}}</span>
                    <span class='option-text--smaller'>
                <{{ element.rut }}>
            </span>
                </ng-template>
            </app-form-infinite-autocomplete>
<!--            <app-autocomplete-empresa placeholder='Buscar empresa...' [(ngModel)]='empresa' (ngModelChange)='onEmpresaChange()'></app-autocomplete-empresa>-->
        </div>
        <div class='col-md-5 col-sm-12'>
            <mat-form-field>
                <input required='true' matInput [matDatepicker]="picker" [(ngModel)]='selectedDate' (ngModelChange)='onDateChange()'>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <mat-button-toggle-group multiple='true' [(ngModel)]='filters' (ngModelChange)='onFiltersChange()'>
        <mat-button-toggle [value]="Filters.CONDUCTORES">Conductores</mat-button-toggle>
        <mat-button-toggle [value]="Filters.MOTORIZADOS">Motorizados</mat-button-toggle>
        <mat-button-toggle [value]="Filters.REMOLQUES">Remolques</mat-button-toggle>
    </mat-button-toggle-group>
</section>
<section class='recursos-convocatoria-container'>
    <mat-list *ngIf='empresa; else sinEmpresa'>
        <ng-container *ngIf='conductores?.length > 0'>
            <app-recurso-motorizado-conductor [conductor]='conductor' *ngFor='let conductor of conductores; trackById'>
            </app-recurso-motorizado-conductor>
        </ng-container>
        <ng-container *ngIf='motorizados?.length > 0'>
            <app-recurso-motorizado-item [entity]='m' [isMotorizado]='true' *ngFor='let m of motorizados; trackById'>
            </app-recurso-motorizado-item>
        </ng-container>
        <ng-container *ngIf='remolques?.length > 0'>
            <app-recurso-motorizado-item [entity]='r' [isMotorizado]='false' *ngFor='let r of remolques; trackById'>
            </app-recurso-motorizado-item>
        </ng-container>
        <app-alert-message *ngIf='noData' message='No hay elementos' type='info'></app-alert-message>
    </mat-list>
    <ng-template #sinEmpresa>
        <app-alert-message message='Seleccione una empresa...' type='info'></app-alert-message>
    </ng-template>
</section>
