import {Convocatoria} from "./convocatoria";
import {ModeloDocumentacion} from "./documento";
import {EstadoEmpresa} from "./enums";
import {RequerimientoEmpresa} from "./requerimiento";

export interface Message {
    message: string;
}

export interface BaseDTO {
    id?: any;
    created_at?: Date;
    updated_at?: Date;
}

export interface ResultDTO {
    result: boolean;
}

export interface Empresa extends BaseDTO {
    id_migrador: number;
    doc_id: string;
    nombre: string;
    razon_social: string;
    rut: string;
    categoria: string;
    usa_app: boolean;
    telefono: string;
    celular: string;
    email: string;
    direccion: string;
    departamento: string;
    bps?: Date;
    dgi?: Date;
    seguro?: Date;
    planilla?: Date;
    hash?:                    string;
    ultima_convocatoria?: Partial<Convocatoria>;
    convocatorias?: Partial<Convocatoria>[];
    convocatorias_aceptadas?: number;
    gasto_gasoil?: number;
    gasto_alquiler?: number;
    generado_remitos?: number;
    ratio_generado?: number;
    modelo_documentacion?: ModeloDocumentacion;
    estado?: EstadoEmpresa;
    prioridad?: number;
    requerimientos?: RequerimientoEmpresa[];
    requiereremolque?: boolean;
}


export interface Motorizado extends BaseDTO {
    id_migrador: number;
    matricula: string;
    tipo_motorizado_id: number;
    nombre_tipo: string;
    marca: string;
    modelo: string;
    inhabilitada: boolean;
    rut_company: string;
    modelo_documentacion?: ModeloDocumentacion;
}

export interface Remolque extends Motorizado {
    config: any;
}

export interface EntityOperation<T> {
    id: number | string;
    op: Operation;
    entity: T;
}

export enum Operation {
    CREATE = 1, UPDATE = 2, DELETE = 3, NOOP = 4
}
