import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { compareAsc, differenceInDays, isBefore } from 'date-fns';
import { DateUtils } from '@obrador/common';

export class CustomDateValidators {
    static fromToDate(fromControl: AbstractControl, toControl: AbstractControl, fromDateField: string,
                      toDateField: string, errorName: string = 'fromToDate'): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
            // const fromDate = new Date(formGroup.get(fromDateField).value);
            const fromDate = new Date(fromControl.value);
            const toDate = new Date(toControl.value);
            if ((fromDate !== null && toDate !== null) && !isBefore(fromDate, toDate)) {
                return {[errorName]: true};
            }
            // fromControl.updateValueAndValidity({ emitEvent: false });
            // toControl.updateValueAndValidity({ emitEvent: false });
            return null;
        };
    }
}

export class CustomDatetimeValidators {
    static fromToTime(fromTimeField: string, toTimeField: string, fromDateField: string = 'fecha_desde',
                      toDateField: string = 'fecha_hasta',errorName: string = 'fromToDateTime'): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
            const from = typeof formGroup.get(fromDateField).value === 'string' ?
                new Date(formGroup.get(fromDateField).value) : formGroup.get(fromDateField).value;
            const to = typeof formGroup.get(toDateField).value === 'string' ?
                new Date(formGroup.get(toDateField).value) : formGroup.get(toDateField).value;
            const fromDate = DateUtils.mixFechaHora(from, formGroup.get(fromTimeField).value);
            const toDate = DateUtils.mixFechaHora(to, formGroup.get(toTimeField).value);
            if ((fromDate !== null && toDate !== null)) {
                if (differenceInDays(to, from) > 30) {
                    return { diffMajor30: true};
                }
                if (!isBefore(fromDate, toDate)) {
                    return {[errorName]: true};
                }
            }
            return null;
        };
    }

    static dateRangeValidator(startDateKey: string, endDateKey: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const startDate = control.get(startDateKey)?.value;
            const endDate = control.get(endDateKey)?.value;

            if (!startDate || !endDate) {
                // Si alguna de las fechas no está presente, no se valida.
                return null;
            }

            // Utiliza date-fns para comparar las fechas.
            const comparison = compareAsc(new Date(startDate), new Date(endDate));

            // Si la fecha de inicio es posterior a la fecha de finalización, se retorna un error.
            return comparison === 1 ? { 'invalidDateRange': true } : null;
        };
    }
}
