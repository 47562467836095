<mat-list-item class='recurso-conductor-item'>
    <img [src]='conductor.photo_URL || DEFAULT_IMAGE' alt='Usuario' [onErrorImage]='DEFAULT_IMAGE'>
    <div class='recurso-conductor-lines'>
        <span class='recurso-line-title'>{{ conductor.display_name || conductor.email }}</span>
        <p class='text-line'>
            <mat-icon>badge</mat-icon>
            {{ conductor.ci || 'Sin cédula' }}
        </p>
        <p class='text-line'>
            <mat-icon>smartphone</mat-icon>
            {{ conductor.phone_number || 'Sin celular' }}
        </p>
        <p class='text-line recurso-convocado' *ngIf='conductor.sub_convocatorias?.length > 0; else sinConvocar'>
            <mat-icon color='warn'>report</mat-icon>
            Convocado {{ conductor.sub_convocatorias[0].fecha_inicio | date: 'dd/MM/yyyy HH:mm' }}
        </p>
        <ng-template #sinConvocar>
            <p class='text-line'>
                <i>Sin Convocar</i>
            </p>
        </ng-template>
    </div>
</mat-list-item>
