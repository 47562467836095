<mat-form-field appearance='outline'>
  <input matInput type="search" #input
         autocomplete="off"
         (keyup)="keyUp$.next(input.value)"
         [formControl]="formControl"
         (paste)="inputChangedEvent(input.value)"
         [placeholder]="placeholder"
         [matAutocomplete]="inputAutocomplete"
         ngDefaultControl
  />
  <mat-icon matPrefix *ngIf="iconPosition === 'left'">
    search
  </mat-icon>
  <mat-icon matSuffix *ngIf="iconPosition === 'right'">
      search
  </mat-icon>
  <mat-autocomplete #autoComplete [displayWith]="displayWithFn(keyLabel)"
                    (scrollableInfinite)="getNextBatch()"
                    #inputAutocomplete="matAutocomplete"
                    (optionSelected)="onOptionSelected($event)"
                    style="overflow: hidden;"
  >
    <mat-option *ngFor="let element of elements; trackBy: trackByFn" [value]="element">

      <ng-container *ngTemplateOutlet="optionTemplate; context: {$implicit: element}">

      </ng-container>
    </mat-option>
  </mat-autocomplete>
  <ng-content error-content></ng-content>
</mat-form-field>


