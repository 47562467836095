import { Motorizado, Remolque } from './api-interfaces';
import { Conductor } from './conductor';
import { Convocatoria } from './convocatoria';
import { SubConvocatoria } from './sub-convocatoria';

export interface RecursoMotorizado extends Motorizado {
    convocatorias: Array<Partial<Convocatoria>>;
    cantidad?: number;
}

export interface RecursoConductor extends Conductor {
    sub_convocatorias: Array<Partial<SubConvocatoria>>;
    cantidad?: number;
}

export interface RecursoRemolque extends Remolque {
    convocatorias: Array<Partial<Convocatoria>>;
    cantidad?: number;
}

export interface AlarmaRecurso {
    empresaId: number;
    empresa: string;
    fecha_vencimineto: Date;
    matricula: string;
    nombre: string;
    code: string;
    tipo: string;
}
