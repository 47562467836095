import { Pipe, PipeTransform } from '@angular/core';
import { ArrayUtils } from '@obrador/common';

@Pipe({
    name: 'nestedObject'
})
export class NestedObjectPipe implements PipeTransform {

    transform(value: any, pathString: string, pipes?: { pipe: PipeTransform, args?: Array<any> }[]): any {
        let current = value;
        const paths = pathString.split('.');

        for (let i = 0; i < paths.length; ++i) {
            // Cuando es null, quiebra el for loop y termina aplicando ese value a los pipes
            if (current[paths[i]] === undefined || current[paths[i]] === null) {
                current = null;
                break;
            } else {
                current = current[paths[i]];
            }
        }
        if (pipes && pipes.length > 0) {
            pipes.forEach(pipeObj => {
                if (pipeObj.args && ArrayUtils.isNotEmpty(pipeObj.args)) {
                    current = pipeObj.pipe.transform(current, ...pipeObj.args);
                } else {
                    current = pipeObj.pipe.transform(current);
                }
            });
        }

        return current;
    }
}
