import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseDTO, RecursoConductor, RecursoMotorizado, RecursoRemolque } from '@obrador/api-interfaces';
import { Observable } from 'rxjs';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class RecursoService extends RestService<BaseDTO> {

    constructor(protected injector: Injector) {
        super(injector, Resource.RECURSOS);
    }

    getMotorizados(id_empresa: number, start: Date): Observable<Array<RecursoMotorizado>> {
        const params = new HttpParams().set('from', start.toISOString());
        return this.http.get<Array<RecursoMotorizado>>(`${this.buildURL()}/${id_empresa}/${Resource.MOTORIZADOS}`, {params});
    }

    getRemolques(id_empresa: number, start: Date): Observable<Array<RecursoRemolque>> {
        const params = new HttpParams().set('from', start.toISOString());
        return this.http.get<Array<RecursoRemolque>>(`${this.buildURL()}/${id_empresa}/${Resource.REMOLQUES}`, {params});
    }

    getConductores(id_empresa: number, start: Date): Observable<Array<RecursoConductor>> {
        const params = new HttpParams().set('from', start.toISOString());
        return this.http.get<Array<RecursoConductor>>(`${this.buildURL()}/${id_empresa}/${Resource.CONDUCTORES}`, {params});
    }

    getAlarmas(cuentaId: number): Observable<any> {
        return this.http.get<any>(`${this.buildURL()}/alarmas?cuentaId=${cuentaId}`);
    }

}
