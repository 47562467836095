import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AdminDTO, LoginResponse } from '@obrador/api-interfaces';
import { isEmpty } from 'lodash';
import {
    concat,
    first,
    lastValueFrom,
    NEVER,
    Observable,
    ObservableInput,
    ObservedValueOf,
    of,
    OperatorFunction,
    switchMap
} from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

function startFrom<T, O extends ObservableInput<any>>(start: O): OperatorFunction<T, T | ObservedValueOf<O>> {
    return (source: Observable<T>) => concat(start, source)
}

@Injectable({
    providedIn: 'root'
})
export class AuthService extends RestService<AdminDTO>{

    private readonly ADMIN_KEY = 'admin';
    private readonly user$: Observable<LoginResponse | undefined>;

    constructor(
        protected router: Router,
        protected http: HttpClient,
        protected injector: Injector,
        protected storage: StorageMap
    ) {
        super(injector, Resource.AUTH);
        this.user$ = this.storage.watch(this.ADMIN_KEY)
            .pipe(
                map(data => {
                    if (!data) {
                        return null;
                    } else {
                        return data as LoginResponse;
                    }
                }),
                shareReplay(1)
            );
    }

    getUser(): Promise<LoginResponse> {
        return lastValueFrom(
            this.user$.pipe(
                first()
            )
        );
    }

    async getAdmin(): Promise<AdminDTO> {
        return (await this.getUser())?.admin || null;
    }

    getLoginData(): Observable<LoginResponse> {
        return this.user$.pipe(
            first()
        );
    }

    authState(): Observable<LoginResponse> {
        return this.user$;
    }

    /**
     * Solo emite cuando tiene usuario.
     */
    userChanged(): Observable<AdminDTO> {
        return this.user$
            .pipe(
                switchMap(data => {
                    if (!data) {
                        return NEVER;
                    }
                    return of(data);
                }),
                map(d => {
                    return d?.admin || null;
                })
            );
    }

    async isLoggedIn(): Promise<boolean> {
        const user = await this.getUser();
        return !isEmpty(user);
    }

    login(username: string, password: string): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${this.buildURL()}/login`, { username, password })
            .pipe(
                switchMap(data => {
                    return this.storage.set(this.ADMIN_KEY, data);
                })
            );
    }

    async logout() {
        this.storage.delete(this.ADMIN_KEY).subscribe();
        await this.router.navigateByUrl('auth')
    }

}
