import { NgForOf } from '@angular/common';
import { Directive, Host, Input, NgIterable } from '@angular/core';
import { BaseDTO } from '@obrador/api-interfaces';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ngForTrackByProperty]'
})
export class NgForTrackByPropertyDirective<T> {

    @Input() ngForOf!: NgIterable<T>;
    @Input() ngForTrackByProperty!: keyof T;

    constructor(@Host() ngForOfDir: NgForOf<T>) {
        ngForOfDir.ngForTrackBy = (_, item: T): T[keyof T] => item[this.ngForTrackByProperty];
    }
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ngForTrackByIndex]'
})
export class NgForTrackByIndexDirective<T> {

    @Input() ngForOf!: NgIterable<T>;

    constructor(@Host() ngForOfDir: NgForOf<T>) {
        ngForOfDir.ngForTrackBy = (index: number): number => index;
    }
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ngForTrackById]'
})
export class NgForTrackByIdDirective<T extends BaseDTO> {

    @Input() ngForOf!: NgIterable<T>;

    constructor(@Host() ngForOfDir: NgForOf<T>) {
        ngForOfDir.ngForTrackBy = (_, item: T) => item.id;
    }
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ngForTrackByValue]'
})
export class NgForTrackByValueDirective<T> {
    @Input() ngForOf!: NgIterable<T>;

    constructor(@Host() ngForOfDir: NgForOf<T>) {
        ngForOfDir.ngForTrackBy = (index: number, value: any): any => value;
    }
}
