import { AdminDTO } from './admin';
import { BaseDTO } from './api-interfaces';
import { EstadoEventoViajeCancerbero, SistemaOrigen, TipoEstadoAlarma, TipoEventoAlarma } from './enums';
import { Viaje } from './viaje';
import { Novedad } from './novedad';
import { Reserva } from "./reserva";

export interface Alarma extends BaseDTO {
    fecha?: Date;
    viajeId: number;
    tipo: TipoAlarma;
    viaje?: Viaje;
    novedades?: AlarmaNovedad[];
    estado?: TipoEstadoAlarma;
    ultima_novedad?: AlarmaNovedad;
    eventos_alarmas?: EventoAlarma[];
    tipo_instancia?: EstadoEventoViajeCancerbero;
}

export interface TipoAlarma extends BaseDTO {
    tipo: string;
    descripcion?: string;
    tolerancia: number;
    reservaId?: number;
    reserva?: Reserva;
}

export interface AlarmaNovedad extends BaseDTO {
    tiempo_postpuesta: number;
    fecha_fin: Date;
    alarma?: Alarma;
    novedad?: Novedad;
}

export interface EventoAlarma {
    fecha: Date;
    tipo: TipoEventoAlarma;
    origen: SistemaOrigen;
    metadata: string;
    metadata_anterior?: string;
    alarma: Alarma;
    admin?: AdminDTO;
}
