import { Injectable, Injector } from '@angular/core';
import { MensajeProgramado } from '@obrador/api-interfaces';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class MensajeProgramadoService extends RestService<MensajeProgramado>{

    constructor(protected injector: Injector) {
        super(injector, Resource.MENSAJES_PROGRAMADOS);
    }
}
