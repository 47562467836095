import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Convocatoria } from '@obrador/api-interfaces';
import { DateUtils } from '@obrador/common';
import { ConvocatoriaService, SubConvocatoriaService } from '@obrador/core';
import { firstValueFrom } from 'rxjs';
import { LoadingController } from '../../controllers';
import { SnackBarService } from '../../services';
import { polluteForm } from '../../utils';
import { CustomDatetimeValidators } from '../../validators';

export interface CambiarHorarioModalOptions {
    convocatorias: Array<Convocatoria>
}

@Component({
    selector: 'app-cambiar-horario-modal',
    templateUrl: './cambiar-horario-modal.component.html',
    styleUrls: ['./cambiar-horario-modal.component.scss']
})
export class CambiarHorarioModalComponent implements OnInit {

    form: FormGroup;

    constructor(
        protected dialogRef: MatDialogRef<CambiarHorarioModalComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: CambiarHorarioModalOptions,
        protected snack: SnackBarService,
        protected loading: LoadingController,
        protected fb: FormBuilder,
        protected convocatoriaService: ConvocatoriaService,
        protected subConvocatoriaService: SubConvocatoriaService) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            fecha_inicio: [null, [Validators.required]],
            fecha_fin: [null, [Validators.required]],
            hora_inicio: [null, [Validators.required]],
            hora_fin: [null, [Validators.required]]
        }, {
            validators: [
                CustomDatetimeValidators
                    .fromToTime('hora_inicio', 'hora_fin', 'fecha_inicio', 'fecha_fin')
            ]
        });
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }

    async submit(): Promise<void> {
        if (this.form.valid) {
            try {
                this.loading.start();
                const value = this.form.value;
                const inicio = DateUtils.mixFechaHora(new Date(value.fecha_inicio), value.hora_inicio);
                const fin = DateUtils.mixFechaHora(new Date(value.fecha_fin), value.hora_fin);
                const promises = [];
                for (const convocatoria of this.data.convocatorias) {
                    const request = {
                        id: convocatoria.id,
                        fecha_inicio: inicio,
                        fecha_fin: fin
                    };
                    promises.push(firstValueFrom(this.convocatoriaService.update(request)));
                    if (convocatoria.sub_convocatorias?.length === 1) {
                        const subConvocatoria = convocatoria.sub_convocatorias[0];
                        const requestSubconvocatoria = {
                            id: subConvocatoria.id,
                            convocatoria: {
                              id: convocatoria.id
                            },
                            fecha_fin: fin,
                            fecha_inicio: inicio
                        };
                        promises.push(firstValueFrom(this.subConvocatoriaService.update(requestSubconvocatoria)));
                    }
                }
                await Promise.all(promises);
            } catch (e) {
                this.snack.unexpected();
            } finally {
                this.loading.stop();
                this.snack.success('Cambios guardados');
                this.dialogRef.close(true);
            }
        } else {
            polluteForm(this.form);
        }
    }

    shouldShowFechaError(): boolean {
        return this.form.hasError('fromToDateTime') &&
            this.form.get('fecha_inicio').valid &&
            this.form.get('fecha_fin').valid &&
            this.form.get('hora_inicio').valid &&
            this.form.get('hora_fin').valid;
    }

}
