import { HttpEvent } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class ExcelService extends RestService<any>{

    constructor(protected injector: Injector) {
        super(injector, Resource.EXCEL)
    }


    parse<T>(file: File): Observable<HttpEvent<T[]>> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<T[]>(`${this.buildURL()}/parse`, formData, {
            reportProgress: true,
            observe: 'events'
        })
        .pipe(
            catchError(err => this.handleError(err))
        );
    }

}
