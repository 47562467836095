<ng-container *ngIf='isLoading'>
    <mat-spinner></mat-spinner>
</ng-container>
<div class="overlay-content" *ngIf="!media.isCloudinary; else isCloudinary">
  <div *ngIf="media.type === 'image'">
    <mat-icon *ngIf='!isLoading'>edit</mat-icon>
    <img
      class="media-image"
      mat-card-image
      [src]="media.url">
  </div>
</div>

<ng-template #isCloudinary>
  <div class="overlay-content">
    <div *ngIf="media.type === 'image'">
      <mat-icon *ngIf='!isLoading' class="close-icon" (click)="dialogRef.close()">close</mat-icon>
      <img
        class="media-image"
        mat-card-image
        [src]="media.url"
        (load)='onImageLoad()'
        (error)='onImageError()'
      >
    </div>
  </div>
</ng-template>
