import { Injectable, Injector } from '@angular/core';
import { Pesada } from '@obrador/api-interfaces';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class PesadaService extends RestService<Pesada>{

    constructor(protected injector: Injector) {
        super(injector, Resource.PESADAS);
    }

}
