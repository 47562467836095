import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DatetimeAdapter, MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core';
import { provideLocalStoragePrefix } from '@ngx-pwa/local-storage';
import { ENVIRONMENT } from '@obrador/core';
import { DatetimeFnsAdapter } from '@obrador/shared';
import { es as esLocale } from 'date-fns/locale';
import { environment } from '../environments/environment';

export const APP_ENVIRONMENT = { provide: ENVIRONMENT, useValue: environment };
export const APP_MAT_DATE_CONFIG = { provide: MAT_DATE_LOCALE, useValue: esLocale };
export const APP_MAT_FORM_FIELD_DEFAULTS = {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: { appearance: 'outline' }
};
export const APP_DATE_TIME_ADAPTER = { provide: DatetimeAdapter, useClass: DatetimeFnsAdapter };
export const APP_MTX_DATETIME_FORMATS = {
    provide: MTX_DATETIME_FORMATS, useValue: {
        parse: {
            dateInput: 'yyyy-MM-dd',
            monthInput: 'MMMM',
            timeInput: 'HH:mm',
            datetimeInput: 'yyyy-MM-dd HH:mm'
        },
        display: {
            dateInput: 'dd/MM/yyyy',
            monthInput: 'MMMM',
            timeInput: 'HH:mm',
            datetimeInput: 'dd/MM/yyyy HH:mm',
            monthYearLabel: 'yyyy MMMM',
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'MMMM yyyy',
            popupHeaderDateLabel: 'MMM dd, ddd'
        }
    }
};

export const APP_LOCALSTORAGE_PREFIX = provideLocalStoragePrefix(environment.storage_prefix)
