import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Conductor, EventoViaje, Pesada, TipoEventoViaje, Viaje } from '@obrador/api-interfaces';
import { PesadaService, ViajeService } from '@obrador/core';
import _ from 'lodash';
import { finalize, lastValueFrom } from 'rxjs';
import { ConfirmDialog } from '../../decorators';
import { SnackBarService } from '../../services';
import {
    HistoricoNovedadesComponent
} from '../historico-novedades/historico-novedades.component';
import {
    DialogHistoricoValidacionConfig,
    HistoricoValidacionDialogComponent
} from '../historico-validacion-dialog/historico-validacion-dialog.component';

export interface DialogViajeData {
    id?: number;
    viaje?: Viaje;
}

@Component({
    selector: 'app-dialog-viaje-api',
    templateUrl: './dialog-viaje-api.component.html',
    styleUrls: ['./dialog-viaje-api.component.scss']
})
export class DialogViajeApiComponent implements OnInit {

    isLoading: boolean;
    data: Viaje;
    createdBy: EventoViaje;
    validatedBy: EventoViaje;
    usuarioApp: Partial<Conductor>
    tieneValidaciones: boolean;
    tieneNovedades: boolean;

    constructor(
        protected dialog: MatDialogRef<DialogViajeApiComponent>,
        protected viajeService: ViajeService,
        protected matDialog: MatDialog,
        protected pesadaService: PesadaService,
        protected snack: SnackBarService,
        @Inject(MAT_DIALOG_DATA) public entryData: DialogViajeData
    ) {
    }

    ngOnInit(): void {
        this.isLoading = true;
        if (this.entryData.viaje) {
            this.isLoading = false;
            this.data = this.entryData.viaje;
            this.tieneValidaciones = this.data?.viaje_api?.eventos_validacion?.length > 0;
            this.tieneNovedades = this.data.novedades?.length > 0;
        } else {
            this.viajeService.findOne(this.entryData.id)
                .pipe(
                    finalize(() => {
                        this.isLoading = false;
                    })
                )
                .subscribe(v => {
                    this.data = v;
                    this.usuarioApp = v.viaje_api?.usuario_app;
                    this.tieneValidaciones = v.viaje_api?.eventos_validacion?.length > 0;
                    this.tieneNovedades = this.data.novedades?.length > 0;
                    this.createdBy = v.eventos ? _.chain(v.eventos)
                        .filter(e => e.tipo === TipoEventoViaje.CREA_VIAJE)
                        .first()
                        .value(): null;
                    this.validatedBy = v.eventos ? _.chain(v.eventos)
                        .filter(e => e.tipo === TipoEventoViaje.VALIDACION_VIAJE)
                        .orderBy('fecha', ['desc'])
                        .first()
                        .value(): null;
                });
        }
    }

    openURL(url: string): void {
        if (url && url?.startsWith('https')) {
            window.open(url, '_blank', 'noopener');
        }
    }

    async openModalValidaciones(viajeId: number): Promise<void> {
        this.matDialog.open(HistoricoValidacionDialogComponent, {
            data: {
                viajeId
            } as DialogHistoricoValidacionConfig,
            width: '750px'
        });
    }

    openModalNovedades(viaje: Viaje): void {
        this.matDialog.open(HistoricoNovedadesComponent, {
            data: {
                viajeId: viaje.id
            },
            minWidth: 700
        });
    }

    close(): void {
        this.dialog.close();
    }


    @ConfirmDialog('matDialog',{
        title: 'Confirmación',
        message: '¿Está seguro de que quiere eliminar la pesada?',
        okText: 'Confirmar',
        cancelText: 'Cancelar',
    })
    async borrarPesada(p: Pesada): Promise<void> {
        try {
            await lastValueFrom(this.pesadaService.delete(p.id));
            this.data.viaje_api.pesadas = this.data.viaje_api.pesadas.filter(p => p.id !== p.id);
            this.snack.success('Cambios guardados.')
        } catch (e) {
            this.snack.unexpected();
        }
    }

}
