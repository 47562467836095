import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDigitOnly]'
})
export class DigitOnlyDirective {

  private readonly regex: RegExp = new RegExp(/^[0-9]+$/g);

  constructor(protected ngControl: NgControl) {
  }

  @HostListener('input', [ '$event' ])
  onKeyDown(event: any): boolean {
    const value = event.target.value;
    if (typeof value === 'string') {
      let newValue = value;
      if (!this.regex.test(value)) {
        newValue = newValue.replace(/[^0-9]/g, '');
      }
      if (newValue !== value) {
        this.ngControl.control.setValue(newValue);
        return false;
      }
    }
    return true;
  }

}
