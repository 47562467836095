import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { Environment, ENVIRONMENT } from '../environment';
import { AuthService } from '../rest';
import { ApiInterceptor } from './api.interceptor';

@Injectable()
export class AuthInterceptor extends ApiInterceptor implements HttpInterceptor {

    readonly API_URL: string;

    constructor(
        protected router: Router,
        protected authService: AuthService,
        @Inject(ENVIRONMENT) protected env: Environment
    ) {
        super();
        this.API_URL = env.obrador_url;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isUrlAPI(request.url)) {
            return this.authService.getLoginData()
                .pipe(
                    switchMap(data => {
                        if (data?.token) {
                            return next.handle(request.clone({
                                setHeaders: {
                                    Authorization: `Bearer ${data.token}`
                                }
                            }));
                        }
                        return next.handle(request);
                    })
                );
        }
        return next.handle(request);
    }

    // /**
    //  * Handles 401 error when tokens expire or are revoked.
    //  * Redirects to landing
    //  */
    // protected handle401Error(response: HttpErrorResponse): Observable<never> {
    //     if (!this.isRedirecting && response.error) {
    //         this.isRedirecting = true;
    //         const code = response.error.code;
    //         if (code === LoginErrorType.TOKEN_EXPIRED || code === LoginErrorType.TOKEN_REVOKED) {
    //             this.router.navigate(['landing'])
    //                 .then(() => {
    //                     this.isRedirecting = false;
    //                     // Logout, token is removed from local storage.
    //                     this.authService.signOut();
    //                 });
    //             return NEVER;
    //         }
    //     }
    //     return throwError(response);
    // }
}

export const AUTH_INTERCEPTOR = {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true};
