import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterViewInit,
    Renderer2,
    OnInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { Timeout } from '@obrador/common';


export interface EditableTextEvent {
    originalValue: string;
    currentValue: string;
    equal: boolean;
    component: EditableTextComponent
}

@Component({
    selector: 'app-editable-text',
    templateUrl: './editable-text.component.html',
    styleUrls: ['./editable-text.component.scss'],
})
export class EditableTextComponent implements AfterViewInit, OnInit, OnChanges {


    @ViewChild('textContainer', { static: false }) textContainer: ElementRef;
    @ViewChild('inputText', { static: false }) inputText: ElementRef;

    @Input() value: string;
    @Input() placeholder: string;
    @Input() type: 'number' | 'text' = 'text';

    maxWidth = '120px';

    @Output() edit = new EventEmitter<EditableTextEvent>();

    isEditing = false;
    originalValue: string;
    constructor(private renderer: Renderer2) {}

    ngOnInit(): void {
        this.originalValue = this.value;
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Cuando cambia el valor Value desde afuera.
        if (changes.value && !changes.value.firstChange) {
            this.originalValue = changes.value.currentValue;
        }
    }

    ngAfterViewInit() {
        this.setInputWidth();
    }

    setInputWidth(): void {
        if (this.textContainer) {
            this.maxWidth = `${this.textContainer.nativeElement.offsetWidth}px`;
        }
    }

    enableEditing(): void {
        this.isEditing = true;
        this.focusInput();
    }

    cancel(): void {
        this.value = this.originalValue;
        this.isEditing = false;
    }

    @Timeout(0)
    focusInput(): void {
        if (this.inputText) {
            this.inputText.nativeElement.focus();
        }
    }

    finishEditing() {
        this.isEditing = false;
        this.edit.emit({
            currentValue: this.value,
            originalValue: this.originalValue,
            equal: this.value === this.originalValue,
            component: this
        });
    }

    reset(): void {
        this.value = this.originalValue;
        this.isEditing = false;
    }

    onDoubleClick(): void {
        this.enableEditing();
    }
}
