import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ProgressContainerComponent } from './progress-container/progress-container.component';

@Injectable()
export class LoadingController {

  protected spinner: OverlayRef;

  protected attached: boolean;

  constructor(
    protected overlay: Overlay,
    protected loadingBar: LoadingBarService
  ) {
    this.attached = false;
    this.initializeOverlay();
  }

  protected initializeOverlay(): void {
    this.spinner = this.overlay.create({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true
    });
  }

  start(): void {
    if (!this.attached) {
      this.spinner.attach(new ComponentPortal(ProgressContainerComponent));
      this.attached = true;
    }
  }

  stop(): void {
    if (this.attached) {
      this.spinner.detach();
      this.attached = false;
    }
  }

  startGlobal(): void {
      this.loadingBar.useRef().start();
  }

  stopGlobal(): void {
      this.loadingBar.useRef().stop();
  }

}
