import { Component } from '@angular/core';
import { SideBarService } from '@obrador/shared';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
    constructor(protected sidebar: SideBarService) {

    }

    toggle(): void {
        this.sidebar.toggleSideNavRecursos();
    }
}
