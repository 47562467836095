<mat-list-item class='recurso-motorizado-item'>
    <mat-icon class='recurso-motorizado-icon'>
        {{ icon }}
    </mat-icon>
    <div class='recurso-motorizado-lines'>
        <span>
            {{ matricula }} - {{ tipo }}
        </span>
        <p class='text-line'>
            {{ modelo }}
        </p>
        <p class='text-line recurso-convocado' *ngIf='entity.convocatorias?.length > 0; else sinConvocar'>
            Convocado {{ entity.convocatorias[0].fecha_inicio | date: 'dd/MM/yyyy HH:mm' }}
        </p>
        <ng-template #sinConvocar>
            <p class='text-line'>
                <i>Sin Convocar</i>
            </p>
        </ng-template>
    </div>
</mat-list-item>
