import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-numeric-card',
    templateUrl: './numeric-card.component.html',
    styleUrls: ['./numeric-card.component.scss'],
})
export class NumericCardComponent {

    @Input() title: string;
    @Input() number: string | number;

}
