import { Component, Inject, Input, OnInit } from '@angular/core';

import { MediaPreviewOverlayRef } from './media-preview-overlay-ref';
import { FILE_PREVIEW_DIALOG_DATA } from './media-preview-overlay.tokens';

@Component({
    selector: 'app-media-viewer',
    templateUrl: './media-viewer.component.html',
    styleUrls: ['./media-viewer.component.scss']
})
export class MediaViewerComponent implements OnInit {

    isLoading: boolean;

    constructor(
        public dialogRef: MediaPreviewOverlayRef,
        @Inject(FILE_PREVIEW_DIALOG_DATA) public media: any) {
    }

    ngOnInit(): void {
        this.isLoading = true;
    }

    onImageLoad(): void {
        this.isLoading = false;
    }

    onImageError(): void {
        this.isLoading = false;
    }
}

