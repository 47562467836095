import { Injectable, Injector } from '@angular/core';
import { RemitoDigital, Reserva } from '@obrador/api-interfaces';
import { RestService } from '../rest.service';
import { Resource } from '../rest.resources';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RemitoDigitalService extends RestService<RemitoDigital> {

    constructor(protected injector: Injector) {
        super(injector, Resource.REMITOS_DIGITALES);
    }

    regenerar(ids: number[], reserva: Partial<Reserva>): Observable<unknown> {
        return this.http.put<unknown>(`${this.buildURL()}/regenerar/${reserva.id}`, {
            ids_viajes: ids
        });
    }

    generar(data: {
        viajeId: number,
        fecha: Date,
        numero: string,
        kilos: number
    }[]): Observable<void> {
        return this.http.post<void>(`${this.buildURL()}/generar-manual`, data);
    }

    getRemitosViajeApi(idViajeApi: number): Observable<RemitoDigital[]> {
        return this.http.get<RemitoDigital[]>(`${this.buildURL()}/viaje-api/${idViajeApi}`);
    }

    getRemitosReserva(idReserva: number): Observable<RemitoDigital[]> {
        return this.http.get<RemitoDigital[]>(`${this.buildURL()}/reserva/${idReserva}`);
    }

}
