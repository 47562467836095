<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div class='user-profile'
     style='background: url(assets/images/background/juango-bg.png) no-repeat; background-size: cover; background-position: bottom;'>
    <!-- User profile image -->
    <div class='profile-img'><img [src]='DEFAULT_IMAGE' alt='user'></div>
    <!-- User profile text-->
    <!-- ============================================================== -->
    <!-- Profile - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class='profile-text'><a [matMenuTriggerFor]='sdprofile' class=''>
        <ng-container *ngIf='user'>
            {{ user.nombre }} {{ user.apellido }}
        </ng-container>
        <i class='ti-angle-down font-12 m-l-5'></i></a></div>
    <mat-menu #sdprofile='matMenu' class='mymegamenu'>
        <button mat-menu-item>
            <mat-icon>settings</mat-icon>
            Settings
        </button>
        <button mat-menu-item>
            <mat-icon>account_box</mat-icon>
            Profile
        </button>
        <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            Disable notifications
        </button>
        <button mat-menu-item>
            <mat-icon>exit_to_app</mat-icon>
            Sign Out
        </button>
    </mat-menu>

</div>
<mat-nav-list appAccordion>
    <mat-list-item appAccordionLink *ngFor='let menuitem of menuItems.getMenuitem()' routerLinkActive='selected'>
        <!--        <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem()" routerLinkActive="selected" [group]="menuitem.state">-->

        <!--        <a class="" appAccordionToggle [routerLink]="menuitem.disabled ? null : [menuitem.state]" *ngIf="menuitem.type === 'link'">-->
        <!--            <mat-icon>{{ menuitem.icon }}</mat-icon>-->
        <!--            <span>{{ menuitem.name }}</span>-->
        <!--            <span fxFlex></span>-->
        <!--        </a>-->
        <a class='' appAccordionToggle [routerLink]='menuitem.disabled ? null : [menuitem.state]'
           *ngIf="menuitem.type === 'link'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
        </a>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
        <a class='' (click)='salir()'>
            <mat-icon>logout</mat-icon>
            <span>Salir</span>
            <span fxFlex></span>
        </a>
    </mat-list-item>

</mat-nav-list>
