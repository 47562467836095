import { Injectable, Injector } from '@angular/core';
import { Motivo } from '@obrador/api-interfaces';
import { catchError, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class MotivoService extends RestService<Motivo> {

    protected readonly URL_CAMBIO_RESERVA = 'cambio-reserva'
    protected readonly URL_ELIMINAR_VIAJE = 'eliminacion-viaje'

    constructor(protected injector: Injector) {
        super(injector, Resource.MOTIVOS);
    }

    getMotivosCambioReserva(): Observable<Motivo[]> {
        return this.http.get<Motivo[]>(`${this.buildURL()}/${this.URL_CAMBIO_RESERVA}`)
            .pipe(
                catchError(err => this.handleError(err)),
                map(response => this.mapArray(response)),
            );
    }

    getMotivosEliminacionViaje(): Observable<Motivo[]> {
        return this.http.get<Motivo[]>(`${this.buildURL()}/${this.URL_ELIMINAR_VIAJE}`)
            .pipe(
                catchError(err => this.handleError(err)),
                map(response => this.mapArray(response)),
            );
    }
}
