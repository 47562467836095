import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '../miscellaneous/not-found/not-found.component';
import { FullComponent } from './layouts/full/full.component';

const routes: Routes = [
    {
        path: '',
        component: FullComponent,
        children: [
            {
                path: '',
                redirectTo: 'convocatorias',
                pathMatch: 'full'
            },
            {
                path: 'convocatorias',
                loadChildren: () => import('../convocatoria/convocatoria.module').then(m => m.ConvocatoriaModule)
            },
            {
                path: 'viaje',
                loadChildren: () => import('../viaje/viaje.module').then(m => m.ViajeModule)
            },
            {
                path: 'reservas',
                loadChildren: () => import('../panel-reservas/panel-reservas.module').then(m => m.PanelReservasModule)
            },
            {
                path: 'resumen-reservas',
                loadChildren: () => import('../resumen-reservas/resumen-reservas.module').then(m => m.ResumenReservasModule)
            },
            {
              path: 'seguimiento',
              loadChildren: () => import('../seguimiento/seguimiento.module').then(m => m.SeguimientoModule)
            },
            {
                path: 'recursos',
                loadChildren: () => import('../recursos/recursos.module').then(m => m.RecursosModule)
            },
            {
                path: '**',
                component: NotFoundComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HomeRoutingModule {
}
