import { Injectable, Injector } from '@angular/core';
import { Remolque } from '@obrador/api-interfaces';
import { uniqBy } from 'lodash';
import { catchError, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RUT_JUANGO } from '../../constants';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';
import { PagedResult, QueryParams } from "../rest.model";

@Injectable({
    providedIn: 'root'
})
export class RemolqueService extends RestService<Remolque> {

    constructor(protected injector: Injector) {
        super(injector, Resource.REMOLQUES);
    }

    /**
     * Obtiene los remolques de la empresa, y los de juango.
     * @param rut
     */
    getRemolques(rut: string): Observable<Array<Remolque>> {
        return forkJoin([
            this.paginated({ limit: 2000, filters: [{ value: rut, field: 'rut' }] }),
            this.paginated({ limit: 2000, filters: [{ value: RUT_JUANGO, field: 'rut' }] })
        ]).pipe(
            map(([remolques, remolquesJuango]) => {
                return uniqBy(remolques.data.concat(remolquesJuango.data), 'id');
            })
        );
    }

    paginatedAll(queryParams: QueryParams): Observable<PagedResult<Remolque>> {
        return this.http.get<PagedResult<Remolque>>(`${this.buildURL()}/recursos`, { params: this.buildParams(queryParams) })
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapPaginated(data, queryParams))
            );
    }

}
