import { Pipe, PipeTransform } from '@angular/core';
import { differenceInMinutes } from 'date-fns';

@Pipe({
    name: 'dateDiff'
})
export class DateDiffPipe implements PipeTransform {

    transform(fechaPasada: Date | null, fechaFutura?: Date): string {
        if (fechaPasada === null) {
            return '00:00';
        }
        const fechaReferencia = fechaFutura ? fechaFutura : new Date();
        const minutos = differenceInMinutes(fechaReferencia, fechaPasada);
        if (minutos === 0) {
            return '00:00';
        }
        const horas = Math.floor(minutos / 60);
        const min = minutos % 60;
        return `${horas.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`;
    }
}
