import { UntypedFormControl } from '@angular/forms';
import {
    Conductor,
    Convocatoria,
    EstadoConvocatoria,
    EstadoSubConvocatoria,
    SubConvocatoria
} from '@obrador/api-interfaces';

export interface TreeNode<T> {
    data: T;
    expanded: boolean;
    children?: TreeNode<any>[]
}

export interface ConvocatoriaTableRow {
    id: number;
    data: Convocatoria
    expanded: boolean;
    matricula: string;
    remolque: string;
    empresa: string;
    inicio: Date | string;
    fin: Date | string;
    canEdit: boolean;
    canCancel: boolean;
    canPublish: boolean;
    canDelete: boolean;
    canSwitchFamily: boolean;
    canAddConductor: boolean;
    isEditing: boolean;
    isLoading: boolean;
    tieneViajes: boolean;
    tieneViajesActivos: boolean;
    children?: SubConvocatoriaTableRow[];
    form: {
        remolqueControl: UntypedFormControl;
        matriculaControl: UntypedFormControl;
        fechaInicioControl: UntypedFormControl;
        fechaFinControl: UntypedFormControl;
    },
    comentario_cancelacion: string | null;
}

export interface SubConvocatoriaTableRow {
    id: number;
    data: SubConvocatoria;
    conductor: Conductor;
    estado: EstadoSubConvocatoria,
    inicio: Date | string;
    fin: Date | string;
    lugar: string;
    expanded: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canCancel: boolean;
    viajesEnCursoCompleto: boolean;
    tieneViajes?: boolean;
    comentario_rechazo: string;
}
