import { BaseDTO } from './api-interfaces';
import { TipoMensajeProgramado } from './enums';
import { SubConvocatoria } from './sub-convocatoria';

export interface MensajeProgramado extends BaseDTO {
    tipo: TipoMensajeProgramado;
    titulo: string;
    mensaje: string;
    sub_convocatoria: SubConvocatoria;
    enviado?: boolean;
    fecha_enviado?: Date;
}

export interface MensajeProgramadoRequest {
    tipo: TipoMensajeProgramado;
    sub_convocatorias_ids: Array<number>;
    mensaje: string;
    titulo?: string;
}
