import { HttpClient, HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';
import { BaseDTO } from '@obrador/api-interfaces';
import * as _ from 'lodash';
import { catchError, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeepPartial } from 'typeorm';
import { ENVIRONMENT, Environment } from '../environment';
import { Logger } from '../logger';
import { IRestService } from './irest.service';
import { ApiError, APIParams, PagedResult, QueryParams } from './rest.model';
import { Resource } from './rest.resources';

export abstract class RestService<T extends BaseDTO> implements IRestService<T> {

    protected readonly baseURL;
    protected url: string;
    protected logger: Logger;
    protected http: HttpClient;

    protected constructor(protected injector: Injector, protected resource: Resource) {
        const env = this.injector.get<Environment>(ENVIRONMENT);
        this.http = this.injector.get<HttpClient>(HttpClient);
        this.logger = this.injector.get<Logger>(Logger);
        this.baseURL = env.obrador_url;
        this.url = this.buildURL();
    }

    all(): Observable<Array<T>> {
        return this.http.get<T[]>(this.buildURL())
            .pipe(
                catchError(err => this.handleError(err)),
                map(response => this.mapArray(response)),
            );
    }

    allWith(queryParams: QueryParams): Observable<Array<T>> {
        return this.http.get<T[]>(this.buildURL(), { params: this.buildParams(queryParams) })
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapArray(data))
            );
    }

    create(entity: Partial<T>): Observable<T> {
        return this.http.post<T>(this.buildURL(), entity)
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapEntity(data))
            );
    }

    delete(id: string | number): Observable<boolean> {
        return this.http.delete<boolean>(`${this.buildURL()}/${id}`)
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapDelete(data))
            );
    }

    findOne(id: string | number): Observable<T> {
        return this.http.get<T>(`${this.buildURL()}/${id}`)
            .pipe(
                map(data => this.mapEntity(data))
            );
    }

    paginated(queryParams: QueryParams): Observable<PagedResult<T>> {
        return this.http.get<PagedResult<T>>(this.buildURL(), { params: this.buildParams(queryParams) })
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapPaginated(data, queryParams))
            );
    }

    update<E>(entity: E): Observable<T>;
    update(entity: T): Observable<T> {
        const id = entity.id;
        if (!id) {
            throw new Error(`RestService: Se necesita el ID de la entidad ${entity}`)
        }
        delete entity.id;
        return this.http.put<T>(`${this.buildURL()}/${id}`, entity)
            .pipe(
                map(data => this.mapEntity(data))
            );
    }

    patch(entity: DeepPartial<T>): Observable<T> {
        return this.http.patch<T>(`${this.buildURL()}/${entity.id}`, entity)
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapEntity(data))
            );
    }

    mapDelete(value: boolean): boolean {
        return value;
    }

    mapEntity(value: T): T {
        return value;
    }

    mapArray(value: Array<T>): Array<T> {
        return value.map(v => this.mapEntity(v));
    }

    handleError(error: any): Observable<never> {
        if (typeof error === 'string') {
            return throwError(() => new ApiError(new Error(error), error));
        }
        return throwError(() => new ApiError(error, error.message));
    }

    mapPaginated(response: any, queryParams: QueryParams): PagedResult<T> {
        return {
            data: this.mapArray(response.data),
            links: response.links,
            meta: response.meta,
            params: { ...queryParams }
        };
    }

    protected buildURL(): string {
        return `${this.baseURL}/${this.resource}`;
    }

    protected buildURLWithResource(resource: Resource): string {
        return `${this.baseURL}/${resource}`;
    }

    protected buildParams(queryParams: QueryParams): HttpParams {
        // TODO: see how to build filter params. search & search_by do not support arrays.
        let options = new HttpParams();
        const search = queryParams.search;
        if (search) {
            options = options.set(APIParams.SEARCH, search);
        }
        const searchBy = queryParams.searchBy;
        if (searchBy) {
            options = options.set(APIParams.SEARCH_BY, searchBy);
        }
        const orderBy = queryParams.orderBy;
        if (orderBy) {
            options = options.set(APIParams.ORDER_TYPE,
                orderBy.order ? orderBy.order.toUpperCase() : 'ASC');
            options = options.set(APIParams.ORDER_BY, orderBy.field);
        }
        const limit = queryParams.limit;
        if (limit) {
            options = options.set(APIParams.LIMIT, String(limit));
        }
        const offset = queryParams.offset;
        if (!isNaN(offset)) {
            options = options.set(APIParams.OFFSET, String(offset));
        }
        const except = queryParams.except;
        if (except) {
            options = options.set(APIParams.EXCEPT, String(except));
        }
        const page = queryParams.page;
        if (page >= 0) {
            options = options.set(APIParams.PAGE, String(page));
        }
        /* Relations param */
        const relations = queryParams.relations;
        if (_.isArray(relations) && !_.isEmpty(relations)) {
            for (const relation of relations) {
                options = options.append(`relations[]`, relation);
            }
        }
        // Filters params
        const filters = queryParams.filters;
        if (_.isArray(filters) && !_.isEmpty(filters)) {
            for (const filter of filters) {
                if (_.isArray(filter.value)) {
                    for (const filterValue of filter.value) {
                        options = options.append(`${filter.field}[]`, filterValue);
                    }
                } else {
                    options = options.set(filter.field, filter.value);
                }
            }
        }
        return options;
    }
}
