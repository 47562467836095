import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-empty-list-warn',
    templateUrl: './empty-list-warn.component.html',
    styleUrls: ['./empty-list-warn.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyListWarnComponent {
    @Input() message = 'No hay elementos'
}
