<ng-container *ngIf="error">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'none'">
      {{ error }}
    </ng-container>
    <mat-error *ngSwitchCase="'mat'">
      {{ error }}
    </mat-error>
    <div *ngSwitchCase="'small'" class="error-message">
      <small class='error-message'>
        {{ error }}
      </small>
    </div>
  </ng-container>
</ng-container>
