import { Injectable, Injector } from '@angular/core';
import { FamiliaServicio } from '@obrador/api-interfaces';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class FamiliaServicioService extends RestService<FamiliaServicio> {
    constructor(protected injector: Injector) {
        super(injector, Resource.FAMILIAS_SERVICIO);
    }
}
