import { Reserva } from '@obrador/api-interfaces';

export abstract class ReservaUtils {
    protected static readonly prefix_contenedores = 'transporte ctn'
    protected static readonly familias_contenedores = [
        // Flete contenedores, zona metropolitana.
        2,
        // Flete contenedores, larga distancia hacia MVD.
        4,
        // Flete contenedores, larga distancia desde MVD.
        7
    ];

    static isReservaModalidadcontenedor(r: Reserva): boolean {
        if (r.familia_servicio) {
            return ReservaUtils.isFamiliaContenedor(r.familia_servicio.id);
        }
        // Fallback cdo no hay flia.
        return r.modalidad ? r.modalidad.toLowerCase().includes(ReservaUtils.prefix_contenedores) : false;
    }

    static isFamiliaContenedor(id: number): boolean {
        return ReservaUtils.familias_contenedores.includes(id);
    }

}
