import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Reserva } from '@obrador/api-interfaces';

@Component({
    selector: 'app-reserva-card',
    templateUrl: './reserva-card.component.html',
    styleUrls: ['./reserva-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReservaCardComponent  {

    @Input() reserva: Reserva;

}
