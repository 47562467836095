<h1 mat-dialog-title>{{ config.title }}</h1>
<div mat-dialog-content>
    <ng-container *ngIf='!safeHTML; else withHTML'>
        <p>{{ config.message }}</p>
    </ng-container>
    <ng-template #withHTML>
        <div [innerHTML]='safeHTML'></div>
    </ng-template>
</div>
<div mat-dialog-actions class='justify-content-end'>
  <button mat-button (click)="cancel()">{{config.cancelText || 'No'}}</button>
  <button color='primary' mat-button (click)="confirm()" cdkFocusInitial> {{ config.okText || 'Sí'}}</button>
</div>
