import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toneladas'
})
export class ToneladasPipe implements PipeTransform {

    constructor(protected decimalPipe: DecimalPipe) {

    }

    transform(value: number, showTn: boolean = true, fromUnit: 'g' | 'kg' = 'kg', format = '1.2-3'): unknown {
        if (isNaN(value)) {
            return '';
        }
        const unit = fromUnit === 'g' ? 1e+6 : 1e3;
        const parsedValue = value / unit;
        if (!showTn) {
            return `${this.decimalPipe.transform(parsedValue, format)}`
        }
        return `${this.decimalPipe.transform(parsedValue, format)} tn`
    }

}
