import { Pipe, PipeTransform } from '@angular/core';
import { TipoControl } from '@obrador/api-interfaces';

@Pipe({
    name: 'controlDesvioGrupo',
})
export class ControlDesvioGrupoPipe implements PipeTransform {
    transform(value: TipoControl | string, nombreCampo: string): string {
        switch (value) {
            case TipoControl.AGRUPARYCONTAR:
                return `Agrupar ${nombreCampo}`;
            case TipoControl.CONTARTOTALES:
                return `Contar ${nombreCampo}`;
            case TipoControl.SUMAR:
                return `Sumar ${nombreCampo}`
        }
        return '';
    }
}
