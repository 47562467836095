import { BaseDTO, Empresa, Motorizado, Remolque } from './api-interfaces';
import { Conductor } from './conductor';
import { Reserva } from './reserva';
import { ViajeApi } from './viaje';

export interface RemitoDigital extends BaseDTO {
    fecha: Date;
    numero: string;
    lugar?: string;
    kilos_netos: number;
    kms: number;
    url: string;
    horas: number;
    deleted_at?: Date;
    evento_app?: any;
    motorizado?: Partial<Motorizado>;
    remolque?: Partial<Remolque>;
    empresa?: Partial<Empresa>;
    conductor?: Partial<Conductor>;
    reserva?: Partial<Reserva>
    viaje_api?: ViajeApi;
    manual?: boolean;
}
