import { BaseDTO } from "./api-interfaces";
import { Viaje } from "./viaje";
import {Alarma, AlarmaNovedad} from "./alarma";
import { TipoMotivoNovedad } from "./enums";
import { AdminDTO } from "./admin";


export interface Novedad extends BaseDTO {
    fecha: Date;
    comentario?: string;
    motivo?: TipoMotivoNovedad;
    motivo_select?: string;
    viajeId?: number;
    viaje?: Viaje;
    alarma?: Alarma;
    alarmas?: AlarmaNovedad[];
    deleted_at?: Date;
    admin?: AdminDTO;
}

// export interface AlarmaNovedad extends BaseDTO {
//     tiempo_postpuesta?: number;
//     fecha_fin?: Date;
//     novedadId?: number;
//     alarmaId?: number;
//     alarma?: Partial<Alarma>;
//     novedad?: Partial<Novedad>;
// }

export interface NovedadBulkCreateDTO {
    motivo?: TipoMotivoNovedad;
    comentario?: string;
    fecha_fin?: Date;
    tiempo_posponer?: number;
    viajesIds?: number[];
    posponer_alarmas?: boolean;
    apagar_alarmas?: boolean;
}

export interface NovedadBulkRemoveDTO {
    ids: number[];
}
