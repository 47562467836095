import { Pipe, PipeTransform } from '@angular/core';
import { Reserva } from '@obrador/api-interfaces';

export type OUTPUTS = 'id_cliente_booking' | 'id_producto_booking'

@Pipe({
  name: 'reserva'
})
export class ReservaPipe implements PipeTransform {

  transform(reserva: Reserva, output: OUTPUTS = 'id_cliente_booking'): string {
    if (!reserva) {
        return '';
    } else {
        switch (output) {
            case 'id_cliente_booking':
                return `R${reserva.id_migrador} - ${reserva.empresa} - ${reserva.booking || 'Sin booking'}`;
            case 'id_producto_booking':
                return `R${reserva.id_migrador} - ${reserva.nombre_producto} - ${reserva.booking || 'Sin booking'}`;
        }
        return `R${reserva.id_migrador}`;
    }
  }

}
