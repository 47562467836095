export function Timeout(ms: number): any {
    return (target: any, methodName: string, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value;
        descriptor.value = function(...args: any[]) {
            setTimeout(() => {
                originalMethod.apply(this, args);
            }, ms);
        };
        return descriptor;
    };
}

export function TimeoutAsync(ms: number): any {
  return (target: any, methodName: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
      descriptor.value = function(...args: any[]) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            const result = await originalMethod.apply(self, args);
            return resolve(result);
          } catch (e) {
            return reject(e);
          }
        }, ms);
      })

    };
    return descriptor;
  };
}
