import { BaseDTO } from './api-interfaces';
import { ConfirmacionViaje, EstadoEventoViajeCancerbero } from './enums';

export interface EventoViajeCancerbero extends BaseDTO {
    id_migrador: number;
    viaje_id: number;
    confirmacion: ConfirmacionViaje;
    tipo: EstadoEventoViajeCancerbero;
    fecha?: Date;
    picadora: string;
    indice_validez?: number;
    sensores: SensorEventoViajeCb[];
}

export interface SensorEventoViajeCb {
    fecha: string;
    agente: string;
    sensor: string;
}
