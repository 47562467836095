<mat-form-field [appearance]='appearance'>
    <mat-label>
        <ng-content select="[label-content]"></ng-content>
    </mat-label>
    <input matInput
           #input
           type="search"
           autocomplete="off"
           (keyup)="keyUp$.next(input.value)"
           (paste)="inputChangedEvent(input.value)"
           [placeholder]="placeholder"
           [matAutocomplete]="inputAutocomplete"
    >
    <mat-icon matPrefix *ngIf="iconPosition === 'left'">
        search
    </mat-icon>
    <mat-icon matSuffix *ngIf="iconPosition === 'right'">
        search
    </mat-icon>
    <mat-autocomplete [displayWith]="displayWithFn"
                      #inputAutocomplete="matAutocomplete"
                      (optionSelected)="onOptionSelected($event)"
                      style="overflow: hidden;"
    >
        <ng-container *ngIf='empresas?.length > 0'>
            <mat-option *ngFor="let option of empresas; trackById" [value]="option">
                {{option.nombre}}
            </mat-option>
        </ng-container>
    </mat-autocomplete>
    <mat-error>
        <ng-content error-content></ng-content>
    </mat-error>
</mat-form-field>


