import { Injectable } from '@angular/core';
import { EstadoViaje, ResumenReserva, ValidacionReglaResult } from '@obrador/api-interfaces';
import { parseISO } from 'date-fns';
import { ResumenReservaTableRow } from '../models/resumen-reserva.models';

@Injectable()
export class ResumenReservaHelper {

    protected readonly TOOLTIP_PASSED = 'Cumple con las reglas definidas';
    protected readonly TOOLTIP_FAILED = 'No cumple con las reglas definidas';

    mapTableRow(row: ResumenReserva): ResumenReservaTableRow {
        row.data.fecha_inicio = parseISO(row.data.fecha_inicio as string);
        row.data.fecha_fin = parseISO(row.data.fecha_fin as string);
        let resultValidaciones = null;
        if (row.viaje_registro) {
            const resultado_validacion = row.viaje_registro.resultado_validaciones;
            resultValidaciones = resultado_validacion ? ValidacionReglaResult.PASSED : ValidacionReglaResult.FAILED;
        }
        let tieneRemitosManuales = false;
        if (row.remitos_digitales?.length > 0) {
            tieneRemitosManuales = row.remitos_digitales.some(r => r.manual);
        }
        // Construyendo el objeto ResumenReservaTableRow con todas las propiedades
        return {
            ... row,
            // always true.
            canSelect: true,
            puedeCambiarReserva: row.data.estado !== EstadoViaje.CANCELADO,
            tieneValidaciones: resultValidaciones !== null,
            validaciones: resultValidaciones,
            toolTipValidaciones: this.getTooltipResultadoValidacion(resultValidaciones),
            remitos_digitales: row.remitos_digitales || [],
            tieneRemitosManuales
        } as any;
    }

    getTooltipResultadoValidacion(val: ValidacionReglaResult): string {
        switch (val) {
            case ValidacionReglaResult.ERROR:
            case ValidacionReglaResult.FAILED:
                return this.TOOLTIP_FAILED;
            case ValidacionReglaResult.PASSED:
                return this.TOOLTIP_PASSED
        }
        return null;
    }
}
