import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RecursoConductor } from '@obrador/api-interfaces';

@Component({
    selector: 'app-recurso-motorizado-conductor',
    templateUrl: './recurso-motorizado-conductor.component.html',
    styleUrls: ['./recurso-motorizado-conductor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecursoMotorizadoConductorComponent {

    readonly DEFAULT_IMAGE = 'assets/images/user.png';

    @Input() conductor: RecursoConductor;

}
