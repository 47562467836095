import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { DataTableColumn } from './data-table.component';

@Pipe({
    name: 'dataTableCell'
})
export class DataTableCellPipe implements PipeTransform {

    transform<T>(row: any, column: DataTableColumn<T>): SafeHtml | string {
        if (column.render) {
            return column.render(row);
        }
        return '';
    }

}
@Pipe({
    name: 'dataTableClass'
})
export class DataTableClass implements  PipeTransform {

    transform<T>(row: any, column: DataTableColumn<T>): string | string[] | undefined {
        if (column.renderClass) {
            return column.renderClass(row);
        }
        return column.cellClass;
    }

}
