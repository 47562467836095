export enum TipoEventoConvocatoria {
    ASIGNACION_CONDUCTOR = 'ASIGNACION_CONDUCTOR',
    ASIGNACION_MOTORIZADO = 'ASIGNACION_MOTORIZADO',
    ASIGNACION_REMOLQUE = 'ASIGNACION_REMOLQUE',
    ASIGNACION_EMPRESA = 'ASIGNACION_EMPRESA',
    CREACION_CONVOCATORIA = 'CREACION_CONVOCATORIA',
    EDICION_CONVOCATORIA = 'EDICION_CONVOCATORIA',
    EDICION_CONVOCATORIA_FAMILIA = 'EDICION_CONVOCATORIA_FAMILIA',
    EDICION_SUB_CONVOCATORIA = 'EDICION_SUB_CONVOCATORIA',
    PUBLICACION_CONVOCATORIA = 'PUBLICACION_CONVOCATORIA',
    ACEPTA_CONVOCATORIA_EMPRESA = 'ACEPTA_CONVOCATORIA_EMPRESA',
    ACEPTA_CONVOCATORIA_CHOFER = 'ACEPTA_CONVOCATORIA_CHOFER',
    RECHAZA_CONVOCATORIA_EMPRESA = 'RECHAZA_CONVOCATORIA_EMPRESA',
    RECHAZA_CONVOCATORIA_CHOFER = 'RECHAZA_CONVOCATORIA_CHOFER',
    CANCELA_CONVOCATORIA = 'CANCELA_CONVOCATORIA',
    CANCELA_SUB_CONVOCATORIA = 'CANCELA_SUB_CONVOCATORIA',
    ELIMINA_CONVOCATORIA = 'ELIMINA_CONVOCATORIA',
    ELIMINA_SUB_CONVOCATORIA = 'ELIMINA_SUB_CONVOCATORIA',
    REPLICAR_ASIGNACION_CONDUCTOR = 'REPLICAR_ASIGNACION_CONDUCTOR',

}
export enum TipoEventoViaje {
    ELIMINA_VIAJE_APP = 'ELIMINA_VIAJE_APP',
    RECHAZAR_VIAJE = 'RECHAZAR_VIAJE',
    ELIMINA_VIAJE = 'ELIMINA_VIAJE',
    CREA_VIAJE = 'CREA_VIAJE',
    CAMBIO_ORDEN_VIAJE = 'CAMBIO_ORDEN_VIAJE',
    CAMBIO_RESERVA_VIAJE = 'CAMBIO_RESERVA_VIAJE',
    CAMBIO_HORARIO_VIAJE = 'CAMBIO_HORARIO_VIAJE',
    VALIDACION_VIAJE = 'VALIDACION_VIAJE',
    AUTO_VALIDACION_VIAJE = 'AUTO_VALIDACION_VIAJE',
    AUTO_GENERA_VIAJE = 'AUTO_GENERA_VIAJE'
}

export enum TipoEventoReserva {
    CREA_PLAN_VIAJE = 'CREA_PLAN_VIAJE',
    EDITA_PLAN_VIAJE = 'EDITA_PLAN_VIAJE',
    CIERRE_CANTIDADES = 'CIERRE_CANTIDADES',
    CANCELAR_RESERVA = 'CANCELAR_RESERVA'
}

export enum TipoEstadoReserva {
    OPERATIVA = 'OPERATIVA',
    CANCELADA = 'CANCELADA'
}

export enum TipoConductor {
    PCH = 'PCH',
    CHOFER = 'CHOFER',
    OTRO = 'OTRO'
}

export enum EstadoSubConvocatoria {
    PENDIENTE = 'PENDIENTE',
    ACEPTADA = 'ACEPTADA',
    RECHAZADA = 'RECHAZADA',
    CANCELADA = 'CANCELADA'
}

export enum EstadoConvocatoria {
    PREPARADA = 'PREPARADA',
    PUBLICADA = 'PUBLICADA',
    ACEPTADA = 'ACEPTADA',
    CONFIRMADA = 'CONFIRMADA',
    RECHAZADA = 'RECHAZADA',
    CANCELADA = 'CANCELADA',
    ELIMINADA = 'ELIMINADA',
    FINALIZADA = 'FINALIZADA'
}

export enum EstadoViaje {
    PENDIENTE = 'PENDIENTE',
    EN_CURSO = 'EN_CURSO',
    COMPLETO = 'COMPLETO',
    CANCELADO = 'CANCELADO',
}

export enum TipoHabilitacion {
    DGI = 0,
    BPS = 1,
    MTOP = 2,
    SUCTA = 3,
    SOA = 4,
    SEGURO = 5,
    POLIZA = 6,
    LIBRETA = 7,
    CARGA = 8,
    OTRO = 9,
    PNC = 10,
    RC = 11,
    PT = 12
}

export enum SistemaOrigen {
    APP_PCH = 'APP_PCH',
    APP_CHOFERES = 'APP_CHOFERES',
    MIGRADOR = 'MIGRADOR',
    MIGRADOR_ALQUILADORA = 'MIGRADOR_ALQUILADORA',
    GAUDI2 = 'GAUDI2',
    OBRADOR = 'OBRADOR'
}

export enum TipoMensajeProgramado {
    FINALIZA_VIAJES = 1,
    REMITO_INCORRECTO = 2
}

export enum PushNotificationType {
    PUSH_NOTIFICATION = 10,
    PUSH_NOTIFICATION_REGISTRO = 12
}

export enum TipoRegla {
    CANTIDADES_REQUERIDAS = 'CANTIDADES_REQUERIDAS',
    REMITOS = 'REMITOS',
    REMITOS_CON_PUSH = 'REMITOS_CON_PUSH',
    CONTROL_PESADA = "CONTROL_PESADA",
    REMITO_CON_LUGAR = 'REMITO_CON_LUGAR',
    CONTENEDOR = 'CONTENEDOR',
    CONTENEDOR_CON_PUSH = 'CONTENEDOR_CON_PUSH'
}

export enum ValidacionReglaResult {
    FAILED = 0,
    PASSED = 1,
    ERROR = 3
}

export enum ConfirmacionViaje {
    SIN_CONFIRMAR = 0,
    CONFIRMACION_PARCIAL = 1,
    CONFIRMACION_TOTAL = 2
}

export enum EstadoEventoViajeCancerbero {
    RUMBO_ORIGEN = 1,
    EN_ORIGEN = 2,
    CARGADO = 3,
    EN_DESTINO = 5,
    FINALIZADO = 7,
}

// Ids de la base.
export enum TipoEventoNotificacionId {
    REMITOS_CON_PUSH = 1,
    CONTENEDOR_CON_PUSH = 2
}

export enum TipoEventoValidacion {
    GENERAL = 1,
    ESPECIFICO = 2
}

export enum TipoCierreReserva {
    REMITO_ORIGEN = 'Remito_Origen',
    REMITO_DESTINO = 'Remito_Destino',
    HORAS = 'Horas',
    CONTENEDOR = 'Contenedor'
}

export enum TipoEventoAlarma {
    CREA_ALARMA = 'CREA_ALARMA',
    REACTIVA_ALARMA = 'REACTIVA_ALARMA',
    APAGA_ALARMA = 'APAGAR_ALARMA',
    POSPONE_ALARMA = 'POSPONE_ALARMA',
    APAGA_ALARMA_AUTO = 'APAGA_ALARMA_AUTO'
}

export enum TipoEventoNovedad {
    CREA_NOVEDAD = 'CREA_NOVEDAD',
    ELIMINA_NOVEDAD = 'ELIMINA_NOVEDAD'
}

export enum TipoMotivoNovedad {
    CARGA_DEMORADA = 'CARGA_DEMORADA',
    DESCARGA_DEMORADA = 'DESCARGA_DEMORADA',
    PROBLEMA_RUTA = 'PROBLEMA_RUTA',
    PROBLEMA_CLIMATICO = 'PROBLEMA_CLIMATICO',
    PROBLEMA_USUARIO = 'PROBLEMA_USUARIO',
    PROBLEMA_TIEMPOS_SUP = 'PROBLEMA_TIEMPOS_SUP',
    VARIOS_COORDINACION = 'VARIOS_COORDINACION',
    CAMBIO_INSTANCIA = 'CAMBIO_INSTANCIA',
    DOCUMENTACION = 'DOCUMENTACION'
}

export enum TipoEstadoAlarma {
    ACTIVA = 'ACTIVA',
    POSPUESTA = 'POSPUESTA',
    APAGADA = 'APAGADA'
}

export enum TipoAlarmaControl {
    VIAJE_TIEMPO_ACUM = 'viaje_tiempo_acumulado',
    VIAJE_TIEMPO_INTERACCION = 'viaje_tiempo_interaccion'
}

export enum EstadoEmpresa {
    HABILITADA = 'HABILITADA',
    DESHABILITADA = 'DESHABILITADA'
}

export const mapEstadoEmpresa = [
    {
        label: 'Habilitada',
        value: EstadoEmpresa.HABILITADA
    },
    {
        label: 'Deshabilitada',
        value: EstadoEmpresa.DESHABILITADA
    }
]

export const prioridadesEmpresa = [1, 2, 3, 4, 5];

export enum TipoFiltroDocumentos {
    CON_VENCIDOS = 'CON_VENCIDOS',
    SIN_VENCIDOS = 'SIN_VENCIDOS'
}

export const mapTipoFiltro = [
    {
        label: 'Vencidos / Faltantes',
        value: TipoFiltroDocumentos.CON_VENCIDOS
    },
    {
        label: 'Sin Vencidos',
        value: TipoFiltroDocumentos.SIN_VENCIDOS
    }
];

export enum TipoDocumentos {
    CONDUCTOR = 'CONDUCTOR',
    HABILITACIONES = 'HABILITACIONES'
}

export enum TipoEventoEmpresa {
    UPDATE_PRIORIDAD_ESTADO = 'UPDATE_PRIORIDAD_ESTADO'
}

export enum TipoEntidadRequerimiento {
    EMPRESA = 'EMPRESA',
    CONDUCTOR = 'CONDUCTOR',
    MOTORIZADO = 'MOTORIZADO',
    REMOLQUE = 'REMOLQUE',
}

export enum TipoRequerimiento {
    CARACTERISTICA = 'CARACTERISTICA',
    CONTROL = 'CONTROL'
}

export enum TipoCodigoRequerimiento {
    COMPROMISO_APP = 'COMPROMISO_APP',
    MODELO_DOC_OK = 'MODELO_DOC_OK'
}

export enum TipoEventoHabilitacion {
    VALIDAR_MODELO_DOCUMENTACION = 'VALIDAR_MODELO_DOCUMENTACION'
}

export enum TipoAlarmaRecurso {
    EMPRESA = 'Empresa',
    MATRICULA = 'Matricula',
    CONDUCTOR = 'Conductor'
}

export enum AutoGenerarViajeResult {
    OK = 'OK',
    ERROR = 'ERROR'
}
export enum FuenteDatos {
    GAUDI = 'GAUDI-API',
    CRONER = 'CRONER',
    MIGRADOR = 'MIGRADOR-PCH',
    BALANZA = 'BALANZA-JUANGO'
}

export enum OPERADOR {
    IGUAL = '=',
    MAYOR_IGUAL = '>=',
    MENOR_IGUAL = '<=',
    MENOR = '<',
    MAYOR = '>',
    DISTINTO = '!='
}

export enum TipoControl {
    AGRUPARYCONTAR = "AGRUPARYCONTAR",
    CONTARTOTALES = "CONTARTOTALES",
    SUMAR = "SUMAR",
}

export enum ControlDesvioError {
    FALLO_GAUDI = 'FALLO_GAUDI',
    FALLO_GAUDI2 = 'FALLU_GAUDI2',
    FALLO_CRONER = 'FALLO_CRONER',
    FALLO_MIGRADOR = 'FALLO_MIGRADOR',
    FALLO_BALANZA = 'FALLO_BALANZA',
    FALLO_INTERNO = 'FALLO_INTERNO',
    FALLO_GAUDI_SIN_REMITOS = 'FALLO_GAUDI_SIN_REMITOS'
}

export enum TipoMotivo {
    CAMBIO_RESERVA = 'CAMBIO_RESERVA',
    ELIMINAR_VIAJE = 'ELIMINAR_VIAJE',
}
