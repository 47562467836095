<mat-card appearance="outlined">
    <mat-card-content class='plan-diario-content'>
        <span class='plan-diario-reserva'> R{{ reserva.id_migrador }} </span>
        <small class='plan-diario-empresa'>{{ reserva.empresa }}</small>
        <div class='plan-diario-info'>
            <mat-icon>sync_alt</mat-icon>
            <span> {{ planViaje.total_viajes }} </span>
            <mat-icon>local_shipping</mat-icon>
            <span>{{ planViaje.cantidad_camiones }}</span>
        </div>
    </mat-card-content>
</mat-card>
