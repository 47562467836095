import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Convocatoria, ConvocatoriaSave, EstadoConvocatoria, GroupedConvocatoriaData } from '@obrador/api-interfaces';
import { ArrayUtils } from '@obrador/common';
import { catchError, forkJoin, Observable } from 'rxjs';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class ConvocatoriaService extends RestService<Convocatoria> {

    constructor(protected injector: Injector) {
        super(injector, Resource.CONVOCATORIAS);
    }

    bulk(elements: ConvocatoriaSave[]): Observable<void> {
        return this.http.post<void>(`${this.buildURL()}/bulk`, elements);
    }

    allGrouped(from: Date, to: Date, estados?: Array<EstadoConvocatoria>, cuentaId?: number): Observable<Array<GroupedConvocatoriaData>> {
        let params = new HttpParams()
            .append('from', from.toISOString())
            .append('to', to.toISOString());
        if (ArrayUtils.isNotEmpty(estados)) {
            for (const estado of estados) {
                params = params.append('estados[]', estado);
            }

        }
        if (cuentaId) {
            params = params.append('cuentaId', cuentaId);
        }
        return this.http.get<GroupedConvocatoriaData[]>(this.buildURL(), {
            params
        })
        .pipe(
            catchError(err => this.handleError(err))
        );
    }

    bulkDelete(ids: Array<number>): Observable<void> {
        return this.http.delete<void>(`${this.buildURL()}/bulk`, { body: { convocatorias: ids } });
    }

    cambiarEstadoBulk(arr: number[], estado: EstadoConvocatoria) {
        const ops = [];
        for (const id of arr) {
            ops.push(this.update({
                id,
                estado
            }));
        }
        return forkJoin(ops);
    }
}
