import equal from 'fast-deep-equal/es6';
import * as _ from 'lodash';
import { ArrayUtils } from './array';

export const deepEquals = (v1: any, v2: any): boolean => {
    return equal(v1, v2);
}

/**
 * Compara 2 arrays profundamente
 * A considerar
 * - Se clonan los arrays
 * - Hay chequeos de null
 * @param array1
 * @param array2
 * @param excludeNulls - excluye propiedades en null
 * @param omit - nombres de las propiedades a omitir cuando se compara
 */
export const _deepEquals = (array1: any[], array2: any[], excludeNulls: boolean, omit?: string[]): boolean => {
    const v1 = _.cloneDeep(array1);
    const v2 = _.cloneDeep(array2);
    let a;
    let b;
    if (!v1 && v2) {
        return false;
    }
    if (v2 && !v1) {
        return false;
    }
    if (!v2 && !v1) {
        return false;
    }
    if (omit) {
        if (excludeNulls) {
            a = v1.map(el => _.chain(el).omitBy(v => _.isUndefined(v) || _.isNull(v)).omit(omit).value());
            b = v2.map(el => _.chain(el).omitBy(v => _.isUndefined(v) || _.isNull(v)).omit(omit).value());
        } else {
            a = v1.map(el => _.omit(el, omit));
            b = v2.map(el => _.omit(el, omit));
        }
    } else {
        if (excludeNulls) {
            a = v1.map(el => _.omitBy(el, v => _.isUndefined(v) || _.isNull(v)));
            b = v2.map(el => _.omitBy(el, v => _.isUndefined(v) || _.isNull(v)));
        } else {
            a = v1;
            b = v2;
        }
    }
    return ArrayUtils.isDeepEqual(a, b)
}

export function mergeSum<T>(o1: T, o2: T): T {
    const result = _.cloneDeep(o1);
    for (const k in o2) {
        if (hasOwnProperty(result, k)) {
            const value = result[k];
            const addValue = o2[k];
            if (_.isNumber(value) && _.isNumber(addValue)) {
                result[k] = (value + addValue) as any;
            }
        }
    }
    return result;
}

export function hasOwnProperty(o: any, k: string): boolean {
    return Object.prototype.hasOwnProperty.call(o, k);
}
