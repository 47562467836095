import {EstadoEventoViajeCancerbero} from "@obrador/api-interfaces";

export class EventoCancerberoUtils {

    static mapTipoInstanciaToNombre(tipo: EstadoEventoViajeCancerbero): string {
        switch (tipo) {
            case EstadoEventoViajeCancerbero.CARGADO:
                return 'Cargado'
            case EstadoEventoViajeCancerbero.EN_DESTINO:
                return 'En destino'
            case EstadoEventoViajeCancerbero.EN_ORIGEN:
                return 'En origen'
            case EstadoEventoViajeCancerbero.RUMBO_ORIGEN:
                return 'Rumbo a origen'
            case EstadoEventoViajeCancerbero.FINALIZADO:
                return 'Finalizado'
        }
    }

}
