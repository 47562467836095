import {EventoValidacion} from "@obrador/api-interfaces";
import {Injectable, Injector} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../rest.service";
import {Resource} from "../rest.resources";

@Injectable({
    providedIn: 'root'
})
export class EventoValidacionService extends RestService<EventoValidacion>{

    constructor(protected injector: Injector) {
        super(injector, Resource.EVENTOS_VALIDACION)
    }

    getHistoricoByViajeApiId(id: number): Observable<EventoValidacion[]> {
        return this.http.get<EventoValidacion[]>(`${this.buildURL()}/viajes/${id}`);
    }

}
