import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Empresa } from '@obrador/api-interfaces';
import { EmpresaService, Logger, QueryParams } from '@obrador/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { FormAutocompleteComponent, InputTextEvent } from '../form-autocomplete/form-autocomplete.component';

/**
 * @deprecated
 * Endpoint empresas pasa a ser paginado..
 */
@Component({
    selector: 'app-autocomplete-empresa',
    templateUrl: './autocomplete-empresa.component.html',
    styleUrls: ['./autocomplete-empresa.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: AutocompleteEmpresaComponent, multi: true }
    ]
})
export class AutocompleteEmpresaComponent extends FormAutocompleteComponent implements OnInit, OnDestroy, ControlValueAccessor {

    private destroy$: Subject<void> = new Subject<void>();

    value: Empresa;
    isDisabled: boolean;
    empresas: Array<Empresa>;

    onChange: () => void;
    onTouch: () => void;

    @Input() iconPosition: 'left' | 'right' = 'right';
    @Input() appearance: MatFormFieldAppearance = 'outline';

    constructor(protected service: EmpresaService, logger: Logger) {
        super(logger);
    }

    ngOnInit(): void {
        this.empresas = [];
        this.initSubs();
    }

    initSubs(): void {
        this.keyUp$ = new BehaviorSubject('');
        this.keyUp$.pipe(
            // Skip first para evitar la primera emision vacia del behaviour subject.
            takeUntil(this.destroy$),
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(val => {
            this.inputChangedEvent(val);
        });
        this.inputChanged$ = new BehaviorSubject<InputTextEvent>({ value: '', emitLookup: false });
        this.inputChanged$
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(event => {
                if (event.emitLookup) {
                    const searchText = event.value;
                    this.onSearch(searchText);
                }
            });
    }

    onSearch(text: string): void {
        this.getEmpresas(text);
    }

    ngOnDestroy(): void {
        this.inputChanged$.complete();
        this.keyUp$.complete();
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(obj: any): void {
        super.writeValue(obj);
    }

    getEmpresas(text?: string): void {
        let observable;
        if (text) {
            const params = this.buildFilters(text);
            observable = this.service.allWith(params);
        } else {
            observable = this.service.all();
        }
        observable
            .subscribe({
                next: value => {
                    this.empresas = value;
                },
                error: err => {
                    this.logger.error(err);
                    this.empresas = [];
                }
            });
    }

    displayWithFn = (value: any) => {
        if (!value) {
            return '';
        }
        if (typeof value === 'string') {
            return value;
        }
        return value.nombre || value.razon_social || value.rut;
    };

    protected buildFilters(inputText: string): QueryParams {
        return {
            filters: [
                {
                    value: inputText,
                    field: 'rut'
                },
                {
                    value: inputText,
                    field: 'nombre'
                }
            ]
        };
    }
}
