import {Admin, BaseDTO, Reserva, TipoRegla, ValidacionReglaResult} from '@obrador/api-interfaces';

export interface IReglaOutputData<T> {
    data: T;
}

export interface IReglaInputData<T> {
    id?: number;
    key: string;
    value: T;
}

export interface ExecuteReglasReserva {
    id_regla: number;
    id_reserva: number;
    id_viajes?: Array<number>;
}

export interface Regla extends BaseDTO {
    tipo: TipoRegla;
    nombre: string;
    descripcion: string;
    schema: JSON;
    reservas?: Array<Reserva>;
    reglaInputReservas?: Array<ReglaInputReserva>;
    inputs: Array<ReglaInput>
    eventos: Array<EventoValidacion>;
}

export interface ReglaInputReserva {
    data: Array<IReglaInputData<any>>;
    regla?: Regla;
    reserva?: Reserva;
}

export interface EventoValidacion extends BaseDTO {
    input: ReglaInputReserva;
    output: IReglaOutputData<unknown>;
    result: ValidacionReglaResult;
    fecha: Date;
    reserva: Reserva;
    regla: Regla;
    id_reserva?: number;
    id_regla?: number;
    admin?: Admin
}

export interface ReglaInput {
    id?: number;
    key: string;
    tipo: 'number' | 'string' | 'boolean' | 'symbol' | 'bigint';
    regla: Regla
}
