import { Pipe, PipeTransform } from '@angular/core';
import { ValidacionReglaResult } from '@obrador/api-interfaces';

@Pipe({
    name: 'reglaResultIcon'
})
export class ReglaResultIconPipe implements PipeTransform {

    transform(value: ValidacionReglaResult): string {
        if (value === null || value === undefined) {
            return 'question_mark';
        }
        switch (value) {
            case ValidacionReglaResult.FAILED:
            case ValidacionReglaResult.ERROR:
                return 'thumb_down';
            case ValidacionReglaResult.PASSED:
                return 'thumb_up';
        }

    }

}
