import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Empresa, RecursoConductor, RecursoMotorizado, RecursoRemolque } from '@obrador/api-interfaces';
import { EmpresaService, QueryParams, RecursoService } from '@obrador/core';
import _ from 'lodash';
import {forkJoin, of, Subscription} from 'rxjs';
import { CuentaSelectService, SideBarService, SnackBarService } from '../../services';
import { buildQueryEmpresas } from '../../utils';
import {
    FormInfiniteAutocompleteComponent
} from "../form/form-infinite-autocomplete/form-infinite-autocomplete.component";

const INITIAL_QUERY_EMPRESAS: QueryParams =  {
    limit: 30,
    page: 1,
    concatData: true
}

enum RecursoFilter {
    CONDUCTORES = 0, MOTORIZADOS = 1, REMOLQUES = 2
}

@Component({
    selector: 'app-recursos-convocatoria',
    templateUrl: './recursos-convocatoria.component.html',
    styleUrls: ['./recursos-convocatoria.component.scss'],
})
export class RecursosConvocatoriaComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(FormInfiniteAutocompleteComponent, { static: false })
    formInfiniteAuto: FormInfiniteAutocompleteComponent;

    @Input()
    isVisible: boolean;

    readonly buildQueryEmpresas = buildQueryEmpresas;
    initialQueryEmpresas = INITIAL_QUERY_EMPRESAS;
    // Form
    controlEmpresa = new UntypedFormControl();
    filters: Array<RecursoFilter>;
    selectedDate: Date;
    empresa: Empresa;
    // Enum
    Filters = RecursoFilter;

    loading: boolean;
    motorizados: Array<RecursoMotorizado>;
    conductores: Array<RecursoConductor>;
    remolques: Array<RecursoRemolque>;
    noData: boolean;

    cuentaActual: number;
    cuentaSub: Subscription;

    constructor(protected service: RecursoService,
                protected snack: SnackBarService,
                protected sideBar: SideBarService,
                public empresaService: EmpresaService,
                protected cuentaSelectService: CuentaSelectService
    ) {
    }

    ngOnInit(): void {
        this.selectedDate = new Date();
        this.filters = [RecursoFilter.CONDUCTORES];
    }

    ngAfterViewInit() {
        this.cuentaSub = this.cuentaSelectService.cuentaActual$.subscribe(cuenta => {
            this.cuentaActual = cuenta;
            this.initialQueryEmpresas = INITIAL_QUERY_EMPRESAS;
            this.initialQueryEmpresas.filters = [{ field: 'cuentaId', value: this.cuentaActual }];
            if (this.isVisible) {
                this.formInfiniteAuto.refresh();
                this.resetData();
            }
        });
    }

    ngOnDestroy() {
        if (this.cuentaSub) {
            this.cuentaSub.unsubscribe();
        }
    }

    buildQueryEmpresaCuenta = (params: QueryParams, txt: string): QueryParams  => {
        params.filters = [];
        if (txt) {
            params.filters.push({
                value: txt,
                field: 'nombre'
            });
        }
        if (this.cuentaActual) {
            const index = params.filters.findIndex(f => f.field === 'cuentaId');
            if (index >= 0) {
                params.filters[index].value = this.cuentaActual;
            } else {
                params.filters.push({
                    value: this.cuentaActual,
                    field: 'cuentaId'
                });
            }
        }
        return params;
    }

    onEmpresaChange(empresa: Empresa): void {
        this.empresa = empresa;
        if (this.canFetchData()) {
            this.getData(this.empresa.id);
        }
    }

    onDateChange(): void {
        if (this.canFetchData()) {
            this.getData(this.empresa.id);
        }
    }

    onFiltersChange(): void {
        if (this.canFetchData()) {
            this.getData(this.empresa.id);
        }
        if (this.filters.length === 0) {
            this.resetData();
        }
    }

    resetData(): void {
        this.empresa = null;
        this.conductores = [];
        this.remolques = [];
        this.motorizados = [];
        this.noData = true;
    }

    canFetchData(): boolean {
        return this.empresa && this.filters?.length > 0;
    }

    getData(id_empresa: number): void {
        this.noData = false;
        let obsConductores = of([]);
        let obsMotorizados = of([]);
        let obsRemolques = of([]);
        this.loading = true;
        for (const filter of this.filters) {
            if (filter === RecursoFilter.CONDUCTORES) {
                obsConductores = this.service.getConductores(id_empresa, this.selectedDate);
            } else if (filter === RecursoFilter.MOTORIZADOS) {
                obsMotorizados = this.service.getMotorizados(id_empresa, this.selectedDate);
            } else if (filter === RecursoFilter.REMOLQUES) {
                obsRemolques = this.service.getRemolques(id_empresa, this.selectedDate);
            }
        }
        forkJoin([obsConductores, obsMotorizados, obsRemolques])
            .subscribe({
                next: ([conductores, motorizados, remolques]) => {
                    this.conductores = this.mapConductores(conductores);
                    this.motorizados = this.mapData(motorizados);
                    this.remolques = this.mapData(remolques) as Array<RecursoRemolque>;
                    this.loading = true;
                    this.noData = this.conductores.length === 0 && this.motorizados.length === 0 && this.remolques.length === 0
                },
                error: () => {
                    this.loading = false;
                    this.handleError();
                }
            });
    }

    close(): void {
        this.sideBar.toggleSideNavRecursos();
    }

    mapData(arr: Array<RecursoMotorizado | RecursoRemolque>): Array<RecursoMotorizado | RecursoRemolque> {
        const result = arr.map(el => {
            el.cantidad = el.convocatorias?.length || 0;
            return el;
        });
        return _.orderBy(result, ['cantidad', 'matricula'], ['desc', 'asc'])
    }

    mapConductores(arr: Array<RecursoConductor>): Array<RecursoConductor> {
        const result = arr.map(el => {
            el.cantidad = el.sub_convocatorias?.length || 0;
            return el;
        });
        return _.orderBy(result, ['cantidad', 'id'], ['desc', 'asc'])
    }

    handleError(err?: any): void {
        this.snack.error(`Ocurrió un error inesperado`);
    }

}
