import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'groupBy'
})
@Injectable()
export class GroupByPipe implements PipeTransform {

    public transform<T>(value: Array<T>, field: keyof T): Array<{key: any, value: Array<T>}> {
        if (!value || value.length === 0) {
            return [];
        }
        const map = new Map<any, Array<T>>();
        for (const el of value) {
            const fieldValue = el[field];
            if (map.has(fieldValue)) {
                map.get(fieldValue).push(el);
            } else {
                map.set(fieldValue, [el]);
            }
        }
        const result = [];
        for (const [key, value] of map.entries()) {
            result.push({
                key,
                value
            });
        }
        return result;
    }

}
