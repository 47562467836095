import {
    differenceInDays,
    endOfDay,
    format,
    isAfter,
    isBefore,
    isDate,
    isEqual,
    isSameDay,
    isValid,
    startOfDay,
    addDays,
    subDays
} from 'date-fns';
import { formatInTimeZone, toDate, utcToZonedTime } from 'date-fns-tz';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';

export const GMT0_TZ = 'Etc/GMT';
export const UYT = 'America/Montevideo';

export interface FirestoreTimestamp {
    seconds: number;
    nanos: number;
}

export abstract class DateUtils {

    static MS_TO_NANOS = 1e6;

    static isValid(d: Date): boolean {
        return isValid(d);
    }

    static isBetween(pointInTime: Date, start: Date, end: Date): boolean {
        if (!pointInTime) return false;
        const ms = pointInTime.getTime();
        return start.getTime() <= ms && end.getTime() >= ms;
    }

    static isBetweenStrict(pointInTime: Date, start: Date, end: Date) {
        if (!pointInTime) return false;
        const ms = pointInTime.getTime();
        return start.getTime() < ms && end.getTime() > ms;
    }

    static dayInterval = (date: Date): Date[] => {
        return [
            startOfDay(date),
            endOfDay(date)
        ];
    };

    static todayInterval = (): Date[] => {
        return DateUtils.dayInterval(new Date());
    };

    static mixFechaHora = (fecha: Date, hora: string, formatHora='HH:mm'): Date => {
        return parse(hora, formatHora, fecha);
    }

    static isSameDayMonthYear(d1: Date, d2: Date): boolean {
        return DateUtils.isSameDay(d1, d2);
    }

    static isSameDay(d1: Date, d2: Date): boolean {
        if (!d1 || !d2) {
            return false;
        }
        return isSameDay(d1, d2);
    }

    static equals(d1: Date, d2: Date): boolean {
        return isEqual(d1, d2);
    }

    static formatISOWithoutTZ(date: Date | string, dateFormat = 'yyyy-MM-dd HH:mm'): string {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        return format(date, dateFormat);
    }

    static addDays(date: Date, days: number): Date {
        return addDays(date, days)
    }

    static subDays(date: Date, days: number): Date {
        return subDays(date, days);
    }
    /**
     * Determina si una fecha es anterior, descartando hora
     * @param date1
     * @param date2
     */
    static isDateBefore(date1: Date, date2: Date): boolean {
        const wrap1 = startOfDay(date1);
        const wrap2 = startOfDay(date2);
        return isBefore(wrap1, wrap2);
    }

    /**
     * Mismo dia o antes. Descarta horas
     * @param date1
     * @param date2
     */
    static isDateBeforeOrEqual(date1: Date, date2: Date): boolean {
        const wrap1 = startOfDay(date1);
        const wrap2 = startOfDay(date2);
        return isBefore(wrap1, wrap2) || isSameDay(wrap1, wrap2);
    }

    static isDateAfterOrEqual(date1: Date, date2: Date): boolean {
        const wrap1 = startOfDay(date1);
        const wrap2 = startOfDay(date2);
        return isAfter(wrap1, wrap2) || isSameDay(wrap1, wrap2);
    }

    static isDateAfter(date1: Date, date2: Date): boolean {
        const wrap1 = startOfDay(date1);
        const wrap2 = startOfDay(date2);
        return isAfter(wrap1, wrap2);
    }

    /**
     * Convierte un date a un iso string con GMT 0
     * @param date
     */
    static formatToGMT0ISO(date: Date): string {
        return DateUtils.toGMT0Date(date).toISOString();
    }

    static toGMT0Date(date: Date): Date {
        return toDate(date.toISOString(), { timeZone: GMT0_TZ });
    }

    static daysBetween(d1: Date, d2: Date): number {
        return differenceInDays(d1, d2);
    }

    static parseOnlyDate(str: string, referenceDate?: Date): Date {
        return parse(str, 'yyyy-MM-dd', referenceDate || new Date());
    }

    static parseInFormat(str: string, format: string, referenceDate?: Date): Date {
        return parse(str, format, referenceDate || new Date());
    }

    static parseWithTZ(str: string, tz = UYT) {
        return toDate(str, { timeZone: tz });
    }

    static parseWithTZFormat(str: string, format: string, tz=UYT) {
        const date = DateUtils.parseInFormat(str, format);
        return utcToZonedTime(date, tz);
    }

    static parseFirebaseDateTime(firestoreDate: FirestoreTimestamp, tz = UYT): Date {
        return new Date(firestoreDate.seconds * 1000 + firestoreDate.nanos / DateUtils.MS_TO_NANOS);
    }

    static parseISO(str: any): Date {
        return parseISO(str);
    }

    static formatInTimezone(date: Date, format: string): string {
        return formatInTimeZone(date, UYT, format);
    }

    static isDate(val: unknown): boolean {
        return isDate(val);
    }

    /**
     * Determina si en primer periodo esta dentro del segundo periodo, o si el segundo periodo esta dentro del primer periodo.
     * @param start1
     * @param end1
     * @param start2
     * @param end2
     */
    static areIntersected(start1: Date, end1: Date, start2: Date, end2: Date): boolean {
        if (!start1 || !start2 || !end1 || !end2) {
            throw new Error('isIntersection failed invalid arguments.')
        }
        return DateUtils.isBetween(start1, start2, end2) || DateUtils.isBetween(end1, start2, end2) ||
            DateUtils.isBetween(start2, start1, end1) || DateUtils.isBetween(end2, start1, end1);
    }

    static isGreaterOrEqual(date1: Date, date2: Date): boolean {
        return isAfter(date1, date2) || isEqual(date1, date2);
    }

    static isLessThanOrEqual(date1: Date, date2: Date): boolean {
        return isBefore(date1, date2) || isEqual(date1, date2);
    }

    static diffDays(start: Date, end: Date): number {
        return differenceInDays(start, end);
    }

}
