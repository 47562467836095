import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';

export class DataTableDataSource<T> extends MatTableDataSource<T> {

    private _filterChange: Subject<T[]>;

    constructor(initialData: T[] = []) {
        super(initialData);
        this._filterChange = new Subject();
    }

    get filter(): string {
        return super.filter;
    }

    set filter(filter: string) {
        super.filter = filter;
        this._filterChange.next(this.filteredData);
    }

    filterChange(): Observable<T[]> {
        return this._filterChange.asObservable();
    }


}
