
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<!--<a href="https://wrappixel.com/templates/materialpro-angular-dashboard/" class=" m-r-20 hidden-sm-up" mat-raised-button color="warn">Upgrade To Pro</a>-->
<!--<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5 p-0 d-flex jc-c align-items-center"> <img src="assets/images/users/1.jpg" alt="user" class="profile-pic"> </button>-->
<!--<mat-menu #profile="matMenu" class="mymegamenu">-->
<!--    <button mat-menu-item>-->
<!--        <mat-icon>settings</mat-icon> Settings </button>-->
<!--    <button mat-menu-item>-->
<!--        <mat-icon>account_box</mat-icon> Profile </button>-->
<!--    <button mat-menu-item>-->
<!--        <mat-icon>notifications_off</mat-icon> Disable notifications </button>-->
<!--    <button mat-menu-item>-->
<!--        <mat-icon>exit_to_app</mat-icon> Sign Out </button>-->
<!--</mat-menu>-->
<button (click)='toggle()' mat-icon-button class="m-r-5">
    <mat-icon>list</mat-icon>
</button>
