import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {

    constructor(protected snackBar: MatSnackBar) {

    }

    success(msg: string): MatSnackBarRef<TextOnlySnackBar> {
        return this.snackBar.open(msg, 'x', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
        })
    }

    error(msg: string): MatSnackBarRef<TextOnlySnackBar> {
        return this.snackBar.open(msg, 'x', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
        });
    }

    unexpected(): MatSnackBarRef<TextOnlySnackBar> {
        return this.error('Ocurrió un error inesperado.')
    }

}
