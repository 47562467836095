import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Reserva } from '@obrador/api-interfaces';

@Component({
    selector: 'app-reserva-small-card',
    templateUrl: './reserva-small-card.component.html',
    styleUrls: ['./reserva-small-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReservaSmallCardComponent {

    @Input() reserva: Reserva;

    @Output() readonly closeClick = new EventEmitter<Reserva>();

    onRemove(): void {
        this.closeClick.emit(this.reserva);
    }

}
