<table  *ngIf='columnStrings?.length > 0 && columns?.length > 0' matSort mat-table multiTemplateDataRows [dataSource]='dataSource' [trackBy]=' trackByProperty | trackByFn'>
    <tr class='mat-row' *matNoDataRow>
        <ng-container *ngIf='isEmpty'>
            <td class='mat-cell' colspan='9999'>
                <app-alert-message type='info'>
                    No hay datos para visualizar.
                </app-alert-message>
            </td>
        </ng-container>
    </tr>
    <ng-container *ngIf='selectableRows'>
        <ng-container matColumnDef='selection'>
            <th class='tiny-column' mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)='$event ? masterToggle() : null'
                              [checked]='selection.size > 0 && isAllSelected()'
                              [indeterminate]='selection.size > 0 && !isAllSelected()'>
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef='let row'>
                <mat-checkbox
                    (click)='$event.stopPropagation()'
                    (change)='toggleChanged($event, row)'
                    [checked]='selection.has(row.id)'>
                </mat-checkbox>
            </td>
        </ng-container>
    </ng-container>
    <!-- Columnas -->
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnName">
        <th [ngClass]='column.headerClass' mat-header-cell mat-sort-header *matHeaderCellDef> {{ column.header }} </th>
        <td (click)='column.onClick ? column.onClick(element) : null' [ngClass]="element | dataTableClass: column" mat-cell *matCellDef="let element">
            <ng-container *ngIf="column.render; else defaultCell">
                <div [matTooltipClass]='column.tooltipClass' [matTooltip]='column?.tooltip ? ( element | nestedObject:column.tooltip.path: column.tooltip.pipes ) : null' [innerHTML]='element | dataTableCell: column'></div>
            </ng-container>
            <ng-template #defaultCell>
                <span [matTooltipClass]='column.tooltipClass' [matTooltip]='column?.tooltip ? ( element | nestedObject:column.tooltip.path: column.tooltip.pipes ) : null'>
                    {{ element | nestedObject:column.property.path: column.property.pipes }}
                </span>
            </ng-template>
        </td>
    </ng-container>

    <ng-container *ngIf='actions?.length > 0'>
        <ng-container matColumnDef='actions'>
            <th *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngFor='let action of actions; trackByIndex'>
                    <button [matBadge]='action.badge? action.badge(element) : null' [ngClass]='action.cssClass' (click)='action.onClick? action.onClick(element) : null' [matTooltip]='action.renderTooltip ? action.renderTooltip(element) : action.tooltip' mat-icon-button
                            *ngIf="action.isHidden ? !action.isHidden(element) : true">
                        <mat-icon> {{ action.icon }}</mat-icon>
                    </button>
                </ng-container>
            </td>
        </ng-container>
    </ng-container>

    <ng-container matColumnDef='footer' *ngIf='footerButtons?.length > 0'>
        <td mat-footer-cell *matFooterCellDef="let row" [colSpan]='columnStrings.length'>
            <div class='footer-cell'>
                <ng-container *ngFor='let button of footerButtons; trackByIndex'>
                    <ng-container *ngIf="button.buttonType === 'mat'; else iconBtn">
                        <button [disabled]='isFooterButtonDisabled(button)' [matTooltip]='button.tooltip' mat-raised-button color='primary' (click)='button.click(dataSource, selection)'> {{ button.text }}</button>
                    </ng-container>
                    <ng-template #iconBtn>
                        <button [disabled]='isFooterButtonDisabled(button)' [matTooltip]='button.tooltip' (click)='button.click(dataSource, selection)' mat-fab color='accent'>
                            <mat-icon> {{ button.icon }}</mat-icon>
                        </button>
                    </ng-template>
                </ng-container>
            </div>
        </td>
    </ng-container>

    <!-- Fila de encabezado -->
    <tr mat-header-row *matHeaderRowDef="columnStrings"></tr>

    <!-- Fila de datos -->
    <tr mat-row *matRowDef="let row; columns: columnStrings;" [ngClass]='row.rowClass ? row.rowClass : null '></tr>

    <!-- Fila de Footer -->
    <tr mat-footer-row *matFooterRowDef="['footer']; sticky: true; let row;"></tr>

</table>
