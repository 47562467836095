import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';

@Injectable()
export class LocalStorageService {

    constructor(private storage: StorageMap) {
    }

    getItem(key: string): Observable<unknown> {
        return this.storage.get(key);
    }

    setItem(key: string, value: unknown): Observable<void> {
        return this.storage.set(key, value);
    }

    removeItem(key: string): Observable<void> {
        return this.storage.delete(key);
    }

}
