import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { groupBy, noop } from 'lodash';

export interface FormGroupSelectGroup {
    label: string;
    values: FormGroupSelectValue[];
}

export interface FormGroupSelectValue {
    label: string;
    value: any;
}

export interface FormGroupSelectOption {
    key: string;
    keyLabel: string;
    value: any;
}

interface GroupOption {
    group: string;
    groupLabel: string;
    elements: FormGroupSelectOption[];
}

@Component({
    selector: 'app-form-group-select',
    templateUrl: './form-group-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormGroupSelectComponent),
            multi: true
        }
    ]
})
export class FormGroupSelectComponent implements ControlValueAccessor, OnInit {

    @Input() label = 'Filtros';
    @Input() set options(o: FormGroupSelectOption[])  {
        if (o) {
            const groupKeys = new Map<string, GroupOption>();
            for (const opt of o) {
                let group: GroupOption = groupKeys.get(opt.key);
                if (!group) {
                    group = {
                        group: opt.key,
                        groupLabel: opt.keyLabel,
                        elements: []
                    }
                    groupKeys.set(opt.key, group);
                }
                group.elements.push(opt);
            }
            this.groupedOptions = [... groupKeys.values()];
        } else {
            this.groupedOptions = [];
        }
    }

    groupedOptions: GroupOption[];
    value: FormGroupSelectOption[] = [];
    isDisabled: boolean;

    private onChange = (value: any) => {};
    private onTouched = () => {};

    ngOnInit() {

    }

    writeValue(values: FormGroupSelectOption[]): void {
        this.value = values;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    handleChange(values: FormGroupSelectOption[]): void {
        this.value = values;
        this.onChange(values);
    }
}
