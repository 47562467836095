import { AdminDTO } from './admin';
import { Alarma } from './alarma';
import { BaseDTO } from './api-interfaces';
import { AutoGenerarViajeResult, EstadoViaje, TipoEventoViaje } from './enums';
import { EventoViajeCancerbero } from './evento-viaje-cb';
import { Novedad } from './novedad';
import { Pesada } from './pesada';
import { EventoValidacion } from './regla';
import { Reserva } from './reserva';
import { SubConvocatoria } from './sub-convocatoria';
import { Conductor } from "./conductor";

export interface Viaje extends BaseDTO {
    id_migrador?: number;
    estado?: EstadoViaje;
    id_migrador_reserva: number;
    sub_convocatoria?: SubConvocatoria;
    fecha_inicio: Date | string;
    fecha_fin : Date | string;
    orden: number;
    reserva?: Reserva;
    viaje_api: ViajeApi;
    eventos?: EventoViaje[]
    eventos_cancerbero?: EventoViajeCancerbero[]
    comentario?: string;
    alarmas?: Alarma[]
    novedades?: Novedad[];
    registro?: ViajeRegistro;
}

export interface EventoViaje {
    fecha?: Date;
    id_viaje: number;
    id_migrador?: number;
    tipo: TipoEventoViaje;
    adminId?: number;
    admin?: Partial<AdminDTO>;
    viaje?: Partial<Viaje>;
    comentario?: string;
}

export interface ViajeRegistro extends BaseDTO {
    ultimo_evento_cancerbero?: EventoViajeCancerbero;
    resultado_validaciones?: boolean;
}
export interface BulkUpdateViajesDTO {
    viajes: Array<ViajeUpdateBulkRequest>;
}

export declare type ViajeUpdateBulkRequest = Partial<Viaje>;

export interface ViajeSwapRequest {
    origen: ViajeUpdateBulkRequest;
    destino: ViajeUpdateBulkRequest;
}

export interface ViajeApi extends BaseDTO {
    document_id: string;
    estados: Array<AppEventoEstadoViaje>;
    remitos: Array<AppRemitoViaje>;
    fotos: Array<AppFotoViaje>;
    contenedores: Array<AppEventoContenedor>;
    informaciones: Array<AppEventoInformacion>
    finalizado: boolean;
    fecha_inicio: Date;
    fecha_fin: Date;
    viaje?: Viaje,
    problema?: Problema;
    eventos_app?: any[];
    eventos_validacion?: EventoValidacion[];
    usuario_app?: Conductor
    ultima_interaccion?: UltimaInteraccion;
    pesadas?: Array<Pesada>;
}

export interface AppFotoViaje extends AppEventoViaje {
    id: number;
    viaje_id?: number;
    url: string;
    uid: string;
    fecha: Date;
    vision_info: any;
}

export interface AppRemitoViaje extends AppFotoViaje {
    numero: string;
    lugar: string;
    kilos_netos: string;
}

export interface AppEventoEstadoViaje extends AppEventoViaje {
    tipo_estado: { nombre: string, id: number; }
}

export interface AppEventoViaje {
    id?: number;
    viaje_id?: number;
    borrado?: number;
    latitude?: string;
    longitude?: string;
    fecha: Date;
    uid: string;
    numero_contenedor?: string;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
    en_destino?: number;
    en_origen?: number;
    usuario_app?: Conductor;
    id_evento_app?: number;
}

export interface AppEventoContenedor extends AppEventoViaje {
    numero_contenedor?: string;
    correcto?: boolean;
    vision_info?: any;
    url?: string;
}

export interface AppEventoInformacion extends AppEventoViaje {
    valor: string;
}

export enum EstadoViajePCH {
    CARGADO = 1,
    VACIO = 2,
    DESCONSOLIDADO = 3
}

export interface Problema extends AppEventoViaje {
    uid: string;
    comentario?: string;
}

export interface CambioReserva {
    id_reserva: number;
    id_viaje: number;
    motivoId: number;
}

export interface CambioReservaBulk {
    id_viajes: number[];
    id_reserva: number;
    motivoId: number;
}

export enum TipoEventoApp {
    PROBLEMA = 'PROBLEMA',
    REMITO = 'REMITO',
    FOTO = 'FOTO',
    CONTENEDOR = 'CONTENEDOR',
    ESTADO = 'ESTADO',
    INFORMACION = 'INFORMACION'
}

export interface UltimaInteraccion {
    id: number;
    viaje_id: number;
    origen: string;
    fecha: string;
    created_at: string;
    updated_at: string;
}

export interface AutoGenerarRequest {
    data: AutogenerarRequestData[];
}

export interface AutogenerarRequestData {
    id_viaje: number;
    fecha_inicio: string;
    fecha_fin: string;
    motivo: string;
    comentario?: string;
}

export interface AutoGenerarResult {
    result: AutoGenerarViajeResult;
}
