import { Injectable, Injector } from '@angular/core';
import { ControlDesvio, IControlDesvioResult } from '@obrador/api-interfaces';
import { catchError, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

export interface ControlDesvioResult extends IControlDesvioResult {
    control: ControlDesvio
}

@Injectable({
  providedIn: 'root'
})
export class ControlReservaService extends RestService<ControlDesvioResult>{
    constructor(protected injector: Injector) {
        super(injector, Resource.CONTROL_DESVIOS);
    }

    getByReserva(id_reserva: number): Observable<ControlDesvioResult[]> {
        return this.http.get<ControlDesvioResult[]>(`${this.buildURL()}/reservas/${id_reserva}`)
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapArray(data))
            );
    }
}
