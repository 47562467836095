<h2 mat-dialog-title>
    {{ options.title }}
</h2>
<div mat-dialog-content [formGroup]='form'>
    <ng-container *ngIf='options.message'>
        <app-alert-message [message]='options.message.text' [type]='options.message.type'></app-alert-message>
    </ng-container>
    <mat-form-field>
        <textarea [placeholder]='options.placeholder || ""' matInput formControlName='comentario'> </textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions class='justify-content-end'>
    <button mat-raised-button color="primary" (click)='close(true)'>Aceptar</button>
    <button mat-raised-button color="secondary" (click)='close()'>Cancelar</button>
</div>
