import { Injectable, Injector } from '@angular/core';
import { Motorizado } from '@obrador/api-interfaces';
import { catchError, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagedResult, QueryParams } from '../rest.model';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class MotorizadoService extends RestService<Motorizado> {

    constructor(protected injector: Injector) {
        super(injector, Resource.MOTORIZADOS);
    }

    allWith(queryParams: QueryParams): Observable<Array<Motorizado>> {
        return this.paginated({
            ...queryParams,
            limit: 2000,
            page: 1
        }).pipe(
            map(result => result.data)
        );
    }

    all(): Observable<Array<Motorizado>> {
        return this.paginated({
            limit: 2000,
            page: 1
        }).pipe(
            map(result => result.data)
        );
    }

    paginatedAll(queryParams: QueryParams): Observable<PagedResult<Motorizado>> {
        return this.http.get<PagedResult<Motorizado>>(`${this.buildURL()}/recursos`, { params: this.buildParams(queryParams) })
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapPaginated(data, queryParams))
            );
    }
}
