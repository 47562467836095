import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AdminDTO } from '@obrador/api-interfaces';
import { AuthService } from '@obrador/core';
import { MenuItems } from '@obrador/shared';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: []
})
export class AppSidebarComponent implements OnDestroy, OnInit {

    readonly DEFAULT_IMAGE = 'assets/images/user.png';

  mobileQuery: MediaQueryList;
    user: AdminDTO

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
        public menuItems: MenuItems,
        protected authService: AuthService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

    async ngOnInit(): Promise<void> {
        this.user = await this.authService.getAdmin();
    }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

    async salir(): Promise<void> {
        await this.authService.logout();
    }
}
