import {addDays, addHours, format} from 'date-fns';
import { DateUtils } from './date';

interface FromToParams {
    from: string,
    to: string
}

export const FORMAT_MIGRADOR = "yyyy-MM-dd'T'HH:mm:ss"

export const getAPIDateParams = (daysAmount: number): FromToParams => {
    const toDate = new Date();
    toDate.setHours(23, 59, 59);
    const fromDate = addDays(toDate, daysAmount);
    fromDate.setHours(0, 0, 0);
    return {
        from: format(fromDate, FORMAT_MIGRADOR),
        to: format(toDate, FORMAT_MIGRADOR)
    };
}

export const getAPIHourlyParamsInterval = (hours: number): FromToParams => {
    const toDate = new Date();
    const quantity = Math.abs(hours);
    const from = addHours(toDate, (-quantity));
    return {
        from: DateUtils.formatInTimezone(from, FORMAT_MIGRADOR),
        to: DateUtils.formatInTimezone(toDate, FORMAT_MIGRADOR)
    };
};

export const getAPIDateParamsInterval = (days: number): FromToParams => {
    const now = new Date();
    const quantity = Math.abs(days);
    const from = addDays(now, (-quantity));
    from.setHours(0, 0, 0, 0);
    const to = addDays(now, quantity);
    to.setHours(23, 59, 59);
    return {
        from: format(from, FORMAT_MIGRADOR),
        to: format(to, FORMAT_MIGRADOR)
    };
};


export const getAPIDateParamsMinMax = (daysBefore: number, daysAfter: number): FromToParams => {
    const now = new Date();
    const before = Math.abs(daysBefore);
    const after = Math.abs(daysAfter);
    const from = addDays(now, (-before));
    from.setHours(0, 0, 0, 0);
    const to = addDays(now, after);
    to.setHours(23, 59, 59);
    return {
        from: format(from, FORMAT_MIGRADOR),
        to: format(to, FORMAT_MIGRADOR)
    };
}

export const getAPIDateParamsByDate = (start: Date, end: Date): FromToParams => {
    return {
        from: format(start, FORMAT_MIGRADOR),
        to: format(end, FORMAT_MIGRADOR)
    }
}

export const parseBoolean = (v: any): boolean => {
    if (!v) {
        return false;
    }
    switch (typeof v) {
        case 'boolean':
            return v;
        case 'number':
            return Boolean(v);
        case 'string':
            return v !== '0';
        case 'bigint':
            return Boolean(v);
        case 'object':
        case 'symbol':
            throw  new Error(`Unsuported type parseBoolean ${typeof v}`)
    }
    return false;
}

export const parseIdMigrador = (input: string): number => {
    // Verificamos que el input no sea nulo o indefinido
    if (input === null || input === undefined) {
        return NaN;
    }
    // Quitamos el primer caracter si es 'R'
    const substring = input[0].toUpperCase() === 'R' ? input.substring(1) : input;
    // Intentamos convertir la cadena resultante a número
    return parseInt(substring, 10);
};
