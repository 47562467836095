import _ from 'lodash';

export abstract class ArrayUtils {

    static isNotEmpty(arr: Array<any>): boolean {
        return arr && arr.length > 0;
    }

    static isEmpty(arr: Array<any>): boolean {
        return !ArrayUtils.isNotEmpty(arr);
    }

    static isArray(arr: unknown): boolean {
        return _.isArray(arr);
    }

    static removeAt(arr: Array<any>, position: number): Array<any> {
        if (arr) {
            return arr.splice(position, 1);
        }
        return arr;
    }

    /**
     * Compara usando ===
     * El orden importa
     * @param arr1
     * @param arr2
     */
    static isEqual(arr1: unknown[] | null | undefined, arr2: unknown[] | null | undefined): boolean {
        if (arr1 == null && arr2 == null) {
            return true;
        }
        if (arr1 == null || arr2 == null) {
            return false;
        }
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        return true;
    }

    static isEqualIgnoringOrder(a: Array<unknown>, b: Array<unknown>): boolean {
        if ((!a && b) || (b && !a)) {
            return false;
        }
        if (!a && !b) {
            return false;
        }
        return a.length === b.length && _.every(a, v => _.includes(b, v));
    }

    static isDeepEqual(x: unknown[], y: unknown[]): boolean {
        if (!x && y) {
            return false;
        }
        if (x && !y) {
            return true;
        }
        if (x === y) {
            return true;
        }
        return _(x).xorWith(y, _.isEqual).isEmpty();
    }

    static toMapByKey<K, T>(arr: T[], key: keyof T): Map<K, T> {
        const result = new Map();
        for (const el of arr) {
            const keyValue = el[key];
            result.set(keyValue, el);
        }
        return result;
    }

    static toSet<T>(arr: T[], key?: keyof T ): Set<any> {
        if (!arr) return new Set();
        const result = new Set();
        for (const el of arr) {
            if (key) {
                result.add(el[key]);
            } else {
                result.add(el);
            }
        }
        return result;
    }

}


export abstract class SetUtils {
    static setsAreEqual(s1: Set<unknown>, s2: Set<unknown>): boolean {
        if (s1.size !== s2.size) {
            return false;
        }
        for (const item of s1) {
            if (!s2.has(item)) {
                return false;
            }
        }
        return true;
    }

    static join(set: Set<unknown>, sep = ','): string {
        if (!set || !(set instanceof Set)) {
            return '';
        }
        let resultado = '';
        for (const valor of set) {
            if (valor !== null && valor !== undefined && valor !== '') {
                if (resultado) {
                    resultado += `${sep} `;
                }
                resultado += valor.toString();
            }
        }
        return resultado;
    }
}
