import { Directive, ElementRef, Input, Output, EventEmitter, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'img[onErrorImage]'
})
export class FallbackDirective implements OnDestroy {

    @Input() onErrorImage: string;
    @Output() loaded = new EventEmitter<boolean>();

    protected nativeElement: HTMLElement;
    protected isApplied = false;
    protected ERROR_EVENT_TYPE = 'error';
    protected LOAD_EVENT_TYPE = 'load';
    // eslint-disable-next-line @typescript-eslint/ban-types
    protected cancelOnError: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    protected cancelOnLoad: Function;

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.nativeElement = el.nativeElement;
        this.onError = this.onError.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.addEvents();
    }

    ngOnDestroy() {
        this.removeErrorEvent();
        this.removeOnLoadEvent();
    }

    private onError() {
        if (this.nativeElement.getAttribute('src') !== this.onErrorImage) {
            this.isApplied = true;
            this.renderer.setAttribute(this.nativeElement, 'src', this.onErrorImage);
        } else {
            this.removeOnLoadEvent();
        }
    }

    private onLoad() {
        this.loaded.emit(this.isApplied);
    }

    private removeErrorEvent() {
        if (this.cancelOnError) {
            this.cancelOnError();
        }
    }

    private removeOnLoadEvent() {
        if (this.cancelOnLoad) {
            this.cancelOnLoad();
        }
    }

    private addEvents() {
        this.cancelOnError = this.renderer.listen(this.nativeElement, this.ERROR_EVENT_TYPE, this.onError);
        this.cancelOnLoad = this.renderer.listen(this.nativeElement, this.LOAD_EVENT_TYPE, this.onLoad);
    }
}
