import { Injectable } from '@angular/core';
import { Logger } from '../../logger';
import { AppEvents } from './app-events';

export type EventHandler = (...args: Array<any>) => any;

@Injectable({
    providedIn: 'root'
})
/**
 * Class in charge of global app events.
 * Holds and publishes 'topics' so people can subscribe to them and do independent logic.
 * For example, topics like USER_LOGGED_OFF could be an event dispatched in an HTTP interceptor and we can handle
 * the logic elsewhere in the app.
 */
export class Events {
    private readonly eventHolder: Map<string, Array<EventHandler>> = new Map<string, Array<EventHandler>>();

    constructor(protected logger: Logger) {}

    /**
     * Subscribe to an event topic. Events that get posted to that topic will trigger the provided handler.
     *
     * @param topic the topic to subscribe to
     * @param handlers the event handler
     */
    subscribe(topic: AppEvents, ...handlers: Array<EventHandler>): void {
        let topics = this.eventHolder.get(topic);
        if (!topics) {
            this.eventHolder.set(topic, topics = []);
        }
        topics.push(...handlers);
    }

    /**
     * Unsubscribe from the given topic. Your handler will no longer receive events published to this topic.
     *
     * @param topic the topic to unsubscribe from
     * @param handler the event handler
     *
     * @return true if a handler was removed
     */
    unsubscribe(topic: AppEvents, handler?: EventHandler): boolean {
        if (!handler) {
            return this.eventHolder.delete(topic);
        }

        const topics = this.eventHolder.get(topic);
        if (!topics) {
            return false;
        }

        // We need to find and remove a specific handler
        const index = topics.indexOf(handler);

        if (index < 0) {
            // Wasn't found, wasn't removed
            return false;
        }
        topics.splice(index, 1);
        if (topics.length === 0) {
            this.eventHolder.delete(topic);
        }
        return true;
    }

    /**
     * Publish an event to the given topic.
     *
     * @param topic the topic to publish to
     * @param args the data to send as the even
     */
    publish(topic: AppEvents, ...args: Array<any>): Array<any> | null {
        const topics = this.eventHolder.get(topic);
        if (!topics) {
            return undefined;
        }
        return topics.map(handler => {
            try {
                return handler(...args);
            } catch (e) {
                this.logger.error(e);
                return undefined;
            }
        });
    }
}
