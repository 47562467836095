import { Component, OnInit } from '@angular/core';
import { MENU_ITEMS } from '../pages';

@Component({
  selector: 'obrador-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

    menu = MENU_ITEMS;
}
