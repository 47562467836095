import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StorageModule } from '@ngx-pwa/local-storage';
import { AUTH_INTERCEPTOR, ERROR_INTERCEPTOR } from './interceptors';
import { Logger } from './logger';
import { LayoutService } from './ui';

const INTERCEPTORS = [
    AUTH_INTERCEPTOR,
    ERROR_INTERCEPTOR
]

@NgModule({
    imports: [CommonModule, HttpClientModule, StorageModule],
    providers: [LayoutService, Logger, ...INTERCEPTORS]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(`CoreModule has already been loaded. Import Core modules in the AppModule only.`);
        }
    }
}
