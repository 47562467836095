import { Injectable } from '@angular/core';
import enums from './enums.json'

declare type EnumerateLike = {[index: string]: string};

export declare type Enumerable = 'EstadoConvocatoria' | 'EstadoSubConvocatoria' | 'EstadoViajePCH' | 'TipoMensajeProgramado' | 'EstadoTableFilter' | 'EstadoEventoViajeCancerbero' |
    'TipoAccionNovedad' | 'TipoMotivoNovedad' | 'TipoCierreReserva' | 'TipoFiltroSeguimiento' | 'MotivoProblema' | 'TipoControl';

@Injectable()
export class EnumTranslateService {

  getValue(enumName: Enumerable, value: string | number): string {
      const enumerate: EnumerateLike = enums[enumName];
      if (enumerate) {
          return enumerate[value];
      }
      return 'Sin Estado';
  }

}
