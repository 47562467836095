import { Inject, Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { from, Observable, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthService, Environment, ENVIRONMENT, Logger } from '@obrador/core';
import { ApiInterceptor } from './api.interceptor';

@Injectable()
export class ErrorInterceptor
    extends ApiInterceptor
    implements HttpInterceptor
{
    readonly API_URL: string;

    constructor(
        private authService: AuthService,
        private logger: Logger,
        @Inject(ENVIRONMENT) protected env: Environment
    ) {
        super();
        this.API_URL = env.obrador_url;
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (this.isUrlAPI(request.url)) {
            return next.handle(request).pipe(
                catchError((err) => {
                    const error = err.error?.message || err.statusText;
                    if ([401, 403].includes(err.status)) {
                        return this.authService.getLoginData().pipe(
                            switchMap((data) => {
                                this.logger.error(error);
                                if (data) {
                                    return from(this.authService.logout()).pipe(
                                        switchMap(() => throwError(error))
                                    );
                                }
                                return throwError(error);
                            })
                        );
                    }
                    return throwError(error);
                })
            );
        }
        return next.handle(request);
    }
}

export const ERROR_INTERCEPTOR = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
};
