import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, CuentaService } from "@obrador/core";
import { CuentaDTO } from "@obrador/api-interfaces";
import { FormBuilder, UntypedFormGroup } from "@angular/forms";
import { CuentaSelectService, DEFAULT_ACCOUNT_ID } from '@obrador/shared';
import { Subscription } from "rxjs";

@Component({
    selector: 'app-cuenta-select',
    templateUrl: './cuenta-select.component.html',
    styleUrls: ['./cuenta-select.component.scss'],
})
export class CuentaSelectComponent implements OnInit, OnDestroy {

    form: UntypedFormGroup;
    cuentas: CuentaDTO[] = [];
    forSub: Subscription;

    constructor(
        protected cuentaService: CuentaService,
        protected cuentaSelectService: CuentaSelectService,
        protected fb: FormBuilder,
        protected authService: AuthService
    ) {}

    async ngOnInit(): Promise<void> {
        this.form = this.fb.group({
            cuenta: [DEFAULT_ACCOUNT_ID]
        });
        this.forSub = this.form.valueChanges.subscribe(values => {
            if (values.cuenta) {
                this.cuentaSelectService.setCuentaActual(values.cuenta);
            }
        })
        const admin = await this.authService.getAdmin();
        this.cuentas = admin.perfiles?.reduce((acc, perfil) => acc.concat(perfil.cuentas), []);
    }

    ngOnDestroy() {
        if (this.forSub) {
            this.forSub.unsubscribe();
        }
    }
}
