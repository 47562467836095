import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Ausencia, Conductor } from '@obrador/api-interfaces';
import { catchError, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagedResult, QueryParams } from '../rest.model';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class ConductorService extends RestService<Conductor> {

    constructor(protected injector: Injector) {
        super(injector, Resource.CONDUCTORES);
    }

    getByEmpresa(rut: string): Observable<Array<Conductor>> {
        return this.allWith({
            filters: [{
                value: rut,
                field: 'rut'
            }]
        });
    }

    allWith(queryParams: QueryParams): Observable<Array<Conductor>> {
        return this.paginated({
            ...queryParams,
            limit: 2000,
            page: 1
        }).pipe(
            map(result => result?.data || [])
        );
    }

    all(): Observable<Array<Conductor>> {
        return this.paginated({
            limit: 2000,
            page: 1
        }).pipe(
            map(result => result?.data || [])
        );
    }

    getAusencias(id_conductor: number, from: Date, to: Date): Observable<Ausencia[]> {
        const params = new HttpParams().set('from', from.toISOString()).set('to', to.toISOString());
        return this.http.get<Ausencia[]>(`${this.buildURL()}/${id_conductor}/ausencias`, { params });
    }

    paginatedAll(queryParams: QueryParams): Observable<PagedResult<Conductor>> {
        return this.http.get<PagedResult<Conductor>>(`${this.buildURL()}/recursos`, { params: this.buildParams(queryParams) })
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapPaginated(data, queryParams))
            );
    }

}
