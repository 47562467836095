import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Timeout } from '@obrador/common';
import { Logger } from '@obrador/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

export interface InputTextEvent {
  value: string;
  emitLookup: boolean;
}

/**
 * Abstract class to create autocompletes.
 */
@Component({
    selector: 'app-form-autocomplete',
    template: ''
})
export abstract class FormAutocompleteComponent implements ControlValueAccessor {

  readonly EMPTY_RESULT: any = {
    result: []
  };

  @ViewChild(DefaultValueAccessor) accessor: DefaultValueAccessor;
  @Input() placeholder: string;
  @Input() keyLabel: string;
  @Input() formControl: UntypedFormControl;
  @Input() hasSearchIcon = false;

  @Output() readonly dataFetched = new EventEmitter();
  /**
   * Bind the entire object from the lookup service to the form control.
   * If false, it will bind just the value from keyLabel property.
   */
  @Input() bindObject = true;

  /**
   * Change from this component to Form Control.
   */
  onChange: (v: any) => void;

  inputChanged$: Subject<InputTextEvent>;
  keyUp$: BehaviorSubject<string>;

  @Output() readonly optionSelected: EventEmitter<any> = new EventEmitter();
  @Output() readonly textChanged: EventEmitter<string> = new EventEmitter();

  elements: Array<any>;

  protected constructor(protected logger: Logger) {
  }

  /**
   * From formControl => this component
   * Writes the 'keyLabel' attribute to the input text.
   * @param obj - object from formControl.
   */
  writeValue(obj: any): void {
    if (obj) {
      if (typeof obj !== 'object') {
        this.inputChanged$.next({
          value: obj,
          emitLookup: false
        });
      } else {
        this.inputChanged$.next({
          value: obj,
          emitLookup: false
        });
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Text input get's fires onTouched
   * @param fn -> automatic binding from form control interface.
   */
  @Timeout(0)
  registerOnTouched(fn: any): void {
      if (this.accessor) {
        this.accessor.registerOnTouched(fn);
      }
  }

  /**
   * From formControl => this component => text input.
   * Text input disabled state.
   * @param isDisabled - disabled or not.
   */
  setDisabledState(isDisabled: boolean): void {
    this.accessor?.setDisabledState(isDisabled);
  }

  trackByFn(index: number, item: any): any {
    if (item.id) {
      return item.id;
    }
    return index;
  }

  onKeyDown(val: any, ev: KeyboardEvent): void {
    if (ev && ev.keyCode === 8) { // backspace
      this.textChanged.emit(val);
    }
  }

  onKeyPress(val: any): void {
    this.textChanged.emit(val);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    const emitValue = !this.bindObject && value[this.keyLabel] ? value[this.keyLabel] : value;
    if (this.onChange) {
        this.onChange(emitValue);
    }
  }

  inputChangedEvent(value: string): void {
    this.inputChanged$.next({
      value,
      emitLookup: true
    });
  }

  displayWithFn(keyLabel: any): any {
    return (value: any) => {
      if (!value) {
        return '';
      }
      if (typeof value === 'string') {
        return value;
      }
      return keyLabel ? value[keyLabel] : value;
    };
  }

  handleError(error: any): Observable<any> {
    this.logger.error(error);
    return of(this.EMPTY_RESULT);
  }
}
