import { AdminDTO } from './admin';

export interface LoginDTO {
    username: string;
    password: string;
}

export interface LoginResponse {
    admin: AdminDTO;
    token: string;
}
