import { Pipe, PipeTransform } from '@angular/core';
import { differenceInMinutes } from 'date-fns';

@Pipe({
    name: 'minutesToHours'
})
export class MinutesToHoursPipe implements PipeTransform {

    private readonly ZERO = '00:00';
    transform(minutes: number): string {
        if (!minutes) {
            return this.ZERO;
        }
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
    }
}
