import { Pipe, PipeTransform } from '@angular/core';
import { Conductor } from '@obrador/api-interfaces';
import { trim } from 'lodash';

@Pipe({
    name: 'conductorStr'
})
export class ConductorStrPipe implements PipeTransform {

    transform(value: Conductor): string | null {
        if (!value) {
            return null;
        }
        return trim(value.display_name || value.email || value.ci?.toString());
    }

}
