import { BaseDTO, Empresa } from "./api-interfaces";
import {TipoEntidadRequerimiento, TipoRequerimiento} from "./enums";

export interface Requerimiento extends BaseDTO {
    entidad: TipoEntidadRequerimiento;
    codigo: string;
    tipo: TipoRequerimiento;
    nombre_campo?: string;
}

export interface RequerimientoEmpresa extends BaseDTO {
    requerimiento?: Requerimiento;
    empresa?: Empresa;
    requerimientoId?: number;
    empresaId?: number;
}
