import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    PipeTransform,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SafeHtml } from '@angular/platform-browser';
import { Timeout } from '@obrador/common';
import { Subscription } from 'rxjs';
import { DataTableDataSource } from '../../datasource';
import { DataTableFilters } from './data-table.utils';

export interface DataTableFooterButton<T> {
    text?: string;
    icon?: string;
    buttonType: 'fab' | 'mat';
    disabled?: (source: MatTableDataSource<T>, selected: Map<any, T>) => boolean;
    click: (source: MatTableDataSource<T>, selected: Map<any, T>) => void;
    tooltip?: string;
    color: 'primary' | 'accent';
}

export interface DataTableActionColumn<T> {
    icon: string;
    tooltip?: string;
    cssClass?: string;
    onClick?: (row: T) => any;
    isHidden?: (row: T) => boolean;
    badge?: (row: T) => string;
    renderTooltip?: (row: T) => string;
}

export interface DataTableColumn<T> {
    columnName: string;
    property?: { path: string, pipes?: { pipe: PipeTransform, args?: any[] }[];}
    tooltip?: { path: string, pipes?: { pipe: PipeTransform, args?: any[] }[];}
    tooltipClass?: string | string[];
    render?: (row: T) => SafeHtml | string;
    header: string;
    headerClass?: string;
    renderClass?: (row: T) => string | string[];
    sortOnInit?: { direction: 'asc' | 'desc'}
    onClick?: (row: T) => any;
    cellClass?: string | string[];
}

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @ViewChild(MatSort) sort: MatSort;

    protected initialized: boolean;
    protected subDataSource: Subscription;

    dataSource = new DataTableDataSource<any>([]);

    @Input() set data(d: Array<any>) {
        this.dataSource.data = d;
        this.isEmpty = d?.length === 0;
        if (this.selectableRows) {
            this.updateSelection(d);
        }
    }

    @Input() columns: DataTableColumn<any>[];

    @Input() actions: DataTableActionColumn<any>[];

    @Input() footerButtons: DataTableFooterButton<any>[]

    @Input() trackByProperty: string;

    @Input() filterPredicate: <T>(data: T, filter: string) => boolean;

    @Input() selectableRows: boolean;

    @Output() readonly dataChanged: EventEmitter<any[]> = new EventEmitter();

    selection: Map<any, any>;

    columnStrings: string[] = [];

    isEmpty: boolean;

    ngOnInit(): void {
        this.initialized = true;
        if (this.selectableRows && !this.selection) {
            this.selection = new Map();
        }
    }

    @Timeout(50)
    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        if (this.filterPredicate) {
            this.dataSource.filterPredicate = this.filterPredicate;
        }
        const sortable = this.columns.find(c => c.sortOnInit);
        if (sortable?.sortOnInit) {
            this.sort.active = sortable.columnName;
            this.sort.direction = sortable.sortOnInit.direction;
            this.sort.sortChange.emit({ active: this.sort.active, direction: this.sort.direction });
        }
        this.subDataSource = this.dataSource.connect()
            .subscribe(data => {
                this.dataChanged.emit(data);
            });
    }

    ngOnDestroy(): void {
        this.subDataSource.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
       if (changes.columns) {
           this.columnStrings = this.columns.map(c => c.columnName);
           if (this.actions) {
               this.columnStrings.push('actions');
           }
           if (this.selectableRows) {
               this.columnStrings.unshift('selection');
           }
       }
    }
    search(filters: DataTableFilters): void {
        this.dataSource.filter = JSON.stringify(filters);
        this.selection.clear();
    }
    getDataSource(): MatTableDataSource<any> {
        return this.dataSource;
    }

    isAllSelected() {
        return this.selection.size > 0 && this.selection.size === this.dataSource.filteredData?.length;
    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.dataSource.filteredData.forEach(row => this.selection.set(row.id, row));
        }
    }

    toggleChanged(event: MatCheckboxChange, row: any): void {
        if (event) {
            if (this.selection.has(row.id)) {
                this.selection.delete(row.id);
            } else {
                this.selection.set(row.id, row);
            }
        }
    }

    /**
     * Si se actualizan los datos es necesario mantener los seleccionados actualizados tambien.
     * @param data
     */
    updateSelection(data: Array<any>): void {
        if (!this.selection) {
            this.selection = new Map();
        } else if (this.selection.size > 0) {
            for (const el of data) {
                if (this.selection.has(el.id)) {
                    this.selection.set(el.id, el);
                }
            }
            // Quitar elementos que se hayan ido y estaban seleccionados.
            const keys = [...this.selection.values()];
            for (const key of keys) {
                if (!data.find(e => e.id === key)) {
                    this.selection.delete(key);
                }
            }
        }
    }

    isFooterButtonDisabled(btn: DataTableFooterButton<any>) {
        if (!btn.disabled) {
            return false;
        }
        return btn.disabled(this.dataSource, this.selection);
    }

    clearSelection(): void {
        this.selection.clear();
    }

}
