import {BaseDTO, Empresa} from './api-interfaces';
import { TipoConductor } from './enums';
import { SubConvocatoria } from "./sub-convocatoria";
import {ModeloDocumentacion, ModeloDocumento} from "./documento";
import { Viaje } from './viaje';

export interface Conductor extends BaseDTO {
    id_migrador: number;
    display_name: string;
    email: string;
    ci: number;
    uid: string;
    phone_number: string;
    photo_URL: string;
    tipo: TipoConductor;
    disabled: boolean;
    empresa?: Partial<Empresa>;
    sub_convocatorias_ids?: number[];
    viajes?: Viaje[];
    ultima_sub?: Partial<SubConvocatoria>;
    modelo_documentacion?: ModeloDocumentacion;
    documentos?: DocumentoConductor[];
}

export interface Ausencia extends BaseDTO {
    fecha_inicio: Date;
    fecha_fin: Date;
    comentario?: string;
    id_migrador: number;
    document_id: string;
    deleted: boolean;
    deleted_at?: Date;
    conductor?: Conductor;
    motivo?: BaseDTO & { nombre: string};
}

export interface DocumentoConductor extends BaseDTO {
    fecha_vencimiento: Date;
    vencimiento?: Date;
    image_url?: string;
    valido?: boolean;
    modelo_documento?: ModeloDocumento;
    modelo?: ModeloDocumento
}
