import { DatetimeAdapter } from '@ng-matero/extensions/core';
import { addHours, addMinutes, format, getHours, getMinutes, startOfMonth } from 'date-fns';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { es } from 'date-fns/locale';

export interface DateFnsOptions {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
}

export interface DateFnsDateTimeAdapterOptions extends DateFnsOptions {
    getInstance: any;
}

export const getDateFnsDateTimeAdapterOptionsInstance = function (): DateFnsOptions {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const _this = Object.assign({}, this);
    delete _this.getInstance;
    return _this;
};

/** InjectionToken for date-fns date adapter to configure options. */
export const DATEFNS_DATE_TIME_ADAPTER_OPTIONS = new InjectionToken<DateFnsDateTimeAdapterOptions>(
    'DATEFNS_DATE_TIME_ADAPTER_OPTIONS', {
        providedIn: 'root',
        factory: DATEFNS_DATE_TIME_ADAPTER_OPTIONS_FACTORY
    }
);

export function DATEFNS_DATE_TIME_ADAPTER_OPTIONS_FACTORY(): DateFnsDateTimeAdapterOptions {
    return {
        locale: es,
        getInstance: getDateFnsDateTimeAdapterOptionsInstance
    };
}

/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
    const valuesArray = Array(length);
    for (let i = 0; i < length; i++) {
        valuesArray[i] = valueFunction(i);
    }
    return valuesArray;
}

@Injectable()
export class DatetimeFnsAdapter extends DatetimeAdapter<Date>{

    private _localeData: {
        longMonths: string[],
        shortMonths: string[],
        narrowMonths: string[],
        longDaysOfWeek: string[],
        shortDaysOfWeek: string[],
        narrowDaysOfWeek: string[],
        dates: string[],
        hours: string[],
        minutes: string[]
    };

    constructor(
        @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
        @Optional()
        @Inject(DATEFNS_DATE_TIME_ADAPTER_OPTIONS) private options: DateFnsDateTimeAdapterOptions,
        _delegate: DateAdapter<Date>
    ) {
        super(_delegate);
        this.setLocale({
            locale: es,
            firstWeekContainsDate: undefined,
            getInstance: undefined,
            useAdditionalDayOfYearTokens: false,
            useAdditionalWeekYearTokens: false,
            weekStartsOn: undefined
        });
    }

    /**
     * Creates a date but allows the month and date to overflow.
     * @param {number} year
     * @param {number} month
     * @param {number} date
     * @param {number} hours -- default 0
     * @param {number} minutes -- default 0
     * @param {number} seconds -- default 0
     * @returns The new date, or null if invalid.
     * */
    private createDateWithOverflow(year: number, month: number, date: number, hours: number = 0, minutes: number = 0, seconds: number = 0): Date {
        const result = new Date(year, month, date, hours, minutes, seconds);
        if (year >= 0 && year < 100) {
            result.setFullYear(this.getYear(result) - 1900);
        }
        return result;
    }

    public setLocale(options?: DateFnsDateTimeAdapterOptions) {
        super.setLocale(es);
        if (options) {
            if (!this.options) {
                this.options = options;
            } else {
                for (const key in options) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    this.options[key] = options[key];
                }
            }
        }
        this.options.getInstance = getDateFnsDateTimeAdapterOptionsInstance;
        const dateFnsLocaleData = this.options.locale.localize;
        this._localeData = {
            longMonths: range(12, (i) => dateFnsLocaleData.month(i, { width: 'wide' })),
            shortMonths: range(12, (i) => dateFnsLocaleData.month(i, { width: 'abbreviated' })),
            narrowMonths: range(12, (i) => dateFnsLocaleData.month(i, { width: 'narrow' })),
            longDaysOfWeek: range(7, (i) => dateFnsLocaleData.day(i, { width: 'wide' })),
            shortDaysOfWeek: range(7, (i) => dateFnsLocaleData.day(i, { width: 'abbreviated' })),
            narrowDaysOfWeek: range(7, (i) => dateFnsLocaleData.day(i, { width: 'short' })),
            dates: range(31, (i) => String(this.getDate(this.createDate(2017, 0, i + 1)))),
            hours: range(24, i => format(this.createDatetime(2017, 0, 1, i, 0), 'H')),
            minutes: range(60, i => format(this.createDatetime(2017, 0, 1, 1, i), 'm')),
        };
    }

    createDate(year: number, month: number, date: number, hours: number = 0, minutes: number = 0, seconds: number = 0): Date {
        if (month < 0 || month > 11) {
            throw Error(`Invalid month index "${month}". Month index should be between 0 and 11.`);
        }
        if (date < 1) {
            throw Error(`Invalid date "${date}". Date should be greater than 0.`);
        }
        if (hours < 0 || hours > 23) {
            throw Error(`Invalid hours "${hours}". Hours should be between 0 and 23.`);
        }
        if (minutes < 0 || minutes > 59) {
            throw Error(`Invalid minutes "${minutes}". Minutes should between 0 and 59.`);
        }
        if (seconds < 0 || seconds > 59) {
            throw Error(`Invalid seconds "${seconds}". Seconds should be between 0 and 59.`);
        }
        const result = this.createDateWithOverflow(year, month, date, hours, minutes, seconds);
        // If the result isn't valid, the date must have been out of bounds for this month.
        if (!this.isValid(result)) {
            throw Error(`Invalid date "${date}" for month with index "${month}".`);
        }
        return result;
    }

    addCalendarHours(date: Date, hours: number): Date {
        return addHours(date, hours);
    }

    addCalendarMinutes(date: Date, minutes: number): Date {
        return addMinutes(date, minutes);
    }

    createDatetime(year: number, month: number, date: number, hour: number, minute: number): Date {
        return new Date(year, month, date, hour, minute);
    }

    getFirstDateOfMonth(date: Date): Date {
        return startOfMonth(date);
    }

    getHour(date: Date): number {
        return getHours(date);
    }

    getHourNames(): string[] {
        return this._localeData.hours;
    }

    getMinute(date: Date): number {
        return getMinutes(date);
    }

    getMinuteNames(): string[] {
        return this._localeData.minutes;
    }

    isInNextMonth(startDate: Date, endDate: Date): boolean {
        return false;
    }

}
