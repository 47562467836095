import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {EventoValidacionService} from "@obrador/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EventoValidacion} from "@obrador/api-interfaces";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {HistoricoValidacionTableRow} from '../../models';
import {ResumenReservaHelper} from '../../services';

export interface DialogHistoricoValidacionConfig {
    viajeId: number;
}

@Component({
    selector: 'app-historico-validacion-dialog',
    templateUrl: './historico-validacion-dialog.component.html',
    styleUrls: ['./historico-validacion-dialog.component.scss'],
})
export class HistoricoValidacionDialogComponent implements OnInit, AfterViewInit {

    readonly displayedColumns = [
        'id', 'fecha', 'regla', 'admin', 'resultado'
    ];
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    dataSource: MatTableDataSource<HistoricoValidacionTableRow>;
    eventos: EventoValidacion[];
    isLoading: boolean;
    isEmpty: boolean;

    constructor(
        protected dialogRef: MatDialogRef<HistoricoValidacionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public config: DialogHistoricoValidacionConfig,
        protected eventoValidacionService: EventoValidacionService,
        protected resumenReservaHelper: ResumenReservaHelper
    ) {}

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource();
        this.isLoading = true;
        this.eventoValidacionService.getHistoricoByViajeApiId(this.config.viajeId)
            .subscribe({
                next: eventos => {
                    this.eventos = eventos;
                    this.dataSource.data = this.eventos.map(e => {
                        return {
                            id: e.id,
                            fecha: e.fecha,
                            regla: e.regla.nombre,
                            admin: e.admin ? `${e.admin.nombre} ${e.admin.apellido}` : 'N/A',
                            resultado: e.result,
                            resultadoTooltip: this.resumenReservaHelper.getTooltipResultadoValidacion(e.result)
                        }
                    });
                    this.isLoading = false;
                },
                error: () => {
                    this.isLoading = false;
                }
            })
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

}
