import {
    EstadoEventoViajeCancerbero,
    EventoViajeCancerbero,
    Tiempo,
    TipoAlarma,
    TipoAlarmaControl,
    UltimaInteraccion,
    Viaje
} from '@obrador/api-interfaces';
import { differenceInMinutes } from 'date-fns';
import _ from 'lodash';
import { ArrayUtils } from './array';
import { DateUtils } from './date';

export abstract class AlarmaUtils {

    /**
     * Calcula el tiempo excedente
     * -> diferencia entre minutos entre real y  (teorico + tolerancia)
     * @param fecha_destino - fecha a evaluar
     * @param viaje
     * @param t - tiempo definido en la reserva
     * @param tolerancia
     * @return tiempo acumulado entre los eventos (minutos)
     */
    static calcularTiempoAcumulado(fecha_destino: Date, viaje: Viaje, t: Tiempo, tolerancia: number): number {
         const real = AlarmaUtils.calcularTiempoAcumuladoReal(fecha_destino, viaje);
         const teorico_tolerancia = AlarmaUtils.calcularTiempoAcumuladoTeorico(t, viaje.eventos_cancerbero) + tolerancia;
         return real - teorico_tolerancia;
    }

    static calcularTiempoAcumuladoTeorico(t: Tiempo, eventos: EventoViajeCancerbero[]): number {
        // Por defecto es rumbo a origen cuando no hay eventos cancerbero...
        if (ArrayUtils.isEmpty(eventos)) {
            return AlarmaUtils.getTiempoByEstado(t, EstadoEventoViajeCancerbero.RUMBO_ORIGEN);
        }
        // Get ultimo evento.
        const ultimo = _.chain(eventos).filter(v => v.confirmacion > 0).maxBy(e => e.tipo).value();
        // Si esta finalizado no tiene sentido seguir.
        if (!ultimo) {
            return 0;
        }
        // Calcular el tiempo acumulado hasta el ultimo evento.
        return AlarmaUtils.getTiempoByEstado(t, ultimo.tipo);
    }

    static calcularTiempoAcumuladoReal(fecha_destino: Date, v: Viaje): number {
        if (v.viaje_api && v.viaje_api.fecha_inicio) {
            return differenceInMinutes(fecha_destino, v.viaje_api.fecha_inicio);
        }
        return 0
    }

    /**
     * Devuelve el porcentaje desviado de la ultima interaccion.
     *  [ ( Fecha_Actual ) -  (Fecha Ultima Interacccion  ) - Tolerancia ] * 100 / tolerancia
     * @param fecha_destino
     * @param tiempo_ultima_interaccion
     * @param control
     */
    static calcularDesviacionSegunTolerancia(fecha_destino: Date, tiempo_ultima_interaccion: number, control: TipoAlarma): number {
        const tolerancia = control?.tolerancia;
        if (!tolerancia || !tiempo_ultima_interaccion) {
            return 0;
        }
        const result =  ( ( tiempo_ultima_interaccion - tolerancia ) * 100 ) / tolerancia;
        return result < 0 ? 0 : result;
    }

    /**
     * Devuelve el porcentaje desviado por tiempo acumulado
     * Calcula la desviacion del tiempo real correspondiendo al tiempo teorico total + la tolerancia.
     * @param fecha_destino
     * @param viaje
     * @param t - tiempo de la reserva
     * @param eventos - Eventos cancerbero
     * @param control - control de la reserva
     */
    static calcularDesviacionAcumuladaSegunTolerancia(fecha_destino: Date, viaje: Viaje, t: Tiempo, eventos: EventoViajeCancerbero[], control: TipoAlarma): number {
        const real = AlarmaUtils.calcularTiempoAcumuladoReal(fecha_destino, viaje);
        const control_tolerancia = control ? +control?.tolerancia|| 0 : 0;
        const teorico_con_tolerancia = AlarmaUtils.calcularTiempoAcumuladoTeorico(t, eventos) + control_tolerancia;
        if (teorico_con_tolerancia === 0) {
            return 0;
        }
        const tiempo = real - teorico_con_tolerancia;
        if (tiempo < 0) {
            return 0;
        }
        return ( tiempo * 100 ) / teorico_con_tolerancia;
    }

    /**
     *
     * @param fecha_destino
     * @param ultima_interaccion
     * @return diferencia entre fecha_Destino y ultima_interaccion en minutos.
     */
    static diffUltimaInteraccion(fecha_destino: Date, ultima_interaccion: UltimaInteraccion): number {
        if (!ultima_interaccion || !ultima_interaccion.fecha) {
            return 0;
        }
        const ultimaFecha = DateUtils.parseISO(ultima_interaccion.fecha);
        if (!DateUtils.isValid(ultimaFecha)) {
            return 0;
        }
        return differenceInMinutes(fecha_destino, ultimaFecha);
    }

    static getTiempoByEstado(t: Tiempo, estado: EstadoEventoViajeCancerbero): number {
        switch (estado) {
            case EstadoEventoViajeCancerbero.RUMBO_ORIGEN:
                return t.ida;
            case EstadoEventoViajeCancerbero.EN_ORIGEN:
                return t.origen + t.ida;
            case EstadoEventoViajeCancerbero.CARGADO:
                return t.vuelta + t.ida + t.origen;
            case EstadoEventoViajeCancerbero.EN_DESTINO:
            case EstadoEventoViajeCancerbero.FINALIZADO:
                return t.destino + t.ida + t.origen + t.vuelta;
            default:
                return 0;
        }
    }

    static getTiempoByEstadoCb(t: Tiempo, estado: EstadoEventoViajeCancerbero): number {
        switch (estado) {
            case EstadoEventoViajeCancerbero.RUMBO_ORIGEN:
                return t.ida;
            case EstadoEventoViajeCancerbero.EN_ORIGEN:
                return t.origen;
            case EstadoEventoViajeCancerbero.CARGADO:
                return t.vuelta;
            case EstadoEventoViajeCancerbero.EN_DESTINO:
                return t.destino;
            case EstadoEventoViajeCancerbero.FINALIZADO:
                return t.destino;
            default:
                return 0;
        }
    }

    /**
     * Calculo: D = (Fecha_Actual - Fecha_Evento) - (Tiempo_Teorico_Instancia + Tolerancia_Minutos)
     * @param fecha_destino
     * @param tipoAlarma
     * @param viaje
     * @param t
     */
    static calcularTiempoInstancia(fecha_destino: Date, tipoAlarma: TipoAlarma, viaje: Partial<Viaje>, t: Tiempo): number {
        const ultimo = AlarmaUtils.getUltimoEventoCancerbero(viaje.eventos_cancerbero);
        if (ultimo) {
            const tolerancia_control = tipoAlarma.tolerancia ;
            const tiempo_instancia = AlarmaUtils.getTiempoByEstadoCb(t, ultimo.tipo);
            const tolerancia = (tolerancia_control * tiempo_instancia) / 100;
            return differenceInMinutes(fecha_destino, ultimo.fecha) - (tiempo_instancia + tolerancia);
        }
        return 0;
    }

    static tiempoInstancia(fecha_instancia: Date): number {
        return differenceInMinutes(new Date(), fecha_instancia);
    }

    static desviacionInstancia(t: Tiempo, eventoCb: EventoViajeCancerbero, tipoAlarma: TipoAlarma): number {
        
        const tolerancia_control = tipoAlarma.tolerancia || 0;
        const tiempo_instancia = AlarmaUtils.getTiempoByEstadoCb(t, eventoCb.tipo);
        const tolerancia = (tolerancia_control * tiempo_instancia) / 100;
        const diff_now_instancia = differenceInMinutes(new Date(), eventoCb.fecha) - (tiempo_instancia + tolerancia);
        if (diff_now_instancia <= 0) {
            return 0;
        }
        return (diff_now_instancia * 100) / tiempo_instancia;
   
    }

    static enAlarma(fecha_destino: Date, tipoAlarma: TipoAlarma, viaje: Partial<Viaje>, t: Tiempo): boolean {
        let result = false;
        const viaje_api = viaje.viaje_api;
        switch (tipoAlarma.tipo) {
            case TipoAlarmaControl.VIAJE_TIEMPO_ACUM:
                // Si fecha_actual - (fecha_inicio + tolerancia + tiempo_instancia) > 0 es inválido
                // ( la resta de tiempo real ) - tiempo teorico
                result = AlarmaUtils.calcularTiempoInstancia(fecha_destino, tipoAlarma, viaje, t) > 0;
                break;
            case TipoAlarmaControl.VIAJE_TIEMPO_INTERACCION:
                if (viaje_api && viaje_api.ultima_interaccion) {
                    // Si fecha_actual - (fecha_interaccion + tolerancia) > 0 es inválido
                    result = AlarmaUtils.diffUltimaInteraccion(fecha_destino, viaje_api.ultima_interaccion)
                        - tipoAlarma.tolerancia > 0;
                }
                break;
        }
        return result;
    }

    static getUltimoEventoCancerbero(events: EventoViajeCancerbero[]): EventoViajeCancerbero {
        return _.chain(events).filter(e => e.confirmacion > 0).maxBy(e => e.tipo).value();
    }
}
