import { Injectable, Injector } from '@angular/core';
import { PlanViaje } from '@obrador/api-interfaces';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class PlanViajeService extends RestService<PlanViaje> {

    constructor(protected injector: Injector) {
        super(injector, Resource.PLAN_VIAJE);
    }

}
