import { BaseDTO } from './api-interfaces';
import { Conductor } from './conductor';
import { Convocatoria } from './convocatoria';
import { EstadoSubConvocatoria } from './enums';
import { MensajeProgramado } from './mensaje-programado';
import { Viaje } from './viaje';

export interface SubConvocatoria extends BaseDTO {
    estado: EstadoSubConvocatoria;
    fecha_inicio: Date;
    fecha_fin: Date;
    tarifa?: number;
    comentario?: string;
    id_conductor?: number;
    conductor?: Conductor;
    viajes?: Viaje[];
    lugar: string;
    convocatoria?: Partial<Convocatoria>;
    mensajes_programados: MensajeProgramado[];
    comentario_rechazo?: string;
}
