import { Injectable, Injector } from "@angular/core";
import { Novedad } from "@obrador/api-interfaces";
import { catchError, Observable } from "rxjs";
import { endOfDay, startOfDay } from "date-fns";
import { HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { RestService } from "../rest.service";
import { Resource } from "../rest.resources";

@Injectable({
    providedIn: 'root'
})
export class NovedadService extends RestService<Novedad>{

    constructor(protected injector: Injector) {
        super(injector, Resource.NOVEDADES);
    }

    getNovedadesByViajeId(viajeId: number): Observable<Novedad[]> {
        return this.http.get<Novedad[]>(`${this.buildURL()}/${viajeId}`)
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapArray(data)
            ))
    }

}
