import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  disabled?: boolean;
}

const MENUITEMS = [
    // { state: 'dashboard', name: 'Inicio', type: 'link', icon: 'av_timer', disabled: true },
    // { state: 'calendario', name: 'Calendario', type: 'link', icon: 'calendar_today', disabled: true },
    { state: 'reservas', name: 'Reservas', type: 'link', icon: 'event' },
    { state: 'convocatorias', name: 'Convocatorias', type: 'link', icon: 'event_note' },
    { state: 'viaje', name: 'Viajes', type: 'link', icon: 'local_shipping' },
    { state: 'seguimiento', name: 'Seguimiento', type:'link', icon: 'access_alarm'},
    { state: 'resumen-reservas', name: 'Cierre de Reservas', type: 'link', icon: 'playlist_add_check_circle' },
    { state: 'recursos', name: 'Recursos', type: 'link', icon: 'assignment' }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
