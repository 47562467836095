import {BaseDTO, Empresa, Motorizado, Remolque} from "./api-interfaces";

export interface Habilitacion extends BaseDTO {
    id_migrador: number;
    tipo: number;
    images: Array<string>;
    fecha_vencimiento: Date;
    prestadora_seguro: string;
    nombre_prestadora: string;
    monto_rc: number;
    sin_documento: boolean;
    document_id: string;
    modelo_documento?: ModeloDocumento;
    empresa?: Empresa;
    motorizado?: Motorizado;
    remolque?: Remolque;
    deleted_at?: Date;
}

export interface ModeloDocumento extends BaseDTO {
    id_migrador: number;
    code: string;
    name: string;
    tipo: number;
    version: string;
    document_id: string;
    modelosDocumentaciones: ModeloDocConfig[];
    habilitaciones: Habilitacion[];
}

export interface ModeloDocumentacion extends  BaseDTO {
    id_migrador: number;
    entity: string;
    default: boolean;
    type: string;
    document_id: string;
    modeloDocumentos?: ModeloDocConfig[];
}

export interface ModeloDocConfig extends BaseDTO {
    required: boolean;
    modeloDocumentacionId: number;
    modeloDocumentoId: number;
    modeloDocumento?: ModeloDocumento;
}
