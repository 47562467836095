<div #textContainer *ngIf="!isEditing; else editingTemplate" (dblclick)="onDoubleClick()">
    <ng-container *ngIf='value !== undefined && value !== null; else defaultValue'>
        {{ value }}
    </ng-container>
    <ng-template #defaultValue>
        {{ placeholder }}
    </ng-template>

</div>
<ng-template #editingTemplate>
    <input [type]='type' (keyup.enter)="finishEditing()" (keyup.escape)='cancel()' #inputText [style.max-width]='maxWidth' [(ngModel)]="value" (blur)="cancel()" />
</ng-template>
