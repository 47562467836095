import {
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    Renderer2,
    SimpleChanges,
    ViewContainerRef
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ErrorMessages } from '../../../error';

@Directive({
  selector: '[appFormError]'
})
export class FormErrorDirective implements OnDestroy, OnChanges {

  @Input() control: UntypedFormControl | AbstractControl;
  @Input() fieldName: string;

  protected subscription: Subscription;
  protected _error: string;

  set error(error: string) {
    const render = this._error !== error;
    this._error = error;
    if (render) {
      this.renderError();
    }
  }

  get error() {
    return this._error;
  }

  constructor(
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected element: ElementRef,
    protected renderer: Renderer2,
    protected errorMessages: ErrorMessages,
    protected viewContainer: ViewContainerRef
  ) {
  }

  renderError(): void {
    this.element.nativeElement.replaceChildren();
    if (this.error) {
      const text = this.renderer.createText(this.error);
      this.renderer.appendChild(this.viewContainer.element.nativeElement, text);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('control' in changes) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.subscribeToControlChanges();
    }
  }

  getError(): string {
    if (!this.control) {
      return undefined;
    }
    const controlErrors = this.control.errors;
    if (controlErrors && this.isPolluted()) {
      const keys = Object.keys(controlErrors);
      // Loop through errors, first match gets shown.
      for (const keyError of keys) {
        if (this.fieldName) {
          return this.errorMessages.getMessageWithParameters(keyError, this.fieldName);
        } else {
          return this.errorMessages.getMessageWithParameters(keyError, 'Campo');
        }
      }
    }
    return undefined;
  }

  subscribeToControlChanges(): Subscription {
    if (this.control) {
      this.error = this.getError();
      this.subscription = this.control.statusChanges.subscribe(
        status => {
          if (status === 'INVALID') {
            this.error = this.getError();
          } else if (status === 'VALID') {
            this.error = undefined;
          }
        }
      );
    }
    return undefined;
  }

  isPolluted(): boolean {
    return (!this.control.valid && this.control.dirty);
  }


}
