import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RecursoMotorizado, RecursoRemolque } from '@obrador/api-interfaces';

@Component({
    selector: 'app-recurso-motorizado-item',
    templateUrl: './recurso-motorizado-item.component.html',
    styleUrls: ['./recurso-motorizado-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecursoMotorizadoItemComponent {

    protected _isMotorizado: boolean;

    icon: string;

    matricula: string;
    tipo: string;
    modelo: string;

    protected _entity: RecursoMotorizado | RecursoRemolque;

    @Input() set isMotorizado(flag: boolean) {
        this._isMotorizado = flag;
        this.icon = flag? 'local_shipping' : 'rv_hookup'
    };
    @Input() set entity(e: RecursoRemolque | RecursoMotorizado) {
        this._entity = e;
        this.matricula = e.matricula || 'Sin matricula';
        this.tipo = e.nombre_tipo || 'Sin tipo';
        this.modelo = e.modelo || 'Sin modelo';
    }

    get entity() {
        return this._entity;
    }
}


