import { Injectable, Injector } from '@angular/core';
import { Regla } from '@obrador/api-interfaces';
import { Observable } from 'rxjs';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
  providedIn: 'root'
})
export class ReglaService extends RestService<Regla>{

    constructor(protected injector: Injector) {
        super(injector, Resource.REGLAS);
    }

    validar(id_reserva: number, ids: number[]): Observable<unknown> {
        return this.http.post<unknown>(`${this.buildURL()}/execute`, {
            id_reserva,
            id_viajes_api: ids
        });
    }

}
