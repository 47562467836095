import { Injectable } from '@angular/core';
import * as errorMessages from './error-codes.json';
@Injectable()
export class ApiErrorMessageService {

    protected errorMessages: Record<string, string>;
    protected readonly defaultError = 'Ocurrió un error inesperado';

    constructor() {
        this.errorMessages = errorMessages as Record<string, string>;
    }

    getErrorMessage(code: string, enumLike?: string): string {
        if (!code) {
            return this.defaultError;
        }
        const key = enumLike ? `${enumLike}.${code}` : code;
        return this.errorMessages[key] || this.defaultError;
    }

}
