import { from, mergeMap, Observable, pipe, UnaryFunction } from 'rxjs';
import { filter, toArray } from 'rxjs/operators';

/**
 * Selects through a predicate and filters when conditions is true
 * @param predicate
 */
export function select<T> (
    predicate: (value: T) => boolean
): UnaryFunction<Observable<T[]>, Observable<T[]>> {
    return pipe(
        mergeMap(value =>
            from(value).pipe(
                filter(predicate),
                toArray()
            )
        )
    );
}
