import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export const DEFAULT_ACCOUNT_ID = 1;

@Injectable({
    providedIn: 'root'
})
export class CuentaSelectService {

    private cuentaActual = new BehaviorSubject<number>(DEFAULT_ACCOUNT_ID);
    cuentaActual$ = this.cuentaActual.asObservable();

    setCuentaActual(cuentaId: number) {
        this.cuentaActual.next(cuentaId);
    }

    getCuentaActual(): Observable<number> {
        return this.cuentaActual$;
    }
}
