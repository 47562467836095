import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventoViaje, TipoEventoViaje, Viaje } from '@obrador/api-interfaces';
import _ from 'lodash';

export interface DialogViajeRechazadoData {
    viaje: Viaje;
}

@Component({
    selector: 'app-dialog-viaje-rechazado',
    templateUrl: './dialog-viaje-rechazado.component.html',
    styleUrls: ['./dialog-viaje-rechazado.component.scss'],
})
export class DialogViajeRechazadoComponent implements OnInit {

    data: Viaje;
    createdBy: EventoViaje;
    rejectedBy: EventoViaje;

    constructor(
        protected dialog: MatDialogRef<DialogViajeRechazadoComponent>,
        @Inject(MAT_DIALOG_DATA) public entryData: DialogViajeRechazadoData
    ) {}

    ngOnInit(): void {
        this.data = this.entryData.viaje;
        this.createdBy = this.data.eventos ? _.chain(this.data.eventos)
            .filter(e => e.tipo === TipoEventoViaje.CREA_VIAJE)
            .first()
            .value(): null;
        this.rejectedBy = this.data.eventos ? _.chain(this.data.eventos)
            .filter(e => e.tipo === TipoEventoViaje.RECHAZAR_VIAJE)
            .first()
            .value(): null;
    }

    close(): void {
        this.dialog.close();
    }
}
