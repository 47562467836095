import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface AutoCompleteScrollEvent {
    autoComplete: MatAutocomplete;
    scrollEvent: Event;
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'mat-autocomplete[scrollableInfinite]'
})
export class FormInfiniteAutocompleteDirective implements OnDestroy {

    @Input() thresholdPercent = 0.8;
    @Output() readonly scrollableInfinite = new EventEmitter<AutoCompleteScrollEvent>();
    _onDestroy = new Subject<void>();

    constructor(public autoComplete: MatAutocomplete) {
        this.autoComplete.opened.pipe(
            tap(() => {
                // Note: When autocomplete raises opened, panel is not yet created (by Overlay)
                // Note: The panel will be available on next tick
                // Note: The panel wil NOT open if there are no options to display
                setTimeout(() => {
                    if (this?.autoComplete?.panel?.nativeElement) {
                        // Note: remove listener just for safety, in case the close event is skipped.
                        this.removeScrollEventListener();
                        this.autoComplete.panel.nativeElement
                            .addEventListener('scroll', this.onScroll.bind(this));
                    }
                });
            }),
            takeUntil(this._onDestroy)).subscribe();

        this.autoComplete.closed.pipe(
            tap(() => {
                this.removeScrollEventListener();
            }),
            takeUntil(this._onDestroy)).subscribe();
    }

    private removeScrollEventListener(): void {
        if (this.autoComplete && this.autoComplete.panel) {
            this.autoComplete.panel.nativeElement
                // tslint:disable:no-unbound-method
                .removeEventListener('scroll', this.onScroll);
        }
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.unsubscribe();
        this.removeScrollEventListener();
    }

    onScroll(event: any): void {
        if (this.thresholdPercent === undefined) {
            this.scrollableInfinite.next({ autoComplete: this.autoComplete, scrollEvent: event });
        } else {
            const threshold = this.thresholdPercent * 100 * event.target.scrollHeight / 100;
            const current = +event.target.scrollTop + +event.target.clientHeight;
            if (current > threshold) {
                this.scrollableInfinite.next({ autoComplete: this.autoComplete, scrollEvent: event });
            }
        }
    }
}
