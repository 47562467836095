import { InjectionToken } from '@angular/core';

export interface Environment {
    storage_prefix: string;
    production: boolean;
    obrador_url: string;
    token?: string;
}

export const ENVIRONMENT = new InjectionToken<Environment>('environment');
