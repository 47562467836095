<h2 mat-dialog-title>
    {{ options.title }}
</h2>
<div mat-dialog-content [formGroup]='form'>
    <mat-form-field>
        <textarea matInput formControlName='comentario'> </textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions class='justify-content-end'>
    <button mat-raised-button color="primary" (click)='submit()'>Guardar</button>
    <button mat-raised-button color="secondary" (click)='close()'>Cancelar</button>
</div>
