import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { SubConvocatoria, Viaje } from '@obrador/api-interfaces';
import { catchError, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class SubConvocatoriaService extends RestService<SubConvocatoria> {

    constructor(protected injector: Injector) {
        super(injector, Resource.CONVOCATORIAS);
    }

    create(entity: Partial<SubConvocatoria>): Observable<SubConvocatoria> {
        return this.http.post<SubConvocatoria>(this.buildURLSub(entity.convocatoria.id), entity)
            .pipe(
                catchError(err => this.handleError(err)),
                map(data => this.mapEntity(data))
            );
    }

    update(entity: Partial<SubConvocatoria>): Observable<SubConvocatoria> {
        const id = entity.id;
        delete entity.id;
        return this.http.put<SubConvocatoria>(`${this.buildURLSub(entity.convocatoria.id)}/${id}`, entity)
            .pipe(
                map(data => this.mapEntity(data))
            );
    }

    delete(id: string | number): Observable<boolean> {
        return this.http.delete<boolean>(`${this.baseURL}/${Resource.CONVOCATORIAS}/${Resource.SUB_CONVOCATORIAS}/${id}`);
    }

    getSubConvocatoriasPorFecha(from: Date, to: Date, cuentaId: number): Observable<Array<SubConvocatoria>> {
        let params = new HttpParams()
            .set('from', from.toISOString())
            .set('to', to.toISOString());
        if (cuentaId) {
            params = params.set('cuentaId', cuentaId);
        }
        return this.http.get<Array<SubConvocatoria>>(`${this.buildURLWithResource(Resource.SUB_CONVOCATORIAS)}`, { params });
    }

    getColaViajes(id: number): Observable<Viaje[]> {
        return this.http.get<Viaje[]>(`${this.buildURLWithResource(Resource.SUB_CONVOCATORIAS)}/${id}/viajes`);
    }

    protected buildURLSub(id_convocatoria: number): string {
        return `${this.buildURL()}/${id_convocatoria}/${Resource.SUB_CONVOCATORIAS}`
    }

    isDisponible(conductorEmail: string, fechaInicio: Date, fechaFin: Date, subId?: number): Observable<SubConvocatoria> {
        let params = new HttpParams()
            .append('conductorEmail', conductorEmail)
            .append('fechaInicio', fechaInicio.toISOString())
            .append('fechaFin', fechaFin.toISOString());
        if (subId) {
            params = params.append('subId', subId);
        }
        return this.http.get<SubConvocatoria>(`${this.baseURL}/${Resource.SUB_CONVOCATORIAS}/conductor-disponible`, { params });
    }
}
