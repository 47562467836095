import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ErrorMessages } from '../../../error';

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormErrorsComponent implements OnDestroy, OnChanges {

  @Input() control: UntypedFormControl | AbstractControl;
  @Input() fieldName: string;
  @Input() type: 'small' | 'mat' | 'none' = 'mat';

  subscription: Subscription;

  error: string;

  constructor(
    protected errorMessages: ErrorMessages,
    protected cd: ChangeDetectorRef
  ) {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('control' in changes) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.subscribeToControlChanges();
    }
  }

  getError(): string {
    if (!this.control) {
      return undefined;
    }
    const controlErrors = this.control.errors;
    if (controlErrors && this.isPolluted()) {
      const keys = Object.keys(controlErrors);
      // Loop through errors, first match gets shown.
      for (const keyError of keys) {
        if (this.fieldName) {
          return this.errorMessages.getMessageWithParameters(keyError, this.fieldName);
        } else {
          return this.errorMessages.getMessage(keyError);
        }
      }
    }
    return undefined;
  }

  subscribeToControlChanges(): Subscription {
    if (this.control) {
      this.error = this.getError();
      this.cd.detectChanges();
      this.subscription = this.control.statusChanges.subscribe(
        status => {
          const prev = this.error;
          if (status === 'INVALID') {
            this.error = this.getError();
          } else if (status === 'VALID') {
            this.error = undefined;
          }
          if (prev !== this.error) {
            this.cd.detectChanges();
          }
        }
      );
    }
    return undefined;
  }

  isPolluted(): boolean {
    return (!this.control.valid && this.control.dirty);
  }

}
