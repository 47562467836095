<div class='filtro-familia' [formGroup]='form'>
    <div class='filtro-texto'>
        <mat-form-field floatLabel='always'>
            <mat-label>Filtrar</mat-label>
            <input matInput autocomplete='off' formControlName='text' type='text' placeholder='Escribe para filtrar...'>
        </mat-form-field>
    </div>
    <div class='filtro-familia-select'>
        <mat-form-field appearance='outline'>
            <mat-label>Familias de servicios</mat-label>
            <mat-select formControlName='familia'>
                <mat-option [value]='0'>Todas</mat-option>
                <mat-option
                    [value]='f'
                    *ngFor='let f of familias; trackByProperty: "id"'>
                    {{f.nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class='filtro-checkbox'>
        <mat-form-field appearance='outline'>
            <mat-label>Estado</mat-label>
            <mat-select formControlName='estado'>
                <mat-option [value]='null'>Todas</mat-option>
                <ng-container *ngFor='let opt of estados; trackByIndex'>
                    <mat-option [value]='opt.value'> {{ opt.label }}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
</div>
