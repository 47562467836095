import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { StorageModule } from '@ngx-pwa/local-storage';
import { CoreModule } from '@obrador/core';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    APP_DATE_TIME_ADAPTER,
    APP_ENVIRONMENT, APP_LOCALSTORAGE_PREFIX,
    APP_MAT_DATE_CONFIG,
    APP_MAT_FORM_FIELD_DEFAULTS,
    APP_MTX_DATETIME_FORMATS
} from './app.config';


@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        MatDateFnsModule,
        MatDatepickerModule,
        CoreModule,
        BrowserModule,
        HttpClientModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MtxDatetimepickerModule,
        // global Loading bars
        LoadingBarRouterModule,
        LoadingBarModule,
        MatProgressBarModule,
        StorageModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        })
    ],
    providers: [
        // Environment variable.
        APP_ENVIRONMENT,
        APP_MAT_DATE_CONFIG,
        APP_MAT_FORM_FIELD_DEFAULTS,
        APP_DATE_TIME_ADAPTER,
        APP_MTX_DATETIME_FORMATS,
        APP_LOCALSTORAGE_PREFIX
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
